<template>
  <div class="app-context">
    <side-menu :deepNavi="deep"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="member-result">
          <div class="member-info-set">
            <div class="content">
<!--
              <div class="item" v-for="(value, index) in memberInner[0]" :key="index + 'A'">
                <p v-text="value.questionText" class="cate"/><p v-text="value.answerResult" class="info"/>
              </div> -->
              <div class="item">
                <p class="checkId cate"><label>아이디</label></p>
                <div class="info" >
                  <input :class="{'warn':admin.idWarn,'':!admin.idWarn}" type="text" @keyup="check('아이디')" v-model="memberInner.userId"/>
                  <p class="warn-message" v-if="admin.hasError && admin.label==='아이디'"  v-text="admin.errorTxt" readonly/>
                </div>
              </div>

              <!-- <div class="item">
                <p class="checkPass cate"><label>비밀번호</label></p>
                <div class="info" >
                  <input type="text" :class="{'warn':pass.warn,'':!pass.warn}" class="info" @keyup="checkPw()" v-model="pass.value">
                  <p class="warn-message" v-if="pass.hasError" v-text="pass.errorTxt"/>
                </div>

              </div>

              <div class="item">
                <p class="checkPass cate"><label>비밀번호 확인</label></p>
                <div class="info" >
                  <input type="text" :class="{'warn':passConfirm.warn,'':!passConfirm.warn}" class="info" @keyup="checkPwConfirm()"  v-model="passConfirm.value"/>
                  <p class="warn-message" v-if="passConfirm.hasError" v-text="passConfirm.errorTxt"/>
                </div>

              </div> -->
              <div class="item">
                <p class="userName cate"><label>이름</label></p>
                <div class="info" >
                  <input type="text" :class="{'warn':admin.nameWarn,'':!admin.nameWarn}" class="info" @keyup="check('이름')" readonly v-model="memberInner.nickname"/>
                  <p class="warn-message" v-if="admin.hasError&& admin.label==='이름'" v-text="admin.errorTxt"/>
                </div>

              </div>

              <div class="item">
                <p class="email cate"><label>이메일</label></p>
                <div class="info" >
                  <input type="email" :class="{'warn':emailAdmin.warn,'':!emailAdmin.warn}" class="info" @keyup="checkEmail()" readonly v-model="memberInner.email"/>
                  <p class="warn-message" v-if="emailAdmin.hasError" v-text="emailAdmin.errorTxt"/>
                </div>

              </div>

              <div class="item">
                <p class="phone cate"><label>연락처</label></p>
                <div class="info" >
                  <input type="tel" :class="{'warn':telAdmin.warn,'':!telAdmin.warn}" class="info" @keyup="getTel(memberInner.phone)" readonly v-model="memberInner.phone" >
                  <p class="warn-message" v-if="telAdmin.hasError" v-text="telAdmin.errorTxt"/>
                </div>
              </div>
            </div>
            <!-- <div class="content">
              <div class="item" v-for="(value, index) in memberInner[0]" :key="index + 'B'">
                <p v-text="value.answerResult" />

              </div>
            </div> -->
          </div>

        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">목록으로</button>
          <!-- <button type="button" @click="editManage()">수정하기</button>
          <button type="button" @click="deleteAdmin()">삭제하기</button> -->
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import vSelect from 'vue-select';

import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {
    manage: { type: Object },
  },
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 권한 관리',
        detail: '관리자 관리',
        detail2: '관리자 정보',
        url: '/managementList',
      },
      deep: 3,
      member: this.$route.query,
      memberInner: [],
      editInfo: null,
      pass: {
        value: null,
        hasError: null,
        errorTxt: null,
        warn: false,
      },
      passConfirm: {
        value: null,
        hasError: null,
        errorTxt: null,
        warn: false,
      },

      admin: {
        label: null,
        hasError: null,
        errorTxt: null,
        idWarn: false,
        nameWarn: false,
      },
      telAdmin: {
        hasError: null,
        errorTxt: null,
        warn: false,
      },
      emailAdmin: {
        hasError: null,
        errorTxt: null,
        warn: false,
      },
      result: {},
      view: {
        isPrepared: false,
        swallowPrepared: false,
        raisingPrepared: false,
        turnPrepared: false,
      },
    };
  },
  created() {
    this.getAdmin();
  },
  mounted() {},
  methods: {
    ...mapActions('member', ['FETCH_MANAGE_LIST', 'DELETE_MANAGE', 'EDIT_MANAGE']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    // async editManage() {
    //   // if (this.editPass !== '1234567') {

    //   if (this.admin.hasError === true) { return; }
    //   if (this.pass.hasError === true) { return; }
    //   if (this.passConfirm.hasError === true) { return; }
    //   if (this.telAdmin.hasError === true) { return; }
    //   if (this.emailAdmin.hasError === true) { return; }
    //   this.editInfo = {
    //     aid: this.memberInner.id,
    //     name: this.memberInner.nickname,
    //     email: this.memberInner.email,
    //     phone: this.memberInner.phone,
    //   };
    //   if (this.pass.value !== '' && this.pass.value !== null) {
    //     this.editInfo.password = this.pass.value;
    //     const crypto = require('crypto');
    //     const password = crypto
    //       .createHash('sha512')
    //       .update(this.pass.value)
    //       .digest('base64');
    //     this.editInfo.password = password;
    //   // }
    //   }
    //   this.SET_POPUP(true);
    //   this.popupSet.popNumber = 3;
    //   this.popupSet.popType = 'anajugiAdminEdit';
    //   this.popupSet.deleteBid = this.memberInner.id;
    //   this.popupSet.content = '수정하시겠습니까?';
    //   this.popupSet.popImg = 'warning.svg';
    //   this.popupSet.cancelBtnText = '취소';
    //   this.popupSet.destination = true;
    //   this.popupSet.confirmBtnText = '수정';
    //   this.popupSet.adminEditInfo = this.editInfo;
    // },
    getTel() {
      const Phone = this.memberInner.phone.replace(/\s/gi, '');
      if (Phone === '') {
        this.telAdmin.hasError = true;
        this.telAdmin.warn = true;
        this.telAdmin.errorTxt = '핸드폰번호를 입력해주세요.';
        return false;
      }
      const isPhone = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;

      if (!isPhone.test(Phone)) {
        this.telAdmin.hasError = true;
        this.telAdmin.warn = true;
        this.telAdmin.errorTxt = '핸드폰번호의 형식이 올바르지 않습니다.';
        return false;
      }
      this.telAdmin.hasError = false;
      this.telAdmin.warn = false;
      return true;
    },
    checkPw() {
      const pw = this.pass.value.replace(/\s/gi, '');
      // if (pw === '') {
      //   this.pass.hasError = true;
      //   this.pass.errorTxt = '비밀번호를 입력해주세요.';
      //   return false;
      // }
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}$/;
      if (!isPw.test(pw)) {
        this.pass.hasError = true;
        this.pass.warn = true;
        this.pass.errorTxt = '영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.';
        return false;
      }
      this.pass.hasError = false;
      this.pass.warn = false;
      return true;
    },
    checkPwConfirm() {
      // const pw = this.passConfirm.value.replace(/\s/gi, '');
      // if (pw === '') {
      //   this.passConfirm.hasError = true;
      //   this.passConfirm.errorTxt = '비밀번호를 입력해주세요.';
      //   return false;
      // }
      // const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}$/;
      // if (!isPw.test(pw)) {
      //   this.passConfirm.hasError = true;
      //   this.passConfirm.errorTxt = '영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.';
      //   return false;
      // }
      if (this.passConfirm.value !== this.pass.value) {
        this.passConfirm.hasError = true;
        this.passConfirm.warn = true;
        this.passConfirm.errorTxt = '비밀번호가 서로 일치하지 않습니다.';
        return false;
      }
      this.passConfirm.hasError = false;
      this.passConfirm.warn = false;
      return true;
    },
    checkEmail() {
      const mail = this.emailAdmin.value.replace(/\s/gi, '');
      if (mail === '') {
        this.emailAdmin.hasError = true;
        this.emailAdmin.warn = true;
        this.emailAdmin.errorTxt = '이메일을 입력해주세요.';
        return false;
      }
      const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!isEmail.test(mail)) {
        this.emailAdmin.hasError = true;
        this.emailAdmin.warn = true;
        this.emailAdmin.errorTxt = '이메일 형식에 맞지 않습니다.';
        return false;
      }
      this.emailAdmin.hasError = false;
      this.emailAdmin.warn = false;
      return true;
    },
    check(check) {
      if (this.memberInner.userId === '' || this.memberInner.nickname === '') {
        if (this.memberInner.userId === '') {
          this.admin.label = '아이디';
          this.admin.idWarn = true;
        }
        if (this.memberInner.nickname === '') {
          this.admin.label = '이름'; this.admin.nameWarn = true;
        }
        this.admin.label = check;
        this.admin.hasError = true;
        this.admin.errorTxt = `${check}을 입력해주세요.`;
        return false;
      }
      this.admin.hasError = false;
      this.admin.idWarn = false;
      this.admin.nameWarn = false;
      return true;
    },
    deleteAdmin(bid) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 3;
      this.popupSet.popType = 'anajugiDel';
      this.popupSet.deleteBid = this.memberInner.id;
      this.popupSet.content = '삭제하시겠습니까?';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.destination = true;
      this.popupSet.confirmBtnText = '삭제';
    },
    getAdmin() {
      this.manage = this.$route.query;
      const option = this.manage;
      this.FETCH_MANAGE_LIST(option).then((data) => {
        this.memberInner = data.response.List[this.manage.index];
      });
      this.emailAdmin.value = this.memberInner.email;
    },

  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
};
</script>
<style lang="scss" scoped>
.leaveWrap{width:80%; margin:0 auto;height:80px; position:relative;
  .leave{background:#fe6867; width:100px; height:50px; border-radius:5px; line-height:50px; text-align:center; color:#fff; position:absolute; right:0;}
}
.member-result {
  margin: 0 0 105px 0;
  .member-info-set {
    width: 80%;
    margin: 0 auto;

    .content {
      .item {
        padding: 0;
        outline: 1px solid #acb3c9;
        justify-content: center;
        align-items: center;
        overflow:hidden;
        p {
          text-align:center;
          border-bottom:2px solid #f1f3f5;
          height:100%;
          line-height:50px;
        &.cate{width:35%; background: #f1f3f5; float:left;}
        }
        div.info{
          width:65%; height: 50px;
          text-align:center; float:left;
          input{height:50px; line-height:50px;
          &.warn{height:30px; line-height: 30px;}
          }
          p{
            height:20px; line-height:20px;
            &.warn-message{color:red;}
          }
      }
      }
    }
  }
  .training-list {
    position: relative;
    margin: 40px 0 0 0;
    .time-wrap {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1px;
      grid-template-columns: 450px;
      max-width: 450px;
      margin: 1px 0 0 0;
      .time-status {
        .start,
        .end {
          display: flex;
          .title {
            width: 150px;
            background: #f3f3f3;
            outline: 1px solid #acb3c9;
            @include initfontKor(
              $font-size: 19px,
              $line-height: 29px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
          .time {
            p {
              outline: 1px solid #acb3c9;
              min-width: 300px;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 29px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
            &:last-of-type {
            }
          }
        }
      }
    }
    .plus-btn {
      position: absolute;
      left: 140px;
      button {
        border: 0;
        width: 120px;
        background: #292a2b;
        cursor: pointer;
        border-radius: 5px;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
    &:first-of-type {
      margin: 0;
    }
    .list-wrap {
      display: flex;
      h5 {
        width: 100%;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 48px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .list {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 150px 150px 150px;
        margin: 1px 0 0 0;
        &:first-of-type {
        }
        .time-notification {
          outline: 1px solid #acb3c9;
          position: relative;
          background: #f3f3f3;
          &.actived {
            padding: 9.5px 0;
          }

          p {
            width: 100%;
            margin: 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:last-of-type {
              /**
              position: absolute;
              transform: translateY(-50%);
              top: calc(50% + 17.5px);
               */
            }
          }
        }
        .set {
          outline: 1px solid #acb3c9;
          background: #fff9f3;
          &.alone {
            p {
              line-height: 64px;
            }
          }
          p {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 41px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              border-width: 0 0 0 0;
            }
          }
        }
        .count {
          outline: 1px solid #acb3c9;
          &.alone {
            p {
              line-height: 64px;
            }
          }
          p {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 41px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              border-width: 0 0 0 0;
            }
          }
        }
        .on-time {
          outline: 1px solid #acb3c9;
          &.alone {
            .item {
              p {
                line-height: 64px;
              }
            }
          }
          .item {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            display: flex;
            &:first-of-type {
              border-width: 0 0 0 0;
            }
            p {
              @include initfontKor(
                $font-size: 18px,
                $line-height: 41px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              width: 100%;
            }
          }
        }
        .noti-time {
          outline: 1px solid #acb3c9;
          background: #fff9f3;
          &.alone {
            .item {
              p {
                line-height: 64px;
              }
            }
          }
          .item {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            display: flex;
            &:first-of-type {
              border-width: 0 0 0 0;
            }
            p {
              @include initfontKor(
                $font-size: 18px,
                $line-height: 41px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import basic from './modules/basic';
import auth from './modules/auth';
import activity from './modules/activity';
import util from './modules/util';
import member from './modules/member';

const modules = {
  basic,
  auth,
  activity,
  util,
  member,
};

const plugins = [
  createPersistedState({
    paths: ['auth'],
  }),
];
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules,
  plugins,
});

<template>
  <div class="pagination">
    <div
      class="nav-btn prev"
      @click="moreNavigatorAct('prev')"
      :class="{ hide: paginationInfo.currentPage === 0 }"
      v-if="paginationInfo.firstMore === true"
    >
      <img src="@assets/images/util/icon_more_before.svg" />
    </div>
    <div
      class="nav-btn prev"
      @click="navigatorAct('prev')"
      :class="{ hide: paginationInfo.currentPage === 0 }"
      v-if="paginationInfo.first === true"
    >
      <img src="@assets/images/util/icon_before.svg" />
    </div>

    <div class="count-wrap">
      <span
        class="item"
        v-for="(paginationItem, index) in this.paginationInfo.list"
        :key="index + 'A'"
        v-text="paginationItem+1"
        @click="changePage(paginationItem)"
        :class="{'active': paginationInfo.active[paginationItem]===true}"
      ></span>
    </div>
    <div
      class="nav-btn next"
      @click="navigatorAct('next')"
      v-if="paginationInfo.end === true"
       >
      <img src="@assets/images/util/icon_next.svg" />
    </div>
    <div
      class="nav-btn nextMore"
      @click="moreNavigatorAct('next')"
      v-if="paginationInfo.endMore === true"
       >
      <img src="@assets/images/util/icon_more_next.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paginationInfo: {
      type: Object,
    },
    response0: { type: [Object, Array] },
  },
  data() {
    return {
      paginationStatus: {
        totalDataCnt: this.paginationInfo.totalDataCnt,
        startPage: this.paginationInfo.startPage,
        // 페이지 개수
        lastPage: null,
        // 현재 페이지
        currentPage: this.paginationInfo.currentPage,
        prev: null,
        next: null,
        first: null,
        last: null,
        list: this.paginationInfo.list,
        // 추가
        active: [true],
        block: 2,
        limit: this.paginationInfo.dataOnPage,
        // 페이지 데이터레코드 개수
        recordsPerPage: null,
      },
    };
  },
  methods: {
    injectPagination(totalDataCnt, startPage, lastPage, currentPage) {
      this.initPagination(totalDataCnt, startPage, lastPage, currentPage);
      this.preparePagination();
      this.initPaginationEffect();
    },
    changePage(start) {
      this.paginationInfo.currentPage = start;
      this.paginationStatus.currentPage = start;
      this.$emit('btn-click', start);
    },

    navigatorAct(type) {
      const parentPaging = this.paginationInfo.currentPage;
      if (type === 'prev' && parentPaging !== 0) { this.paginationStatus.currentPage = parentPaging - 1; } else if (type === 'next') { this.paginationStatus.currentPage = parentPaging + 1; }
      const start = this.paginationStatus.currentPage;
      this.$emit('btn-click', start);
    },
    moreNavigatorAct(type) {
      const parentPaging = this.paginationStatus.currentPage;

      if (type === 'prev' && parentPaging !== 0) { this.paginationStatus.currentPage = (parentPaging - 4) % 4 === 0 ? parentPaging - 4 : parentPaging - 4 - (parentPaging - 4) % 4; } else if (type === 'next') { this.paginationStatus.currentPage = (parentPaging + 4) % 4 === 0 ? parentPaging + 4 : parentPaging + 4 - (parentPaging + 4) % 4; }
      const start = this.paginationStatus.currentPage;
      this.$emit('btn-click', start);
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 580px;
  margin: 0 auto;
  .nav-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    &.hide {
      opacity: 0;
      display:none;
    }
  }
  .count-wrap {
    display: flex;
    span.item {
      margin: 0 0 0 12px;
      display: block;
      width: 30px;
      cursor: pointer;
      @include initfontKor(
        $font-size: 18px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.48px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: #fe6867;
        color: #fff;
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidemenu-context" }, [
    _vm._m(0),
    _c("div", { staticClass: "side-content" }, [
      _c(
        "div",
        { staticClass: "section" },
        [
          _c(
            "h3",
            {
              staticClass: "section-title",
              on: {
                click: function($event) {
                  return _vm.test($event, 0)
                }
              }
            },
            [_vm._v("일반 회원 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link",
              class: {
                active:
                  _vm.deepNavi === 1 && this.$route.query.flag === undefined
              },
              attrs: { to: "/" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("회원 정보 조회 및 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link",
              attrs: { to: "/withdrawed" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("탈퇴회원")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c(
            "h3",
            {
              staticClass: "section-title",
              on: {
                click: function($event) {
                  return _vm.test($event, 1)
                }
              }
            },
            [_vm._v("회원 권한 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link",
              class: { active: _vm.deepNavi === 3 },
              attrs: { to: "/managementList" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("관리자 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link",
              class: { active: _vm.deepNavi === 4 },
              attrs: { to: "/doctorList" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("의사회원 관리")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c(
            "h3",
            {
              staticClass: "section-title",
              on: {
                click: function($event) {
                  return _vm.test($event, 2)
                }
              }
            },
            [_vm._v("게시판 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link",
              class: { active: this.$route.query.flag === true },
              attrs: { to: "/declare" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("신고 내용 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link test",
              class: { active: _vm.deepNavi === 6 },
              attrs: { to: "/noticeList" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("공지사항 관리")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link test",
              attrs: { to: "/askList" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("1:1 문의 관리")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c(
            "h3",
            {
              staticClass: "section-title",
              on: {
                click: function($event) {
                  return _vm.test($event, 3)
                }
              }
            },
            [_vm._v("설정")]
          ),
          _c(
            "router-link",
            {
              staticClass: "section-link",
              attrs: { to: "/setting" },
              nativeOn: {
                click: function($event) {
                  return _vm.gotoUrl($event)
                }
              }
            },
            [_vm._v("앱 버전 관리")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "side-header" }, [
      _c("div", { staticClass: "img-wrap" }, [
        _c("img", { attrs: { src: require("@assets/images/util/kids.png") } })
      ]),
      _c("div", { staticClass: "side-title" }, [
        _c("h3", [_vm._v("안아주기")]),
        _c("p", { staticClass: "type" }, [_vm._v("관리자")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <modal :wide="true">
    <template v-slot:body>
      <div class="search-pop frame">
        <div class="close" @click="close()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <div class="search">
          <h5 class="title">회원검색</h5>
          <div class="header">
            <div class="input-wrap">
              <input type="text" v-model="search.value" />
            </div>
            <div class="btn-wrap">
              <button type="button" @click="search()">검색</button>
            </div>
          </div>
          <div class="content" v-if="listInfo.data.length > 0">
            <list
              :columnInfo="columnInfo"
              :listInfo="listInfo"
              ref="list"
              @member-selected="memberToParent"
              v-if="isListVisible"
            ></list>
            <br />
            <pagination
              @btn-click="paginationClick"
              :paginationInfo="paginationInfo"
              ref="pagination"
              v-if="isPaginationVisible"
            ></pagination>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Modal from "@/components/modal/SearchModal.vue";
import List from "@/components/List.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  props: {},
  components: {
    Modal,
    List,
    Pagination
  },
  data() {
    return {
      listInfo: {
        type: 15,
        data: [],
        isHideAmount: true
      },
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        startPage: null,
        lastPage: null,
        currentPage: 1,
        length: null
      },
      columnInfo: [
        {
          word: "아이디",
          filter: "id"
        },
        {
          word: "이름",
          filter: "userName"
        },
        {
          word: "성별",
          filter: "gender"
        },
        {
          word: "연락처",
          filter: "tel"
        },
        {
          word: "생년월일",
          filter: "birthDate"
        }
      ],
      isPaginationVisible: false,
      isListVisible: false
    };
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapState("basic", ["checkSearchPopup"])
  },
  created() {
    this.search();
  },
  methods: {
    ...mapActions("member", ["FETCH_ACCOUNT_LIST"]),
    ...mapMutations("basic", ["SET_SEARCH_POPUP"]),
    memberToParent(index, item) {
      this.$emit("get-member-id", index, item);
      this.SET_SEARCH_POPUP(false);
    },
    search(page) {
      console.log("searchword :", this.search.value);
      let searchWord = this.search.value;

      if (searchWord === null || searchWord === undefined) searchWord = "";

      if (page === null || page === undefined) page = 1;

      let filter = `?page=${page}&maxPage=10&search=${searchWord}`;
      this.FETCH_ACCOUNT_LIST(filter).then(data => {
        if (this.isListVisible) {
          this.$refs.list.initList(data.response.list, data.response.totalCount);
          this.$refs.pagination.injectPagination(
            data.response.totalCount,
            data.response.firstPage,
            data.response.endPage,
            page
          );
        } else {
          this.listInfo.data = data.response.list;
          this.isListVisible = true;
          this.getPaginationInfo(
            data.response.totalCount,
            data.response.firstPage,
            data.response.endPage,
            1
          );
        }
      });
    },
    paginationClick(pageOrder) {
      this.search(pageOrder);
    },
    getPaginationInfo(totalDataCnt, startPage, lastPage, currentPage) {
      /**
       * page, maxPage, default 인자로 페이지네이션 API 실행함
       * */
      this.paginationInfo.totalDataCnt = totalDataCnt;
      this.paginationInfo.startPage = startPage;
      this.paginationInfo.lastPage = lastPage;
      if (currentPage !== null) {
        this.paginationInfo.currentPage = currentPage;
      } else {
        this.paginationInfo.currentPage = 1;
      }
      this.isPaginationVisible = true;
    },
    close() {
      this.SET_SEARCH_POPUP(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.frame {
  padding: 40px 0;
  min-height: 300px;
}
.search-pop {
  width: inherit;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
  }
  .search {
    padding: 0 25px;
    h5.title {
      margin: 0 0 20px 0;
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: left
      );
    }
    .header {
      display: flex;
      width: 390px;
      margin: 0 0 20px 0;
      .input-wrap {
        width: calc(100% - 130px);
        margin: 0 10px 0 0;
        input {
          width: 100%;
          border: 1px solid black;
          padding: 0 12px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
      }
      .btn-wrap {
        width: 120px;
        button {
          width: inherit;
          background: #292a2b;
          cursor: pointer;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          border: 0;
          padding: 0;
        }
      }
    }
  }
  .list-context {
    margin: 0;
  }
}
/**
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    button {
      width: 100%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      cursor: pointer;
      border: 0;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
 */
</style>

<template>
  <div class="list-context">
    <!-- <div
      class="l-notification"
      :class="{ rightMgr: listInfo.type === 10 }"
      v-if="
        listInfo.type !== 11 &&
          listInfo.type !== 12 &&
          listInfo.type !== 13 &&
          listInfo.type !== 14 &&
          listInfo.type !== 15 &&
          listInfo.type !== 18
      "
    > -->
    <div
      class="l-notification"
      :class="{ rightMgr: listInfo.type === 10 ,declareList:listInfo.type===5}"
    >
      <h3 v-if="listInfo.type === 5" class="case-num">
        총
        {{response0.count}}
        개
      </h3>
      <h3 v-else-if="listInfo.type === 6 || listInfo.type === 7 " class="case-num">
        총
        {{response0.count}}
        건
      </h3>
      <h3 v-else class="case-num">
        총
        {{response0.count}}
        명
      </h3>

      <div class="select-complex">
        <div class="select-wrap" >
          <v-select
            :options="countOptDeclare.option"
            :searchable="false"
            @input="listAmount()"
            v-model="countOptDeclare.value"
            v-if="listInfo.type === 5"
          ></v-select>
          <v-select
            v-else-if="listInfo.type !== 5"
            :options="countOpt.option"
            :searchable="false"
            @input="listAmount()"
            v-model="countOpt.value"
          ></v-select>
        </div>
      </div>
    </div>
    <p class="declareGuide " v-if="listInfo.type === 5">*[삭제]는 신고당한 게시물, 답글, 댓글을 삭제하는 버튼입니다.</p>
    <p class="register" v-if="listInfo.type === 3" @click="registerPop()">등록</p>
    <p class="register" v-if="listInfo.type === 4" @click="registerDocPop()">등록</p>
    <div
      class="list-wrap accountList"
    >
      <div class="header inCheckOpt" v-if="listInfo.type === 1">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"
            :class="[{'isReversal' : reverse[index]=== 0  }, {'Reversal' : reverse[index] ===1}]"
          ></p>
        </div>
      </div>
      <div class="header inCheckOpt withdraw" v-if="listInfo.type === 2">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"
            :class="[{'isReversal' : reverse[index]=== 0  }, {'Reversal' : reverse[index] ===1}]"
          ></p>
        </div>
      </div>
      <div class="header inCheckOpt adminList" v-if="listInfo.type === 3">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"
            :class="[{'isReversal' : reverse[index]=== 0  }, {'Reversal' : reverse[index] ===1}]"
          ></p>
        </div>
      </div>
      <div class="header inCheckOpt" v-if="listInfo.type === 4">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"
            :class="[{'isReversal' : reverse[index]=== 0  }, {'Reversal' : reverse[index] ===1}]"

            ></p>
        </div>
      </div>
      <div class="header inCheckOpt declareWrap" v-if="listInfo.type === 5">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"

            ></p>
        </div>
      </div>
      <div class="header inCheckOpt notice" v-if="listInfo.type === 6">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"
            :class="[{'isReversal' : reverse[index]=== 0  }, {'Reversal' : reverse[index] ===1}]"
            ></p>
        </div>
      </div>
      <div class="header inCheckOpt answer" v-if="listInfo.type === 7">
        <div class="account item" v-for="(filter, index) in filterOpt" :key="index">
          <p
            v-text="filter.word"
            @click="filterReversalAdm(index)"
            :class="[{'isReversal' : reverse[index]=== 0  }, {'Reversal' : reverse[index] ===1}]"
            ></p>
        </div>
      </div>
      <div class="content">
        <div class="article-wrap" v-if="listInfo.type === 1">
          <div v-if="response0.List.length > 0">
            <div class="article"  v-for="(listItem, index) in response0.List" :key="index + 'A'">

              <div class="item">
                <p v-text="transDate(listItem.createdDate)"></p>
              </div>
              <div class="item" @click="goDetail(listItem.id,false)">
                <p v-text="listItem.userId" style="color:blue"></p>
              </div>
              <div class="item">
                <p v-text="listItem.nickname"></p>
              </div>
              <div class="item">
                <p v-text="listItem.email"></p>
              </div>
              <div class="item">
                <p v-text="listItem.gender"></p>
              </div>
              <div class="item">
                <p v-text="transDate(listItem.birthDate)"></p>
              </div>
            </div>
          </div>

          <div v-else class="empty-zone">
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 2">
          <div v-if="response0.List.length > 0">
            <div class="article withdraw"  v-for="(listItem, index) in response0.List" :key="index + 'A'">
              <!-- <div class="item">
                <p v-text="startindex + index + 1"></p>
              </div> -->
              <div class="item">
                <p v-text="transDate(listItem.createdDate)"></p>
              </div>
              <!-- <div class="item">
                <p v-text="listItem.id"></p>
              </div> -->
              <div class="item">
                <p v-text="listItem.userId"></p>
              </div>
              <div class="item">
                <p v-text="listItem.nickname"></p>
              </div>
              <div class="item">
                <p v-text="listItem.email"></p>
              </div>
              <div class="item">
                <p v-text="listItem.gender"></p>
              </div>
              <div class="item">
                <p v-text="transDate(listItem.birthDate)"></p>
              </div>
              <div class="item">
                <p v-text="listItem.withdraw_date"></p>
              </div>
              <div class="item">
                <!-- <p v-if="listItem.reasonContent.length > 1"><span v-for="(items, index) in listItem.reasonContent" :key="index" v-text="items +','"></span></p> -->

                <p ><span v-html="listItem.reasonContent"></span></p>
              </div>
            </div>
          </div>

          <div v-else class="empty-zone">
            <p>조회결과가 없습니다.</p>
          </div>
        </div>

        <div class="article-wrap " v-if="listInfo.type === 3">
          <div v-if="response0.List.length > 0">
            <div class="article adminList" v-for="(listItem, index) in response0.List" :key="index + 'D'">
              <div class="item">
                <p v-if="listIndex.flag" v-text="listIndex.value + index + 1"></p>
                <p v-else v-text="listIndex.value-(index)"></p>
                <!-- <p v-text="index + 1"></p> -->
              </div>
              <div class="item" @click="goAdminDetail(index)">
                <p class="link" v-text="listItem.userId" sytle="color:blue"></p>
              </div>
              <div class="item">
                <p v-text="listItem.nickname"></p>
              </div>
              <div class="item">
                <p v-text="listItem.lastLogin"></p>
              </div>
              <div class="item">
                <p v-text="listItem.createdDate"></p>
              </div>
              <div class="item">
                <p class="edit" @click="goAdminDetailPop(index)">수정</p><p class="delete" @click="deletePop(listItem.id)">삭제</p>
              </div>
            </div>
          </div>
          <div v-else class="empty-zone">
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 4">
          <div v-if="response0.List.length > 0">
            <div class="article" v-for="(listItem, index) in response0.List" :key="index + 'D'">
              <div class="item">
                <p v-text="listIndex.value + index + 1"></p>
              </div>
              <div class="item" @click="goDoctorDetail(listItem.userId)">
                <p class="link" v-text="listItem.userId" sytle="color:blue"></p>
              </div>
              <div class="item">
                <p v-text="listItem.nickname"></p>
              </div>
              <div class="item">
                <p v-text="listItem.hospital"></p>
              </div>
              <div class="item">
                <p v-text="listItem.createdDate"></p>
              </div>
              <div class="item">
                <p class="edit" @click="goAdminDetailPop(index)">수정</p><p class="delete" @click="deletePop(listItem.id)">삭제</p>
              </div>
            </div>
          </div>
          <div v-else class="empty-zone">
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 5">
          <div v-if="response0.List.length > 0">
            <div class="article declareWrap" v-for="(listItem, index) in response0.List" :key="index + 'D'">
              <div class="item">
                <p v-text="listIndex.value + index + 1"></p>
              </div>
              <div class="item">
                <p v-text="listItem.target"></p>
              </div>
              <div class="item" @click="goDetail(listItem.tuid,true)">
                <p v-text="listItem.tunick" style="color:blue"></p>
              </div>
              <div class="item declare" @click="goDeclareDetail(listItem.target_id,listItem.target, index)">
                <!-- <p v-for="(item, index) in listItem.reason" :key="index" style="color:blue"><span v-text="item"></span></p> -->
                <p v-text="listItem.reason" style="color:blue"></p>
              </div>

              <div class="item" @click="goDetail(listItem.uid,true)">
                <p v-text="listItem.unick" style="color:blue"></p>
              </div>
              <div class="item">
                <p v-text="listItem.createDate"></p>
              </div>
              <div class="item">
                <p v-if="listItem.count>=0">신고 {{listItem.count}}회</p>
                <p v-else>신고 취소</p>
              </div>
              <div class="item">
                <p class>{{listItem.textActive}}</p>
              </div>
              <div v-if="listItem.count>=0 && listItem.textActive !=='삭제'" class="item">
                <p class="edit" @click="cancelDeclare(listItem.id)">신고 취소</p><p class="delete" @click="deleteDeclare(listItem.id)">삭제</p>
              </div>
              <div v-else class="item">
                <p>-</p>
              </div>
            </div>
          </div>
          <div v-else class="empty-zone">
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 6">
          <div class="sub-wrap" v-if="response0.List.length > 0">
            <div
              v-for="(listItem, index) in response0.List"
              :key="index + 'L'"

            >
              <div v-if="response0.List[index].important>=0"
              >
                <div v-if="response0.List[index].important>0" class="article notice">
                  <div class="item">
                    <p v-text="listIndex.value + index + 1"></p>
                  </div>
                  <div class="item">
                    <p>TOP</p>
                  </div>
                  <div class="item" @click="goNoticeDetail(listItem.id, false)">
                    <p v-text="listItem.title"></p>
                  </div>
                  <div class="item">
                    <p v-text="listItem.createDate"></p>
                  </div>
                  <div class="item">
                    <p class="edit" @click="goNoticeForEdit(listItem.id, true)">수정</p><p class="delete" @click="noticeDeletePop(listItem.id, true)">삭제</p>
                  </div>
                </div>
                <div v-if="response0.List[index].important<=0" class="article notice">
                  <div class="item">
                    <p v-text="response0.List.length - index"></p>
                  </div>
                  <div class="item">
                    <p v-text="listItem.top"></p>
                  </div>
                  <div class="item"  @click="goNoticeDetail(listItem.id, false)">
                    <p v-text="listItem.title"></p>
                  </div>
                  <div class="item">
                    <p v-text="listItem.createDate"></p>
                  </div>
                  <div class="item">
                    <p class="edit" @click="goNoticeForEdit(listItem.id, true)">수정</p><p class="delete" @click="noticeDeletePop(listItem.id)">삭제</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-zone" v-else>
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 7">
          <div class="sub-wrap" v-if="response0.List.length > 0">
            <div
              v-for="(listItem, index) in response0.List"
              :key="index + 'L'"

            >
              <div class="article answer">
                <div class="item">
                  <p v-text="listIndex.value + index + 1"></p>
                </div>
                <div class="item" @click="goAskDetail(index)">
                  <p style="color:blue;" v-text="listItem.user_id"></p>
                </div>
                <div class="item">
                  <p v-text="listItem.title"></p>
                </div>
                <div class="item">
                  <p v-text="listItem.createDate"></p>
                </div>
                <div class="item">
                  <p class="answerState answerComplete" v-if="listItem.answerDate!==null" @click="goAskDetail(index)" >답변완료</p>
                  <p class="answerState answerWaiting"  v-else @click="goAskDetail(index)">답변대기</p>
                </div>
                <div class="item">
                  <p v-if="listItem.modifiedDate!==listItem.answerDate && listItem.answerDate" v-text="listItem.modifiedDate"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-zone" v-else>
            <p>조회결과가 없습니다.</p>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import vSelect from 'vue-select';
import { mapMutations, mapActions, mapState } from 'vuex';

export default {
  props: {
    columnInfo: {
      type: Array,
    },
    listInfo: {
      type: Object,
    },
    manage: {
      type: Object,
    },
    response0: { type: [Object, Array] },
    reverse: { type: Array },
    listIndex: { type: Object },

  },
  data() {
    return {
      countOpt: {
        option: [10, 30, 50, 100],
        value: '' || 10,
      },
      countOptDeclare: {
        option: [10, 30, 50, 100],
        value: '' || 30,
      },
      filterOpt: [],
      result: [],
      allChecked: false,
      totalDataCnt: '',
      totalCnt: 0,
      startindex: 0,
      view: {},
      order: 'desc',
      typeData: null,
      test: false,
    };
  },
  created() {
    this.makeFilterAdmin();
    this.typeData = this.listInfo.data.type;
  },
  mounted() {
    this.initList(this.listInfo.data, this.listInfo.totalDataCnt);
  },
  methods: {
    ...mapMutations('evaluate', ['SET_DAY']),
    ...mapActions('member', ['FETCH_ACCOUNT_DETAIL']),
    addResv() {
      this.$router.push('/reservationAdd');
    },
    registerPop() {
      this.$emit('register');
    },
    registerDocPop() {
      this.$emit('registerDoc');
    },
    deletePop(bid) {
      this.$emit('delete', bid, this.manage);
    },
    editPop(data) {
      this.$emit('edit', data);
    },
    noticeDeletePop(nid) {
      this.$emit('notice-delete', nid);
    },
    cancelDeclare(bdid) {
      this.$emit('cancel-declare', bdid);
    },
    deleteDeclare(bdid) {
      this.$emit('delete-declare', bdid);
    },
    goDeclareDetail(id, type, i) {
      if (this.response0.List[i].textActive !== '삭제') {
        let type0 = type;
        if (type === '게시물') {
          type0 = 1;
        } else if (type === '댓글') {
          type0 = 2;
        }
        if (type === '답글') {
          type0 = 3;
        }
        this.$emit('declare-detail', id, type0);
      } else { this.$emit('declare-withdraw-content', type); }
    },
    goAskDetail(index) {
      this.$emit('ask-detail', index);
    },
    selectMember(index, item) {
      this.$emit('member-selected', index, item);
    },
    loadDetailInfo(index, time) {
      this.$emit('load-list-detail', index, time);
    },
    msTeams(index) {
      this.$emit('call-consultant-record', this.result[index]);
    },
    calendarPop(info) {
      this.$emit('call-calender-pop', info);
    },
    cancelPop(info) {
      this.$emit('call-cancle-pop', info);
    },
    typecall(info) {
      this.$emit('typecall', info);
    },
    wishPop(info) {
      this.$emit('call-wish-pop', info);
    },
    writePop(info) {
      this.$emit('call-write-pop', info);
    },
    goDetail(index, flag) {
      let query0;
      if (flag === true) {
        query0 = { uid: index, flag };
        this.FETCH_ACCOUNT_DETAIL(`uid=${index}`).then((data) => {
          if (data.response.enable === 0) {
            this.$emit('declare-withdraw');
          } else {
            this.$router.push({
              name: 'AccountDetail',
              query: query0,
            });
          }
        });
      } else {
        query0 = { uid: index };
        this.$router.push({
          name: 'AccountDetail',
          query: query0,
        });
      }
    },
    goAdminDetailPop(index) {
      this.manage.index = index;
      // this.$router.push({
      //   name: 'AdminDetail',
      //   query: this.manage,
      // });
      this.$emit('edit', index, this.manage);
    },
    goAdminDetail(index) {
      this.manage.index = index;
      this.$router.push({
        name: 'AdminDetail',
        query: this.manage,
      });
      // this.$emit('edit', index, this.manage);
    },
    goDoctorDetail(index) {
      this.$router.push({
        name: 'DoctorDetail',
        query: {
          q: index,
        },
      });
    },
    goNoticeDetail(index, isFlag) {
      this.$router.push({
        name: 'NoticeDetail',
        query: {
          nid: index,
          isFlag,
        },
      });
    },
    goNoticeForEdit(index, isFlag) {
      this.$router.push({
        name: 'NoticeDetail',
        query: {
          nid: index,
          isFlag,
        },
      });
    },
    extract(type) {
      this.$emit('list-find-to-checked', this.result, type);
    },
    allCheck() {
      const flag = this.allChecked;
      const list = this.result;
      for (let i = 0; i < list.length; i++) {
        list[i].checkFlag = !flag;
      }
      this.result = list;
    },
    /** listInfo Type 1의 함수 시작 */
    setTrainingTime(order) {
      this.$emit('set-training-time', order);
    },
    setTrainingAmount(order) {
      this.$emit('set-training-amount', order);
    },
    /** listInfo Type 2의 함수 끝 */

    listAmount() {
      if (this.listInfo.type === 5) {
        this.$emit('list-amount-change', this.countOptDeclare.value);
      } else {
        this.$emit('list-amount-change', this.countOpt.value);
      }
    },
    initList(list, totalDataCnt, startIndex) {
      this.startindex = startIndex === undefined ? 0 : startIndex;

      this.totalCnt = totalDataCnt;
    },
    /** 회원(1),탈퇴(2),관리자(3),의사(4),신고(5),공지사항(6)
    */
    makeFilterAdmin() {
      /** 필터옵션 생성해주기 */
      const arraySet = this.columnInfo;
      if (this.reverse !== undefined) {
        for (let i = 0; i < this.reverse.length; i++) {
          if (this.reverse[i] === 0) {
            arraySet[i].flag = 0;
          } else if (this.reverse[i] === 1) { arraySet[i].flag = 1; }
        }
      }
      this.filterOpt = arraySet;
    },
    filterReversalAdm(index) {
      if (this.reverse[index].List === 0) {
        this.filterOpt[index].flag = 1;
      } else if (this.reverse[index].List === 1) {
        this.filterOpt[index].flag = 0;
      }

      const stringVal = index;
      this.$emit('list-reversal', stringVal);
    },
  },

  /** 공통함수  끝 */

  computed: {
    transDate() {
      return (date) => this.$moment(date).format('YYYY-MM-DD');
    },
    transDateTime() {
      return (date) => {
        if (date === null) return '';

        return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },
    transStatus() {
      return (status) => {
        let mutatedValue;
        if (status === 0) {
          mutatedValue = '-';
        } else if (status === 1) {
          mutatedValue = '진행중';
        } else if (status === 2) {
          mutatedValue = '훈련완료';
        }
        return mutatedValue;
      };
    },
  },
  components: {
    vSelect,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.list-context {
  margin: 0 0 60px 0;
  .l-notification {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
    &.rightMgr {
      .select-wrap {
        margin: 0 150px 0 0;
      }
    }
      &.declareList{margin:0}
    h3.case-num {
      @include initfontKor(
        $font-size: 22px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }

  .register{display:inline-block; width:60px; margin-right:10px; margin-bottom:20px; line-height:30px; height:30px; text-align:center; background-color:#fe6867; color:#fff;  border-radius: 5px;}
  .list-wrap {

    border: 1px solid #acb3c9;
    position: relative;

    .consultant-plus {
      position: absolute;
      top: -78px;
      right: 0;
      a {
        display: block;
        border: 0;
        padding: 0;
        width: 105px;
        background: #292a2b;
        cursor: pointer;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 48px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
    .header {
      display: flex;
      background: #f3f3f3;
      margin: 0 0 1px 0;
      &.inCheckOpt {
        .item {
          &.resv {
            min-width:332.5px;
          }
          // &:nth-of-type(1) p:after {
          //   display: none;
          // }
        }
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        outline: 1px solid #acb3c9;
        width: 100%;
        height: 100%;
        &:first-of-type {
          border-left: 0;
        }
        // &:first-of-type p:after {
        //   display: none;
        // }
        p {
          width: fit-content;
          margin: 0 auto;
          position: relative;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 48px,
            $font-family: "AppleSDGothicNeoM00",
            $letter-spacing: -0.6px,
            $text-align: center
          );
          &.isReversal {
            &:after {
              background-image: url("~@/assets/images/util/dropdown.svg");
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            right: -27.5px;
            top: 45%;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            cursor: pointer;
            }
          }
          &.Reversal:after {
              background-image: url("~@/assets/images/util/dropdown.svg");
              transform: rotate(180deg);
              position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            right: -27.5px;
            top: 45%;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            cursor: pointer;
              transform: rotate(180deg);
              top: calc(50% - 7.5px);

          }
        }
      }
    }
    .content {
      .article-wrap {
        &.resv {
          .article {
            .item {
              min-height: 48px;
              &.flex-btn {
                button.request-resv {
                  width: 105px;
                  padding: 0;
                  background: #292a2b;
                  margin: 0px auto;
                  cursor: pointer;
                  border: 0;
                  @include initfontKor(
                    $font-size: 16px,
                    $line-height: 32px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                }
              }
              p.status {
                background: #292a2b;
                color: #fff;
              }
            }
          }
        }
        .empty-zone {
          min-height: 130px;
          padding: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            @include initfontKor(
              $font-size: 21px,
              $line-height: 27px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
        }
        .empty-list {
          p {
          }
        }
        .article {
          display: flex;
          align-items: center;
          margin: 1px 0 0 0;
          &:first-of-type {
            margin: 0;
          }
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            outline: 1px solid #acb3c9;
            width: 100%;
            height: 100%;
            p{
            padding: 8px 0;
            &.edit,&.delete{padding:0;}
            }
            &.button-parent {
              display: flex;
              justify-content: center;
              button {
                border: 0;
                padding: 0;
                width: 120px;
                background: rgba(41, 42, 43, 0.8);
                cursor: pointer;
                border-radius: 5px;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            &.time-setting {
              display: flex;
              flex-wrap: wrap;
              .setter {
                width: 100%;
                display: flex;
                justify-content: center;
                p {
                  &.title {
                    width: 40px;
                    position: relative;
                    color: blue;
                    text-decoration: underline;
                    padding: 0 10px 0 0;
                    &:after {
                      content: "";
                      position: absolute;
                      right: 4.5px;
                      top: 50%;
                      width: 1px;
                      height: 16px;
                      transform: translateY(-50%);
                      background: #292a2b;
                    }
                  }
                }
              }
            }
            .input-wrap {
              width: inherit;
              display: flex;
              justify-content: center;
              input {
                width: calc(100% - 20px);
                padding: 0 12px;
                border: 1px solid #acb3c9;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 26px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #292a2b,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            p {
              &.edit,&.delete{display:inline-block; width:60px; margin-right:10px; line-height:30px; height:30px; text-align:center; background-color:#fe6867; color:#fff; border-radius: 5px;}

              @include initfontKor(
                $font-size: 16px,
                $line-height: 32px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              &.status {
                width: 105px;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
                &.inActive {
                  background: transparent;
                  color: #292a2b;
                }
                &.counseling {
                  background: #292a2b;
                  color: #fff;
                  cursor: pointer;
                }
              }
              &.link {
                text-decoration: underline;
                cursor: pointer;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: blue,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
          }
        }
      }
    }

    /** lisyType === 1 */
    &.accountList {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
            grid-template-columns: 1fr 130px 1fr 1fr 130px 1fr; //1fr 1fr 1fr;
        place-items: center;
        &.withdraw{
            grid-template-columns: 130px 130px 130px 1fr 130px 1fr 130px 1fr; }
        &.notice{
            grid-template-columns: 130px 130px 1fr 1fr 1fr; }
        &.answer{
            grid-template-columns: 130px 130px 1fr 1fr 1fr 1fr; }
        &.declareWrap{
            grid-template-columns: 100px 100px 130px 1fr 130px 1fr 130px 100px 1fr;}

        &.adminList{
            grid-template-columns:  1fr 1fr 1fr 1fr 130px 1fr; }

        &.inCheckOpt {
          .item {
            &:nth-of-type(1),
            &:nth-of-type(9) {
              // p:after {
              //   display: none;
              // }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
              grid-template-columns: 1fr 130px 1fr 1fr 130px 1fr; //1fr 1fr 1fr;
          place-items: center;

        &.withdraw{
            grid-template-columns: 130px 130px 130px 1fr 130px 1fr 130px 1fr; }
        &.notice{
            grid-template-columns: 130px 130px 1fr 1fr 1fr; }
        &.answer{
            grid-template-columns: 130px 130px 1fr 1fr 1fr 1fr; }
        &.declareWrap{
            grid-template-columns: 100px 100px 130px 1fr 130px 1fr 130px 100px 1fr;}

        &.adminList{
            grid-template-columns:  1fr 1fr 1fr 1fr 130px 1fr; }
        }
      }
    }

  }
}
  .list-context .list-wrap .content .article-wrap .article div.item.declare{
        display:grid;
        grid-template-rows: 1fr;
        justify-content: inherit;
      p{
        width:100%;
        height:100%;
        border-bottom:1px solid #acb3c9;

          display: flex;
          justify-content: center;
          align-items: center;
        &:first-child{border-top:none;}
        &:last-child{border-bottom:none;}
        }
      }
    .list-context .list-wrap .content .article-wrap .article .item .answerState{
      margin:5px 0; padding:0 5px; color:#fff; line-height:24px; border-radius: 3px;
      &.answerComplete{background-color:#23cc89}
      &.answerWaiting{background-color:#fe6867}
    }
</style>

<template>
  <div class="app-context">
    <side-menu :deepNavi="deep"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="leaveWrap"><p class="info">아이디 정보</p><p class="leave" @click="withDraw()">회원탈퇴</p></div>
        <div class="member-result">
          <div class="member-info-set">
            <div class="content">
              <div class="item" v-for="(value, index) in memberInner[0]" :key="index + 'A'">
                <p v-text="value.questionText" class="cate"/><p v-text="value.answerResult" class="info"/>
              </div>
            </div>
          </div>

        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">목록으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @result-check="resultCheck"/>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      tree: {
        title: '일반 회원 관리',
        detail: '회원 정보 조회 및 관리',
        detail2: '아이디 정보',
        url: '/',
      },
      deep: 1,
      member: [],
      memberInner: [],
      result: {},
      view: {
        isPrepared: false,
        swallowPrepared: false,
        raisingPrepared: false,
        turnPrepared: false,
      },
    };
  },
  created() {
    this.getMember();
  },
  mounted() {},
  methods: {
    ...mapActions('member', ['FETCH_ACCOUNT_DETAIL']),
    ...mapMutations('basic', ['SET_POPUP']),
    withDraw() {
      this.SET_POPUP(true);
      this.popupSet.uid = this.$route.query;
      this.popupSet.popType = 'withDraw';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '탈퇴 처리 하시겠습니까?';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },

    // 한 번더 확인해주는 팝업
    async resultCheck(comments) {
      if (comments === '처리되었습니다.') {
        await this.SET_POPUP(false);
      }
      this.SET_POPUP(true);
      this.popupSet.popType = 'resultCheck';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = comments;
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = undefined;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.popNumber = 1;
    },
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },

    getMember() {
      const { uid } = this.$route.query;
      this.FETCH_ACCOUNT_DETAIL(`uid=${uid}`).then((data) => {
        console.log(data);
        this.member.push(data);
        let birth = data.response.birthDate;
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1);
        const day = date.getDate();
        const monthDay = month + day;
        birth = birth.replace('-', '').replace('-', ''); const birthdayy = birth.substr(0, 4);
        const birthdaymd = birth.substr(4, 4);
        // 기존 나이 코드
        // const age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;
        const age = monthDay < birthdaymd ? year - birthdayy : year - birthdayy + 1;
        const getList = [
          {
            question: 1,
            questionText: '아이디',
            answerResult: data.response.userId,
          },
          {
            question: 2,
            questionText: '닉네임',
            answerResult: data.response.nickname,
          },
          {
            question: 3,
            questionText: '이메일',
            answerResult: data.response.email,
          },
          {
            question: 4,
            questionText: '성별',
            answerResult: data.response.gender,
          },
          {
            question: 5,
            questionText: '나이',
            answerResult: `${age}세`,
          },
          {
            question: 5,
            questionText: '가입경로',
            answerResult: data.response.visitPath,
          },
          {
            question: 6,
            questionText: '신고 당한 횟수',
            answerResult: `${data.response.declared}회`,
          },
          {
            question: 7,
            questionText: '신고한 횟수',
            answerResult: `${data.response.declaring}회`,
          },
        ];
        this.memberInner.push(getList);
      });
    },

  },
  computed: {
    ...mapState('basic', ['checkPopup']),

  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
.leaveWrap{width:80%; margin:0 auto; height:80px; position:relative;
  .info{
    font-family: "AppleSDGothicNeoM00";
    color: #292a2b;
    text-align: center;
    font-size: 22px;
    letter-spacing: -0.6px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    position: absolute;
    width: 100px;
    height: 50px;
    line-height: 50px;
    top:0;
    left:0;
}
  .leave{background:#fe6867; width:100px; height:50px; border-radius:5px; line-height:50px; text-align:center; top:0; color:#fff; position:absolute; right:0;}
}
.member-result {
  margin: 0 0 105px 0;
  .member-info-set {
    width: 80%;
    margin: 0 auto;

    .content {
      .item {
        padding: 0;
        outline: 1px solid #acb3c9;
        justify-content: center;
        align-items: center;
        height:50px;
        p {
          text-align:center;
          border-bottom:2px solid #f1f3f5;
          height:100%;
          line-height:50px;
        &.cate{width:35%; background: #f1f3f5; float:left;}
        &.info{width:65%; float:left;}
        }
      }
    }
  }

  .training-list {
    position: relative;
    margin: 40px 0 0 0;
    .time-wrap {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1px;
      grid-template-columns: 450px;
      max-width: 450px;
      margin: 1px 0 0 0;
      .time-status {
        .start,
        .end {
          display: flex;
          .title {
            width: 150px;
            background: #f3f3f3;
            outline: 1px solid #acb3c9;
            @include initfontKor(
              $font-size: 19px,
              $line-height: 29px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
          .time {
            p {
              outline: 1px solid #acb3c9;
              min-width: 300px;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 29px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
            &:last-of-type {
            }
          }
        }
      }
    }
    .plus-btn {
      position: absolute;
      left: 140px;
      button {
        border: 0;
        width: 120px;
        background: #292a2b;
        cursor: pointer;
        border-radius: 5px;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
    &:first-of-type {
      margin: 0;
    }
    .list-wrap {
      display: flex;
      h5 {
        width: 100%;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 48px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .list {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 150px 150px 150px;
        margin: 1px 0 0 0;
        &:first-of-type {
        }
        .time-notification {
          outline: 1px solid #acb3c9;
          position: relative;
          background: #f3f3f3;
          &.actived {
            padding: 9.5px 0;
          }

          p {
            width: 100%;
            margin: 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:last-of-type {
              /**
              position: absolute;
              transform: translateY(-50%);
              top: calc(50% + 17.5px);
               */
            }
          }
        }
        .set {
          outline: 1px solid #acb3c9;
          background: #fff9f3;
          &.alone {
            p {
              line-height: 64px;
            }
          }
          p {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 41px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              border-width: 0 0 0 0;
            }
          }
        }
        .count {
          outline: 1px solid #acb3c9;
          &.alone {
            p {
              line-height: 64px;
            }
          }
          p {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 41px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              border-width: 0 0 0 0;
            }
          }
        }
        .on-time {
          outline: 1px solid #acb3c9;
          &.alone {
            .item {
              p {
                line-height: 64px;
              }
            }
          }
          .item {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            display: flex;
            &:first-of-type {
              border-width: 0 0 0 0;
            }
            p {
              @include initfontKor(
                $font-size: 18px,
                $line-height: 41px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              width: 100%;
            }
          }
        }
        .noti-time {
          outline: 1px solid #acb3c9;
          background: #fff9f3;
          &.alone {
            .item {
              p {
                line-height: 64px;
              }
            }
          }
          .item {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            display: flex;
            &:first-of-type {
              border-width: 0 0 0 0;
            }
            p {
              @include initfontKor(
                $font-size: 18px,
                $line-height: 41px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>

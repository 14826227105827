<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="noti-zone">
          <h5 class="title">1:1 문의 관리</h5>
          <list-filter
            @request-list="injectionResult"
            @default-search="getDefaultSearch"
            :listInfo="listInfo"
            ref="filter"
          ></list-filter>
        </div>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          :response0="response"
          :reverse="reverse"
          :listIndex="listIndex"
          @ask-detail="askDetail"
          @list-amount-change="changedListAmount"
          @list-reversal="reversalResult"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          :response0="listInfo"
          ref="pagination"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import ListFilter from '@/components/ListFilter.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        next: null,
        first: null,
        last: null,
        list: null,
        // 추가
        active: [true],
        currentPage: 0,
        block: 5,
      },
      tree: {
        title: '게시판 관리',
        detail: '1:1 문의 관리',
      },
      columnInfo: [
        {
          word: '번호',
        },
        {
          word: '아이디',
        },
        {
          word: '제목',
        },
        {
          word: '등록일',
        },
        {
          word: '상태',
        },
        {
          word: '수정 일시',
        },
      ],
      option: {
        start: 0,
        size: 5,
        status: 0,
        sort: 0,
        order: 1,
      },
      listInfo: {
        type: 7,
        data: [],
        isHideAmount: true,
      },
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParamOrder: '',
      response: {
        List: [], count: '',
      },
      // 앞에 번호를 위한 데이터값
      listIndex: { value: 0, flag: true },
      reverse: [0, 0, '', 0, 0],
    };
  },
  created() {
    window.scrollTo(0, 0);
    // this.fetchData();
  },
  methods: {
    ...mapActions('member', ['QNA_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    askDetail(index) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 7;
      this.popupSet.popType = 'anajugi';
      this.popupSet.popAskOption = this.option;
      this.popupSet.deleteBid = index;
      this.popupSet.huge = true;
      this.popupSet.content = '삭제하시겠습니까?';
      this.popupSet.cancelBtnText = '닫기';
      this.popupSet.destination = true;
      this.popupSet.confirmBtnText = '등록';
    },
    async getDefaultSearch(option) {
      this.option = option;
      const data = await this.QNA_LIST(option);
      this.response = data.response;
      this.listInfo.data = this.response.List;
      this.paginationInfo.currentPage = 0;
      this.paginationInfo.startPage = 0;

      // 페이징 을 위한
      const { count } = this.response;

      this.initPagination(this.paginationInfo.currentPage, count);
      return this.response;
    },

    // 페이지에 나오는 리스트 의 데이터 갯수 메서드
    changedListAmount(listAmountVal) {
      this.option.size = listAmountVal;
      this.paginationInfo.dataOnPage = this.option.size;
      this.option.start = 0; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0;
      this.listIndex.value = this.option.start;
      this.$refs.filter.changeListAmount(listAmountVal);
      this.initPagination(this.paginationInfo.currentPage);
    },
    async fetchData(sort, order) {
      this.option.sort = sort;
      this.option.order = order;
      if (sort === false || sort === undefined) {
        this.option.sort = 0;
        this.option.order = 1;
      }
      try {
        await this.QNA_LIST(this.option).then((data) => {
          this.listInfo.data = data.response.List;
          this.listInfo.totalDataCnt = data.response.count;
          this.response.List = data.response.List;
          this.isListVisible = true;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    reversalResult(info) {
      if (this.reverse[info] === 0) {
        this.reverse[info] = 1;
      } else {
        this.reverse[info] = 0;
      }
      let order;
      if (info < 2) { order = info + 1; } else { order = info; }

      this.fetchData(order, this.reverse[info]);
    },
    getAddClass(check) {
      /** 페이지네이션 번호 클릭 */
      const activeArray = [];
      for (let j = 0; j < Math.ceil(this.paginationInfo.totalDataCnt / this.paginationInfo.dataOnPage); j++) {
        activeArray.push(false);
      }
      activeArray[check] = true;
      this.paginationInfo.active = activeArray;
    },
    paginationClick(start) {
      this.getAddClass(start);
      const start0 = start * this.paginationInfo.dataOnPage;
      this.option.start = start0;
      this.paginationInfo.currentPage = start;

      this.initPagination(start);
      this.QNA_LIST(this.option).then((data) => {
        this.listInfo.data = data.response.List;
        this.listInfo.totalDataCnt = data.response.count;
        this.response.List = data.response.List;
        this.listIndex.value = this.option.start;
      });
    },
    async injectionResult(option, search) {
      /**
       * 리스트 다시 그리는 요청을 받았을 때 실행
       * (필터 설정 후 검색, 페이지네이션 클릭, 리스트 수 셀렉트박스 변동)
       * */
      this.option = option;
      if (search === 0) { this.option.start = 0; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0; }

      const data = await this.QNA_LIST(this.option);
      this.response = data.response;
      this.paginationInfo.totalDataCnt = this.response.count;
      this.initPagination(this.paginationInfo.currentPage, this.paginationInfo.totalDataCnt);
    },
    /** 페이징 처리 */
    initPagination(page, count) {
      if (count !== undefined) { this.paginationInfo.totalDataCnt = count; }
      this.pageDataSetting(this.paginationInfo.totalDataCnt, this.paginationInfo.dataOnPage, this.paginationInfo.block, page);
    },
    pageDataSetting(total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);

      const currentPage = page;

      const startIndex = currentPage % 4 === 0 ? currentPage : currentPage - (currentPage % 4);
      const endIndex = startIndex + 3 >= totalPage ? totalPage - 1 : startIndex + 3;

      this.paginationInfo.startIndex = startIndex;
      this.paginationInfo.endIndex = endIndex;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        if (index >= 0) {
          list.push(index);
        }
      }

      this.paginationInfo.first = totalPage > 1 && currentPage >= 0 ? true : null;
      this.paginationInfo.end = totalPage > 1 && currentPage + 1 < totalPage ? true : null;
      this.paginationInfo.firstMore = totalPage > 3 && currentPage >= 0 ? true : null;
      this.paginationInfo.endMore = totalPage > 4 && startIndex + 3 < totalPage ? true : null;
      this.paginationInfo.list = list;
      return {
        list, currentPage,
      };
    },

  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .noti-zone {
    position: relative;
  }
  .add-notice {
    position: relative;
    button {
        border-radius: 5px;
      border: 0;
      padding: 0;
      width: 105px;
      background: #292a2b;
      position: absolute;
      right:200px;
      border-radius: 5px;
      cursor: pointer;
      @include initfontKor(
        $font-size: 18px,
        $line-height: 48px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
}
</style>

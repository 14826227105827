var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-context" }, [
    _c(
      "div",
      {
        staticClass: "l-notification",
        class: {
          rightMgr: _vm.listInfo.type === 10,
          declareList: _vm.listInfo.type === 5
        }
      },
      [
        _vm.listInfo.type === 5
          ? _c("h3", { staticClass: "case-num" }, [
              _vm._v(" 총 " + _vm._s(_vm.response0.count) + " 개 ")
            ])
          : _vm.listInfo.type === 6 || _vm.listInfo.type === 7
          ? _c("h3", { staticClass: "case-num" }, [
              _vm._v(" 총 " + _vm._s(_vm.response0.count) + " 건 ")
            ])
          : _c("h3", { staticClass: "case-num" }, [
              _vm._v(" 총 " + _vm._s(_vm.response0.count) + " 명 ")
            ]),
        _c("div", { staticClass: "select-complex" }, [
          _c(
            "div",
            { staticClass: "select-wrap" },
            [
              _vm.listInfo.type === 5
                ? _c("v-select", {
                    attrs: {
                      options: _vm.countOptDeclare.option,
                      searchable: false
                    },
                    on: {
                      input: function($event) {
                        return _vm.listAmount()
                      }
                    },
                    model: {
                      value: _vm.countOptDeclare.value,
                      callback: function($$v) {
                        _vm.$set(_vm.countOptDeclare, "value", $$v)
                      },
                      expression: "countOptDeclare.value"
                    }
                  })
                : _vm.listInfo.type !== 5
                ? _c("v-select", {
                    attrs: { options: _vm.countOpt.option, searchable: false },
                    on: {
                      input: function($event) {
                        return _vm.listAmount()
                      }
                    },
                    model: {
                      value: _vm.countOpt.value,
                      callback: function($$v) {
                        _vm.$set(_vm.countOpt, "value", $$v)
                      },
                      expression: "countOpt.value"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]
    ),
    _vm.listInfo.type === 5
      ? _c("p", { staticClass: "declareGuide " }, [
          _vm._v("*[삭제]는 신고당한 게시물, 답글, 댓글을 삭제하는 버튼입니다.")
        ])
      : _vm._e(),
    _vm.listInfo.type === 3
      ? _c(
          "p",
          {
            staticClass: "register",
            on: {
              click: function($event) {
                return _vm.registerPop()
              }
            }
          },
          [_vm._v("등록")]
        )
      : _vm._e(),
    _vm.listInfo.type === 4
      ? _c(
          "p",
          {
            staticClass: "register",
            on: {
              click: function($event) {
                return _vm.registerDocPop()
              }
            }
          },
          [_vm._v("등록")]
        )
      : _vm._e(),
    _c("div", { staticClass: "list-wrap accountList" }, [
      _vm.listInfo.type === 1
        ? _c(
            "div",
            { staticClass: "header inCheckOpt" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  class: [
                    { isReversal: _vm.reverse[index] === 0 },
                    { Reversal: _vm.reverse[index] === 1 }
                  ],
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listInfo.type === 2
        ? _c(
            "div",
            { staticClass: "header inCheckOpt withdraw" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  class: [
                    { isReversal: _vm.reverse[index] === 0 },
                    { Reversal: _vm.reverse[index] === 1 }
                  ],
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listInfo.type === 3
        ? _c(
            "div",
            { staticClass: "header inCheckOpt adminList" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  class: [
                    { isReversal: _vm.reverse[index] === 0 },
                    { Reversal: _vm.reverse[index] === 1 }
                  ],
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listInfo.type === 4
        ? _c(
            "div",
            { staticClass: "header inCheckOpt" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  class: [
                    { isReversal: _vm.reverse[index] === 0 },
                    { Reversal: _vm.reverse[index] === 1 }
                  ],
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listInfo.type === 5
        ? _c(
            "div",
            { staticClass: "header inCheckOpt declareWrap" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listInfo.type === 6
        ? _c(
            "div",
            { staticClass: "header inCheckOpt notice" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  class: [
                    { isReversal: _vm.reverse[index] === 0 },
                    { Reversal: _vm.reverse[index] === 1 }
                  ],
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listInfo.type === 7
        ? _c(
            "div",
            { staticClass: "header inCheckOpt answer" },
            _vm._l(_vm.filterOpt, function(filter, index) {
              return _c("div", { key: index, staticClass: "account item" }, [
                _c("p", {
                  class: [
                    { isReversal: _vm.reverse[index] === 0 },
                    { Reversal: _vm.reverse[index] === 1 }
                  ],
                  domProps: { textContent: _vm._s(filter.word) },
                  on: {
                    click: function($event) {
                      return _vm.filterReversalAdm(index)
                    }
                  }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _vm.listInfo.type === 1
          ? _c("div", { staticClass: "article-wrap" }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c(
                        "div",
                        { key: index + "A", staticClass: "article" },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.transDate(listItem.createdDate)
                                )
                              }
                            })
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              on: {
                                click: function($event) {
                                  return _vm.goDetail(listItem.id, false)
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticStyle: { color: "blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.userId)
                                }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.nickname)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.email) }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.gender) }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.transDate(listItem.birthDate)
                                )
                              }
                            })
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e(),
        _vm.listInfo.type === 2
          ? _c("div", { staticClass: "article-wrap" }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c(
                        "div",
                        { key: index + "A", staticClass: "article withdraw" },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.transDate(listItem.createdDate)
                                )
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.userId) }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.nickname)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.email) }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.gender) }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.transDate(listItem.birthDate)
                                )
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.withdraw_date)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(listItem.reasonContent)
                                }
                              })
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e(),
        _vm.listInfo.type === 3
          ? _c("div", { staticClass: "article-wrap " }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c(
                        "div",
                        { key: index + "D", staticClass: "article adminList" },
                        [
                          _c("div", { staticClass: "item" }, [
                            _vm.listIndex.flag
                              ? _c("p", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.listIndex.value + index + 1
                                    )
                                  }
                                })
                              : _c("p", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.listIndex.value - index
                                    )
                                  }
                                })
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              on: {
                                click: function($event) {
                                  return _vm.goAdminDetail(index)
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticClass: "link",
                                attrs: { sytle: "color:blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.userId)
                                }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.nickname)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.lastLogin)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.createdDate)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "p",
                              {
                                staticClass: "edit",
                                on: {
                                  click: function($event) {
                                    return _vm.goAdminDetailPop(index)
                                  }
                                }
                              },
                              [_vm._v("수정")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function($event) {
                                    return _vm.deletePop(listItem.id)
                                  }
                                }
                              },
                              [_vm._v("삭제")]
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e(),
        _vm.listInfo.type === 4
          ? _c("div", { staticClass: "article-wrap" }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c(
                        "div",
                        { key: index + "D", staticClass: "article" },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.listIndex.value + index + 1
                                )
                              }
                            })
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              on: {
                                click: function($event) {
                                  return _vm.goDoctorDetail(listItem.userId)
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticClass: "link",
                                attrs: { sytle: "color:blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.userId)
                                }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.nickname)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.hospital)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.createdDate)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "p",
                              {
                                staticClass: "edit",
                                on: {
                                  click: function($event) {
                                    return _vm.goAdminDetailPop(index)
                                  }
                                }
                              },
                              [_vm._v("수정")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function($event) {
                                    return _vm.deletePop(listItem.id)
                                  }
                                }
                              },
                              [_vm._v("삭제")]
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e(),
        _vm.listInfo.type === 5
          ? _c("div", { staticClass: "article-wrap" }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c(
                        "div",
                        {
                          key: index + "D",
                          staticClass: "article declareWrap"
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.listIndex.value + index + 1
                                )
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.target) }
                            })
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              on: {
                                click: function($event) {
                                  return _vm.goDetail(listItem.tuid, true)
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticStyle: { color: "blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.tunick)
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "item declare",
                              on: {
                                click: function($event) {
                                  return _vm.goDeclareDetail(
                                    listItem.target_id,
                                    listItem.target,
                                    index
                                  )
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticStyle: { color: "blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.reason)
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              on: {
                                click: function($event) {
                                  return _vm.goDetail(listItem.uid, true)
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticStyle: { color: "blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.unick)
                                }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.createDate)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            listItem.count >= 0
                              ? _c("p", [
                                  _vm._v(
                                    "신고 " + _vm._s(listItem.count) + "회"
                                  )
                                ])
                              : _c("p", [_vm._v("신고 취소")])
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {}, [_vm._v(_vm._s(listItem.textActive))])
                          ]),
                          listItem.count >= 0 && listItem.textActive !== "삭제"
                            ? _c("div", { staticClass: "item" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "edit",
                                    on: {
                                      click: function($event) {
                                        return _vm.cancelDeclare(listItem.id)
                                      }
                                    }
                                  },
                                  [_vm._v("신고 취소")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteDeclare(listItem.id)
                                      }
                                    }
                                  },
                                  [_vm._v("삭제")]
                                )
                              ])
                            : _c("div", { staticClass: "item" }, [
                                _c("p", [_vm._v("-")])
                              ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e(),
        _vm.listInfo.type === 6
          ? _c("div", { staticClass: "article-wrap" }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    { staticClass: "sub-wrap" },
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c("div", { key: index + "L" }, [
                        _vm.response0.List[index].important >= 0
                          ? _c("div", [
                              _vm.response0.List[index].important > 0
                                ? _c("div", { staticClass: "article notice" }, [
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.listIndex.value + index + 1
                                          )
                                        }
                                      })
                                    ]),
                                    _vm._m(0, true),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "item",
                                        on: {
                                          click: function($event) {
                                            return _vm.goNoticeDetail(
                                              listItem.id,
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("p", {
                                          domProps: {
                                            textContent: _vm._s(listItem.title)
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", {
                                        domProps: {
                                          textContent: _vm._s(
                                            listItem.createDate
                                          )
                                        }
                                      })
                                    ]),
                                    _c("div", { staticClass: "item" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "edit",
                                          on: {
                                            click: function($event) {
                                              return _vm.goNoticeForEdit(
                                                listItem.id,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("수정")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.noticeDeletePop(
                                                listItem.id,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("삭제")]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm.response0.List[index].important <= 0
                                ? _c("div", { staticClass: "article notice" }, [
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.response0.List.length - index
                                          )
                                        }
                                      })
                                    ]),
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", {
                                        domProps: {
                                          textContent: _vm._s(listItem.top)
                                        }
                                      })
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "item",
                                        on: {
                                          click: function($event) {
                                            return _vm.goNoticeDetail(
                                              listItem.id,
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("p", {
                                          domProps: {
                                            textContent: _vm._s(listItem.title)
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", {
                                        domProps: {
                                          textContent: _vm._s(
                                            listItem.createDate
                                          )
                                        }
                                      })
                                    ]),
                                    _c("div", { staticClass: "item" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "edit",
                                          on: {
                                            click: function($event) {
                                              return _vm.goNoticeForEdit(
                                                listItem.id,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("수정")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.noticeDeletePop(
                                                listItem.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("삭제")]
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e(),
        _vm.listInfo.type === 7
          ? _c("div", { staticClass: "article-wrap" }, [
              _vm.response0.List.length > 0
                ? _c(
                    "div",
                    { staticClass: "sub-wrap" },
                    _vm._l(_vm.response0.List, function(listItem, index) {
                      return _c("div", { key: index + "L" }, [
                        _c("div", { staticClass: "article answer" }, [
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.listIndex.value + index + 1
                                )
                              }
                            })
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              on: {
                                click: function($event) {
                                  return _vm.goAskDetail(index)
                                }
                              }
                            },
                            [
                              _c("p", {
                                staticStyle: { color: "blue" },
                                domProps: {
                                  textContent: _vm._s(listItem.user_id)
                                }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: { textContent: _vm._s(listItem.title) }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(listItem.createDate)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "item" }, [
                            listItem.answerDate !== null
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "answerState answerComplete",
                                    on: {
                                      click: function($event) {
                                        return _vm.goAskDetail(index)
                                      }
                                    }
                                  },
                                  [_vm._v("답변완료")]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass: "answerState answerWaiting",
                                    on: {
                                      click: function($event) {
                                        return _vm.goAskDetail(index)
                                      }
                                    }
                                  },
                                  [_vm._v("답변대기")]
                                )
                          ]),
                          _c("div", { staticClass: "item" }, [
                            listItem.modifiedDate !== listItem.answerDate &&
                            listItem.answerDate
                              ? _c("p", {
                                  domProps: {
                                    textContent: _vm._s(listItem.modifiedDate)
                                  }
                                })
                              : _vm._e()
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-zone" }, [
                    _c("p", [_vm._v("조회결과가 없습니다.")])
                  ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [_c("p", [_vm._v("TOP")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    attrs: { wide: _vm.isTimeTable },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _vm.popType === "warn"
              ? _c(
                  "div",
                  { staticClass: "calendar-pop frame" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@assets/images/util/x.svg") }
                        })
                      ]
                    ),
                    _c("calendar", {
                      attrs: {
                        notificationType: _vm.notificationType,
                        isTimeTable: _vm.isTimeTable,
                        rangeOrder: _vm.rangeOrder
                      },
                      on: {
                        "selected-time-data": _vm.transSelectedTimeData,
                        "selected-date-val": _vm.transSelectedDate
                      }
                    }),
                    _c("div", { staticClass: "btn-wrap" }, [
                      _c("button", {
                        class: {
                          active:
                            (!_vm.isTimeTable && _vm.selectedDate !== null) ||
                            (_vm.selectedTime !== null &&
                              _vm.selectedDate !== null)
                        },
                        attrs: { type: "button" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.isTimeTable ? "선택완료" : "선택"
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.searchDate()
                          }
                        }
                      })
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
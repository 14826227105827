<template>
  <div class="sidemenu-context">
    <div class="side-header">
      <div class="img-wrap">
        <img src="@assets/images/util/kids.png" />
      </div>
      <div class="side-title">
        <h3>안아주기</h3>
        <p class="type">관리자</p>
      </div>
    </div>
    <div class="side-content">
      <div class="section">
        <h3 class="section-title" @click="test($event, 0)">일반 회원 관리</h3>
        <router-link class="section-link" to="/" @click.native="gotoUrl($event)" :class="{active:deepNavi===1&&this.$route.query.flag===undefined}">회원 정보 조회 및 관리</router-link>
        <router-link class="section-link" to="/withdrawed" @click.native="gotoUrl($event)" >탈퇴회원</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 1)">회원 권한 관리</h3>
        <router-link class="section-link" to="/managementList" @click.native="gotoUrl($event)" :class="{active:deepNavi===3}">관리자 관리</router-link>
        <router-link class="section-link" to="/doctorList" @click.native="gotoUrl($event)" :class="{active:deepNavi===4}">의사회원 관리</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 2)">게시판 관리</h3>
        <router-link class="section-link" to="/declare" @click.native="gotoUrl($event)":class="{active:this.$route.query.flag===true}">신고 내용 관리</router-link>
        <router-link class="section-link test" to="/noticeList" @click.native="gotoUrl($event)" :class="{active:deepNavi===6}">공지사항 관리</router-link>
        <router-link class="section-link test" to="/askList" @click.native="gotoUrl($event)">1:1 문의 관리</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 3)">설정</h3>
        <router-link class="section-link" to="/setting" @click.native="gotoUrl($event)">앱 버전 관리</router-link>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    deepNavi: {
      type: Number,
    },
  },
  data() {
    return {
      preUrl: '',
    };
  },
  created() {
    this.preUrl = this.$route.path;
  },
  methods: {
    callInUnAuth() {
      console.log('[callInUnAuth]');
      this.$emit('is-unauthorized');
    },
    gotoUrl(ev) {
      if (this.preUrl === this.$route.path) {
        this.$router.go();
        // window.location.reload()
      }

      this.preUrl = this.$route.path;
    },
    test(ev, index) {
      const section = document.querySelectorAll('.section')[index];

      if (section === undefined) { return; }

      const link = section.querySelectorAll('.section-link');

      if (link === undefined) { return; }

      for (let i = 0; i < link.length; i++) {
        if (link[i].classList.contains('hide')) {
          link[i].classList.remove('hide');
        } else {
          link[i].classList.add('hide');
        }
      }
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.sidemenu-context {
  width: 260px;
  background: #fff9f3;
  box-shadow: 0 3px 6px 0 rgba(54, 55, 57, 0.15);
  .side-header {
    padding: 15px;
    background: #fe6867;
    display: flex;
    align-items: center;
    height: 80px;
    .img-wrap {
      img {
        width: 44px;
        height: auto;
      }
    }
    .side-title {
      display: flex;
      h3 {
        @include initfontKor(
          $font-size: 22px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        position: relative;
        padding: 0 10px;
        &:after {
          position: absolute;
          content: "";
          width: 1px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 20px;
          background: #fff;
        }
      }
      p.type {
        @include initfontKor(
          $font-size: 19px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoR00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        padding: 0 10px;
      }
    }
  }
  .side-content {
    padding: 0 0 80px 0;
    .section {
      padding: 20px;
      position: relative;
      border-top: 1px solid #acb3c9;
      &.pressed {
        &:after {
          transform: rotate(180deg);
        }
      }
      &:after {
        position: absolute;
        width: 20px;
        height: 20px;
        content: "";
        top: 15px;
        right: 20px;
        background-image: url("~@/assets/images/util/dropdown.svg");
        background-repeat:no-repeat;
        cursor: pointer;
      }
      &:first-of-type {
        border-top: 0;
      }
      h3.section-title {
        @include initfontKor(
          $font-size: 19px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
        margin: 0 0 8px 0;
      }
      .router-link-exact-active {
        color: #fff;
        background: #fe6867;
        border-radius: 12px;
      }
      .active {
        color: #fff;
        background: #fe6867;
        border-radius: 12px;
      }
      .section-link {
        opacity: 1;
      }
      .section-link.hide {
        opacity: 0;
        height: 0;
        visibility: hidden;
      }
      a {
        width: 100%;
        display: block;
        &:hover {
          color: #fff;
          background: #fe6867;
          border-radius: 12px;
        }
        &:first-of-type {
          margin: 0;
        }
        @include initfontKor(
          $font-size: 19px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
        position: relative;
        margin: 5px 0 0 0;
        padding: 0 0 0 25px;
        &:after {
          content: "-";
          position: absolute;
          left: 7.5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>

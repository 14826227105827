var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-context editor-wrap" },
    [
      _c("ckeditor", {
        attrs: { editor: _vm.editor, config: _vm.editorConfig },
        on: {
          input: function($event) {
            return _vm.detact()
          },
          ready: _vm.onReady
        },
        model: {
          value: _vm.afterEditorData,
          callback: function($$v) {
            _vm.afterEditorData = $$v
          },
          expression: "afterEditorData"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-context" },
    [
      _c("side-menu", { on: { "is-unauthorized": _vm.isUnAuthorized } }),
      _c(
        "div",
        { staticClass: "wrap-context" },
        [
          _c("notification", {
            attrs: { tree: _vm.tree },
            on: { "call-logout-popup": _vm.callLogoutPopup }
          }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "div",
                { staticClass: "noti-zone" },
                [
                  _c("h5", { staticClass: "title" }, [_vm._v("공지사항 관리")]),
                  _c("list-filter", {
                    ref: "filter",
                    attrs: { listInfo: _vm.listInfo },
                    on: {
                      "request-list": _vm.injectionResult,
                      "default-search": _vm.getDefaultSearch
                    }
                  }),
                  _c("div", { staticClass: "add-notice" }, [
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.add()
                          }
                        }
                      },
                      [_vm._v("추가")]
                    )
                  ])
                ],
                1
              ),
              _c("list", {
                ref: "list",
                attrs: {
                  columnInfo: _vm.columnInfo,
                  listInfo: _vm.listInfo,
                  response0: _vm.response,
                  listIndex: _vm.listIndex,
                  reverse: _vm.reverse
                },
                on: {
                  "list-amount-change": _vm.changedListAmount,
                  "notice-delete": _vm.deleteNotice,
                  "list-reversal": _vm.reversalResult
                }
              }),
              _c("pagination", {
                ref: "pagination",
                attrs: {
                  paginationInfo: _vm.paginationInfo,
                  response0: _vm.listInfo
                },
                on: { "btn-click": _vm.paginationClick }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.checkPopup
        ? _c("default-popup", {
            attrs: { popupSet: _vm.popupSet },
            on: { "result-check": _vm.resultCheck }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
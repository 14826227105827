<template>
  <div class="app-context">
    <side-menu :deepNavi="deep"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">공지사항 상세</h5>
        <div class="notice">
          <div class="column-info">
            <div class="article">
              <h6 class="header">제목</h6>
              <div class="content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.title.value"
                    :readonly="readonlyFlag === false"
                  />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">작성일</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="form.writedDate.value" readonly />
                </div>
              </div>
            </div>
            <!-- <div class="article">
              <h6 class="header">작성자</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="form.writer.value" />
                </div>
              </div>
            </div> -->
          </div>
          <div class="content-wrap">
            <!-- 에디터 자리 -->
            <editor
              v-if="editButtonFlag !== false"
              :editorData="form.content.value"
              readonly
              @sendEditContent="synchronization"
              :afterEditorData="form.content.value"
            ></editor>
            <div class="content" v-else v-html="form.content.value"></div>
          </div>
          <div class="attachment-wrap" v-if="form.attachment.filename || editButtonFlag !== 'read'">
            <div class="attachment viewer">
              <div class="a-input-wrap">
                <span class="file-name" v-text="form.attachment.filename" />
                <input
                  class="hidden file-upload"
                  type="file"
                  @click.stop
                  @change="onFileChange($event)"
                  ref="inputFile"
                  readonly
                />
              </div>
              <div class="a-btn-wrap" v-if="editButtonFlag !== 'read'">
                <button type="button" @click="attachmentFile()">
                  파일첨부
                </button>
              </div>
              <div class="cancelUploadFile">
                <span v-if="editButtonFlag !== 'read'" @click="cancelUploadFile()">X</span>
              </div>
            </div>
            <!--
            <div class="preview-img" v-if="form.attachment.url !== ''">
              <img :src="form.attachment.url" />
            </div>
             -->
          </div>
          <div class="bottom-btn-wrap">
            <button type="button" @click="$router.go(-1)">이전으로</button>
            <button
              type="button"
              class="confirm"
              @click="submitNotice()"
              v-if="editButtonFlag === 'edit'"
            >
              수정하기
            </button>
            <button
              type="button"
              class="confirm"
              @click="submitNotice()"
              v-if="editButtonFlag === 'register'"
            >
              등록하기
            </button>
            <!-- <button type="button" class="delete" @click="callDeletePop()" v-if="isModify">삭제하기</button> -->
            <div class="checkboxWrap">
              <input
                type="checkbox"
                @click="importancyFlag()"
                :checked="formInner.important"
                v-model="formInner.important"
                :disabled="disabledFlag"
                class="topCheckbox"
                ref="input"
              /><span class="checkboxText">TOP 공지 설정</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @api-called="deleteNotice" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import Editor from '@/components/Editor.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '게시판 관리',
        detail: '공지사항 관리',
        detail2: '공지사항 상세',
      },
      option: {
        start: 0,
        size: 5,
        sort: 0,
        order: 1,
      },
      deep: 6,
      form: {
        title: {
          value: '',
          flag: false,
        },
        writedDate: {
          value: '',
          flag: false,
        },
        writer: {
          value: '',
          flag: false,
        },
        content: {
          value: '',
          flag: false,
        },
        attachment: {
          title: '서명',
          flag: false,
          source: null,
          url: '',
          filename: '',
        },
      },
      formInner: {
        title: '',
        content: '',
        attachment: '',
        important: 0,
      },
      modify: {
        nid: '',
        title: '',
        content: '',
        important: 0,
      },
      isModify: false,
      editButtonFlag: this.$route.query.isFlag,
      readonlyFlag: '',
      disabledFlag: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.setEditButtonFlag();
    this.fetchData();
  },
  mounted() {},
  methods: {
    ...mapActions('member', [
      'CREATE_NOTICE',
      'UPLOAD_NOTICE_FILE',
      'EDIT_NOTICE',
      'NOTICE_LIST',
      'NOTICE_DETAIL',
    ]),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    setEditButtonFlag() {
      if (this.editButtonFlag === 'false' || this.editButtonFlag === false) {
        this.editButtonFlag = 'read';
      } else if (this.editButtonFlag === 'true' || this.editButtonFlag === true) {
        this.editButtonFlag = 'edit';
      } else if (this.editButtonFlag === undefined) {
        this.editButtonFlag = 'register';
      }
      if (this.editButtonFlag !== 'read') {
        this.readonlyFlag = true;
      }
      // this.disabledFlag = !this.editButtonFlag;
      if (this.editButtonFlag === 'read') {
        this.disabledFlag = true;
      }
    },
    async submitNotice() {
      console.log('form :', this.form);

      const formData = new FormData();
      formData.append('title', this.form.title.value);
      formData.append('content', this.form.content.value);

      if (this.form.attachment.flag) {
        formData.append('file', this.form.attachment.source);
      }

      const result = true;

      if (this.$route.query.nid === undefined) {
        if (this.form.title.value === '') {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '제목을 기입해주세요.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
          return;
        }
        if (this.form.content.value === '') {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '내용을 기입해주세요.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
          return;
        }
        const option = { attachment: this.form.attachment.filename };

        this.formInner.title = this.form.title.value;
        this.formInner.content = this.form.content.value;
        if (this.form.attachment.filename !== undefined && this.form.attachment.filename !== '') {
          this.onFileChange();

          this.formInner.attachment = this.form.attachment.filename;
        } else {
          delete this.modify.attachment;
        }
        this.formInner.important = this.importancyFlag();
        try {
          await this.CREATE_NOTICE(this.formInner).then((data) => {
            console.log(data, '등록완료');
          });
        } catch (e) {
          console.log('error', e);
          alert('통신상의 이유로 등록되지 않았습니다');
          return false;
        } finally {
          window.history.go(-1);
        }
      } else {
        this.modify.title = this.form.title.value;
        this.modify.content = this.form.content.value;
        this.modify.important = this.importancyFlag();
        if (this.modify.important) {
          this.modify.important = 1;
        } else {
          this.modify.important = 0;
        }
        if (this.form.attachment.filename !== undefined) {
          this.modify.attachment = this.form.attachment.filename;
        } else {
          delete this.modify.attachment;
        }
        try {
          await this.EDIT_NOTICE(this.modify);
        } catch (e) {
          console.log('error', e);
          alert('통신상의 이유로 등록되지 않았습니다');
          return false;
        } finally {
          window.history.go(-1);
        }
      }
    },
    importancyFlag() {
      if (this.$refs.input.checked) {
        return 1;
      }
      return 0;
    },
    deleteNotice() {
      /** 삭제할 예약의 value 를 서버에 전송(아마도  ID값) */
      setTimeout(() => {
        this.DELETE_NOTICE(`?id=${this.$route.params.code}`).then((data) => {
          this.SET_POPUP(true);
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = data.result
            ? '공지사항이 삭제 되었습니다.'
            : '공지사항 삭제에 실패했습니다.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = '/NoticeList';
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
        });
      }, 1);
    },
    callDeletePop() {
      // 신규 작성 삭제 제한
      if (this.$route.params.code == undefined) {
        return;
      }

      this.SET_POPUP(true);
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '공지사항을 삭제하시겠습니까?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '예';
    },
    async fetchData() {
      const date = new Date();
      const str = `${date
        .getFullYear()
        .toString()
        .padStart(4, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')} ${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date
        .getSeconds()
        .toString()
        .padStart(2, '0')}`;
      this.form.writedDate.value = this.transDateTime(`${str}`);
      if (this.$route.query.nid === undefined) {
        // this.form.writer.value = localStorage.getItem('userName');

        return;
      }
      delete this.$route.query.isFlag;

      await this.NOTICE_DETAIL(this.$route.query).then(async (data) => {
        console.log(data, '체크');
        this.form.writedDate.value = data.response.createDate;
        this.isModify = true;
        this.form.title.value = data.response.title;
        this.formInner.important = Number(data.response.important);
        this.synchronization(data.response.content);
        this.form.content.value = data.response.content;
        if (data.response.file[0] !== undefined) {
          this.form.attachment.filename = data.response.file[0];
          // this.form.attachment.url = data.response.files[0].filePath;
        }
        this.modify.nid = data.response.id;
        if (
          data.response.file !== undefined
          && data.response.file !== null
          && data.response.file !== ''
        ) {
          this.form.attachment.filename = decodeURI(
            data.response.file.substring(data.response.file.lastIndexOf('/') + 1),
          );
          this.form.attachment.url = `${process.env.VUE_APP_MAIN_URL}${data.response.file}`;
        }
      });
    },
    leadingZeros(n, digits) {
      let zero = '';
      n = n.toString();

      if (n.length < digits) {
        for (i = 0; i < digits - n.length; i++) {
          zero += '0';
        }
      }
      return zero + n;
    },
    getList() {},
    synchronization(editorData) {
      this.form.content.value = editorData;
    },
    async onFileChange(event) {
      const getFile = event.target.files[0];
      const formData = new FormData();

      formData.append('attachment', event.target.files[0]);
      try {
        await this.UPLOAD_NOTICE_FILE(formData).then((data) => {
          this.form.attachment.filename = data.response.filePath;
          this.form.attachment.filename = this.form.attachment.filename.replace(
            '/admin/api/v1/notice/',
            '',
          );
        });
      } catch (e) {
        alert(e.data.message);
      }
    },
    attachmentFile() {
      document.querySelector('input.file-upload').click();
    },
    cancelUploadFile() {
      this.form.attachment.filename = '';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    transDateTime() {
      return (date) => {
        console.log('date :', date);

        if (date === null) {
          return '';
        }

        return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    Editor,
  },
};
</script>
<style lang="scss" scoped>
.notice {
  .column-info {
    width: 710px;
    margin: 0 0 20px 0;
    .article {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 205px 1fr;
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      h6.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
      .content {
        outline: 1px solid #acb3c9;
        .input-wrap {
          width: 100%;
          margin: 0 10px 0 0;
          input {
            width: 100%;
            border: 0;
            padding: 0 12px;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
        }
      }
    }
  }
  .content-wrap {
    width: 710px;
    margin: 0 0 20px 0;
    .content {
      min-height: 400px !important;
      min-width: 710px !important;
      outline: 1px solid #acb3c9;
      padding: 5px;
      /deep/ p {
        width: 100%;
        a {
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
  .attachment-wrap {
    width: 100%;
    margin: 0 0 65px 0;
    .attachment {
      display: flex;
      flex-wrap: wrap;
      .a-input-wrap {
        border: 1px solid #acb3c9;
        height: 30px;
        width: 310px;
        margin: 0 15px 0 0;
        cursor: pointer;
        span.file-name {
          padding: 0 12px;
          display: block;
          width: inherit;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 30px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
        input.hidden {
          display: none;
        }
      }
      .cancelUploadFile {
        width: 70px;
        line-height: 30px;
        text-align: center;
        span {
          background: #fe6867;
          padding: 3px;
          border-radius: 3px;
          color: #fff;
        }
      }
      .a-btn-wrap {
        width: 105px;
        height: 32px;
        background: #fe6867;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        overflow: hidden;

        button {
          border-radius: 0;
          border: 0;
          background: transparent;
          height: 100%;
          width: 100%;
          @include initfontKor(
            $font-size: 19px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          cursor: pointer;
        }
      }
    }
    .preview-img {
      margin: 25px 0 0 0;
      width: 460px;
      position: relative;
      img {
        max-width: 100%;
      }
    }
  }
  .bottom-btn-wrap {
    button {
      width: 140px !important;
      &.delete {
        background: #ff0000;
      }
      &.confirm {
        background: #fe6867;
      }
    }
  }
}
.checkboxWrap {
  width: 20%;
  font-size: 24px;
  text-align: right;
  line-height: 52px;
  input[type="checkbox"] {
    display: inline-block;
    position: static;
    margin-right: 10px;
  }
}
/**

 */
</style>

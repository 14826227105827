import { activity } from "@/api";

const state = {
  activity: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  }
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_LIFE_LIST({}, payload) {
    return activity.fetchLifeList(payload);
  },
  FETCH_LIFE_DETAIL({}, payload) {
    return activity.fetchLifeDetail(payload);
  },
  FETCH_LIFE_RESULT({}, payload) {
    return activity.fetchLifeResult(payload);
  },
  FETCH_PF_LIST({}, payload) {
    return activity.fetchPFList(payload);
  },
  FETCH_PF_DETAIL({}, payload) {
    return activity.fetchPFDetail(payload);
  },
  FETCH_PF_RESULT({}, payload) {
    return activity.fetchPFResult(payload);
  },
  FETCH_PF_RESULT_IMAGE({}, payload) {
    return activity.fetchPFResultImage(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

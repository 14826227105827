import {
  auth,
} from '@/api';

const state = {
  auth: {
    fcmToken: "",
    deviceId: "",
    appVersion: ""
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  SET_FCMTOKEN(state, fcmToken) {
    state.auth.fcmToken = fcmToken;
  },
  SET_DEVICEID(state, deviceId) {
    state.auth.deviceId = deviceId;
  },
  SET_APPVERSION(state, appVersion) {
    state.auth.appVersion = appVersion;
  }
};

const getters = {
  GET_FCMTOKEN: state => state.auth.fcmToken,
  GET_DEVICEID: state => state.auth.deviceId,
  GET_APPVERSION: state => state.auth.appVersion
};

const actions = {
  // LOGIN_AUTO({}, loginInfo) {
  //   return auth.loginAuto(loginInfo);
  // }
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div class="filter-context">
    <div class="btn-wrap-absolute">
      <button v-if="listInfo.type===1 || listInfo.type===2 ||listInfo.type===6" type="button" @click="search(filterOption,0)">검색</button>
      <button v-if="listInfo.type===5" type="button" @click="search(filterOptionDeclare,0)">검색</button>
      <button v-if="listInfo.type===7" type="button" @click="search(filterOptionAsk,0)">검색</button>
      <button v-if="listInfo.type===1 || listInfo.type===2 ||listInfo.type===6" type="button" @click="reset(); search(filterOption)">초기화</button>
      <button v-if="listInfo.type===5" type="button" @click="reset(); search(filterOptionDeclare)">초기화</button>
      <button v-if="listInfo.type===7" type="button" @click="reset(); search(filterOptionAsk)">초기화</button>
    </div>
    <div class="filter" v-if="this.listInfo.type===1 || this.listInfo.type===2">
      <div class="article">
        <h5 class="f-title">성별</h5>
        <div class="f-content">
          <div class="radio-input-wrap">
            <div class="item">
              <input type="radio" id="entire" v-model="filterOption.gender" checked="checked" value="0" />
              <label for="entire">전체</label>
            </div>
            <div class="item">
              <input type="radio" id="male" v-model="filterOption.gender" value="1" />
              <label for="male">남성</label>
            </div>
            <div class="item">
              <input type="radio" id="female" v-model="filterOption.gender" value="2" />
              <label for="female">여성</label>
            </div>
          </div>
        </div>
      </div>
      <div class="article">
        <h5 class="f-title">나이</h5>
        <div class="f-content">
          <div class="input-wrap">
            <input type="number" class="center" v-model="filterOption.ageFirst" />
          </div>
          <span class="separate">~</span>
          <div class="input-wrap">
            <input class="center" type="number" v-model="filterOption.ageLast" />
          </div>
        </div>
      </div>

      <div class="article">
        <h5 class="f-title">검색</h5>
        <div class="f-content">
          <div class="input-wrap wide">
            <input type="text" placeholder="가입일('-'포함)/아이디/닉네임/이메일" @keyup.enter="search(filterOption, 0)" v-model="filterOption.fakeQ" />
          </div>
        </div>
      </div>
    </div>
    <div class="filter" v-if="this.listInfo.type===7">
      <div class="article">
        <h5 class="f-title">답변 여부</h5>
        <div class="f-content">
          <div class="radio-input-wrap">
            <div class="item">
              <input type="radio" id="entire" v-model="filterOptionAsk.status" checked value="0" />
              <label for="entire">전체</label>
            </div>
            <div class="item">
              <input type="radio" id="male" v-model="filterOptionAsk.status" value="1" />
              <label for="male">답변대기</label>
            </div>
            <div class="item">
              <input type="radio" id="female" v-model="filterOptionAsk.status" value="2" />
              <label for="female">답변완료</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter" v-if="this.listInfo.type===5">
      <div class="article">
        <h5 class="f-title">유형</h5>
        <div class="f-content">
          <div class="radio-input-wrap">
            <div class="item">
              <input type="radio" id="entire" v-model="filterOptionDeclare.type" checked value="0" />
              <label for="entire">전체</label>
            </div>
            <div class="item">
              <input type="radio" id="male" v-model="filterOptionDeclare.type" value="1" />
              <label for="male">게시글</label>
            </div>
            <div class="item">
              <input type="radio" id="female" v-model="filterOptionDeclare.type" value="2" />
              <label for="female">댓글</label>
            </div>
            <div class="item">
              <input type="radio" id="female" v-model="filterOptionDeclare.type" value="3" />
              <label for="female">답글</label>
            </div>
          </div>
        </div>
      </div>
      <div class="article">
        <h5 class="f-title">상태</h5>
        <div class="f-content">
          <div class="radio-input-wrap">
            <div class="item">
              <input type="radio" id="entire" v-model="filterOptionDeclare.status" checked value="0" />
              <label for="entire">전체</label>
            </div>
            <div class="item">
              <input type="radio" id="one" v-model="filterOptionDeclare.status" value="1" />
              <label for="one">신고 1회</label>
            </div>
            <div class="item">
              <input type="radio" id="two" v-model="filterOptionDeclare.status" value="2" />
              <label for="two">신고 2회</label>
            </div>
            <div class="item">
              <input type="radio" id="three" v-model="filterOptionDeclare.status" value="3" />
              <label for="three">신고 3회</label>
            </div>
            <div class="item">
              <input type="radio" id="three" v-model="filterOptionDeclare.status" value="4" />
              <label for="three">신고 취소</label>
            </div>
          </div>
        </div>
      </div>

      <div class="article">
        <div class="f-title" :class="[ qTypeToggle ? 'isReversal' : 'Reversal' ]" @click="spreadQType()">
          <p  class="qTypeBtn">{{qTypeText}}</p>
            <div class="qTypeRelative">
              <ul class="qTypeWrap" ref="qType">
                <li><input value="신고 당한 회원" type="text" @click="setQType(1,'신고 당한 회원','닉네임')" readonly><span></span></li>
                <li><input value="신고한 회원" type="text" @click="setQType(2,'신고한 회원','닉네임')" readonly></li>
                <li><input value="신고일" type="text" @click="setQType(3,'신고일',`신고일('-' 포함)`)" readonly></li>
              </ul>
            </div>
        </div>
        <div class="f-content">
          <div class="input-wrap wide">
            <input type="text" :placeholder="placeHolder" @keyup.enter="search(filterOptionDeclare, 0)" v-model="filterOptionDeclare.fakeQ" />
          </div>
        </div>
      </div>
    </div>
    <div class="filter" v-if="this.listInfo.type===6">
      <div class="article">
        <h5 class="f-title">작성일</h5>
        <div class="f-content">
          <div class="calendar-wrap">
            <div class="item" @click="callCalendarPop(0)">
              <p v-text="filterOption.signUpFirst" />
            </div>
            <span class="separate">~</span>
            <div class="item" @click="callCalendarPop(1)">
              <p v-text="filterOption.signUpLast" />
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="today(0)">오늘</button>
            <button type="button" @click="month(0)">1개월</button>
            <button type="button" @click="halfYear(0)">6개월</button>
            <button type="button" @click="entire(0)">1년</button>
          </div>
        </div>
      </div>

      <div class="article">
        <h5 class="f-title">검색</h5>
        <div class="f-content">
          <div class="input-wrap wide">
            <input type="text" placeholder="아이디/제목" @keyup.enter="search(filterOption, 0)" v-model="filterOption.fakeQ" />
          </div>
        </div>
      </div>
    </div>
    <div class="filter" v-if="this.listInfo.type===7">
      <div class="article">
        <h5 class="f-title">등록일</h5>
        <div class="f-content">
          <div class="calendar-wrap">
            <div class="item" @click="callCalendarPop(0)">
              <p v-text="filterOption.signUpFirst" />
            </div>
            <span class="separate">~</span>
            <div class="item" @click="callCalendarPop(1)">
              <p v-text="filterOption.signUpLast" />
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="today(0)">오늘</button>
            <button type="button" @click="month(0)">1개월</button>
            <button type="button" @click="halfYear(0)">6개월</button>
            <button type="button" @click="entire(0)">1년</button>
          </div>
        </div>
      </div>

      <div class="article">
        <h5 class="f-title">검색</h5>
        <div class="f-content">
          <div class="input-wrap wide">
            <input type="text" placeholder="아이디/제목" @keyup.enter="search(filterOptionAsk, 0)" v-model="filterOptionAsk.fakeQ" />
          </div>
        </div>
      </div>
    </div>
    <calendar-popup
      @selected-date="selectedDate"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import CalendarPopup from '@/components/modal/CalendarPopup';
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    dateLabel1: {
      type: String,
      default: '예약일',
    },
    dateLabel2: {
      type: String,
      default: null,
    },
    listInfo: {
      type: Object,
    },
  },
  data() {
    return {
      filterOption: {
        start: 0,
        size: 10,
        gender: 0,
        sort: 0,
        order: 1,
        ageFirst: null,
        ageLast: null,
        q: '',
        signUpFirst: null,
        signUpLast: null,
        // signUpFirst2: null,
        // signUpLast2: null,
        // listAmount: 10,
        // clickedPage: 1,
      },
      filterOptionAsk: {
        start: 0,
        size: 10,
        status: 0,
        sort: 0,
        order: 0,
        q: '',
        // signUpFirst2: null,
        // signUpLast2: null,
        // listAmount: 10,
        // clickedPage: 1,
      },
      filterOptionDeclare: {
        start: 0,
        size: 30,
        type: 0,
        qtype: 1,
        q: '',
        status: 0,
        sort: 4,
        order: 1,
      },
      dateOpt: {
        today: null,
        month: null,
        halfYear: null,
        entire: null,
      },
      qTypeText: '신고일',
      qTypeToggle: 1,
      placeHolder: "신고일('-' 포함)",
      popupSetCalendar: {},
    };
  },
  created() {
    this.defaultSearch();
    this.initDateOpt();
  },
  mounted() {
    this.spreadQType();
  },
  methods: {
    ...mapMutations('basic', ['SET_CALENDAR_POPUP']),
    selectedDate(date, order) {
      if (order === 0) {
        this.filterOption.signUpFirst = date;
      } else if (order === 1) {
        this.filterOption.signUpLast = date;
      } else if (order === 2) {
        this.filterOption.signUpFirst2 = date;
      } else if (order === 3) {
        this.filterOption.signUpLast2 = date;
      }
    },
    defaultSearch() {
      let option;
      if (this.listInfo.type === 5) { option = this.filterOptionDeclare; } else if (this.listInfo.type === 7) { option = this.filterOptionAsk; } else {
        option = this.filterOption;
      }
      this.$emit('default-search', option);
    },
    initDateOpt() {
      this.dateOpt.today = this.$moment(new Date()).format('YYYY-MM-DD');
      this.dateOpt.month = this.$moment(
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
      ).format('YYYY-MM-DD');
      this.dateOpt.halfYear = this.$moment(
        new Date(new Date().setMonth(new Date().getMonth() - 6)),
      ).format('YYYY-MM-DD');
      this.dateOpt.entire = this.$moment(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      ).format('YYYY-MM-DD');
    },
    callCalendarPop(order) {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.rangeOrder = order;
    },
    reset() {
      this.filterOption.gender = '0';
      this.filterOption.ageFirst = null;
      this.filterOption.ageLast = null;
      this.filterOption.q = '';
      this.filterOption.signUpFirst = null;
      this.filterOption.signUpLast = null;
      this.filterOption.signUpFirst2 = null;
      this.filterOption.signUpLast2 = null;
      if (this.listInfo.type === 5) {
        this.filterOptionDeclare = {
          start: 0,
          size: 30,
          type: 0,
          qtype: 1,
          q: '',
          status: 0,
          sort: 4,
          order: 1,
        };
      } else if (this.listInfo.type === 7) {
        this.filterOptionAsk = {
          start: 0,
          size: 10,
          status: 0,
          sort: 0,
          order: 0,
          q: '',
          fakeQ: '',
        };
      } else {
        this.filterOption.gender = '0';
        this.filterOption.ageFirst = null;
        this.filterOption.ageLast = null;
        this.filterOption.q = '';
        this.filterOption.fakeQ = '';
        this.filterOption.signUpFirst = null;
        this.filterOption.signUpLast = null;
        this.filterOption.signUpFirst2 = null;
        this.filterOption.signUpLast2 = null;
      }
    },

    changeClickedPage(value) {
      this.filterOption.clickedPage = value;
      // this.search(this.filterOption, 1);
    },
    changeListAmount(value) {
      if (this.listInfo.type === 5) {
        this.filterOptionDeclare.listAmount = value;
        this.search(this.filterOptionDeclare, 'changeAmount');
      } else if (this.listInfo.type === 7) {
        this.filterOptionAsk.listAmount = value;
        this.search(this.filterOptionAsk, 'changeAmount');
      } else {
        this.filterOption.listAmount = value;
        this.search(this.filterOption, 'changeAmount');
      }
    },

    /** 회원(1),탈퇴(2),관리자(3),의사(4),신고(5),공지사항(6)
    */
    search(option, search) {
      if (this.listInfo.type === 1 || this.listInfo.type === 2) {
        if (search === 0) { this.filterOption.q = this.filterOption.fakeQ; }
        if (option.ageFirst < 0) { alert('나이가 0세보다 적습니다. 확인해주세요'); return false; }
        if (option.gender === '1') { option.gender = 1; } else if (option.gender === '2') { option.gender = 2; } else if (option.gender === '0') { option.gender = 0; }
      }
      if (this.listInfo.type === 5) {
        if (search === 0) { this.filterOptionDeclare.q = this.filterOptionDeclare.fakeQ; }
        if (option.type === '0') { option.type = 0; } else if (option.type === '1') { option.type = 1; } else if (option.type === '2') { option.type = 2; } else if (option.type === '3') { option.type = 3; }
        if (option.status === '0') { option.status = 0; } else if (option.status === '1') { option.status = 1; } else if (option.status === '2') { option.status = 2; } else if (option.status === '3') { option.status = 3; } else if (option.status === '4') { option.status = 4; }

        this.qTypeToggle = 1;
        this.spreadQType();
      }

      if (this.listInfo.type === 6) {
        if (search === 0) { this.filterOption.q = this.filterOption.fakeQ; }
        if (this.filterOption.signUpFirst !== '') { option.startdate = this.filterOption.signUpFirst; } else if (this.filterOption.signUpLast !== '') { option.enddate = this.filterOption.signUpLast; }
      }
      if (this.listInfo.type === 7) {
        if (search === 0) { this.filterOptionAsk.q = this.filterOptionAsk.fakeQ; }
        if (this.filterOptionAsk.signUpFirst !== '') { option.startdate = this.filterOptionAsk.signUpFirst; } else if (this.filterOptionAsk.signUpLast !== '') { option.enddate = this.filterOptionAsk.signUpLast; }
        if (this.filterOptionAsk.status === '2') { option.status = 2; } else if (this.filterOptionAsk.status === '1') { option.status = 1; } else if (this.filterOptionAsk.status === '0') { option.status = 0; }
      }
      this.$emit('request-list', option, search);
    },
    spreadQType() {
      if (this.listInfo.type === 5) {
        if (this.qTypeToggle === 0) {
          this.$refs.qType.style.height = '120px';
          this.qTypeToggle = 1;
        } else if (this.qTypeToggle === 1) {
          this.$refs.qType.style.height = 0;
          this.qTypeToggle = 0;
        }
      }
    },
    setQType(qtype, qTypeText, placeHolder) {
      if (this.listInfo.type === 5) {
        this.filterOptionDeclare.qtype = qtype;
        this.qTypeText = qTypeText;
        this.search(this.filterOptionDeclare, 0);
        this.qTypeToggle = 0;
        this.placeHolder = placeHolder;
        this.spreadQType();
      }
    },
    today(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.today;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.today;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },
    month(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.month;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.month;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },
    halfYear(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.halfYear;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.halfYear;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },
    entire(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.entire;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.entire;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },

  },
  computed: {
    ...mapState('basic', ['checkCalendarPopup']),
  },
  components: {
    CalendarPopup,
  },
};
</script>
<style lang="scss" scoped>
.filter-context {
  position: relative;
  padding: 40px;
  background: #f3f3f3;
  margin: 0 0 50px 0;
  .btn-wrap-absolute {
    position: absolute;
    bottom: 20px;
    right: 20px;
    button {
      border: 0;
      padding: 0;
      width: 105px;
      height: 40px;
        background: #fe6867;
      margin: 0 0 0 10px;
      cursor: pointer;
      border-radius: 5px;
      @include initfontKor(
        $font-size: 19px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
    }
  }
  .filter {
    .article {
      margin: 20px 0 0 0;
      display: flex;
      &:first-of-type {
        margin: 0;
      }

      .f-title {
        width: 140px;
        white-space: nowrap;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );

      }
      div.f-title{
          width:200px;
          position: relative;
          &.isReversal {
            &:after {
              background-image: url("~@/assets/images/util/dropdown.svg");
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
    background-position: right;
    top: 19px;
    right: 26px;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            cursor: pointer;
            }
          }
          &.Reversal:after {
              background-image: url("~@/assets/images/util/dropdown.svg");
              transform: rotate(180deg);
              position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            top: 10px;
            right:30px;
            background-position: right;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            cursor: pointer;
              transform: rotate(180deg);

          }

        .qTypeBtn{
          background:#fff; text-indext:10px; margin-right:10px;
          height:40px;}
        .qTypeRelative{position:relative; height:0;}
        .qTypeWrap{background:#fff; text-indext:10px;  margin-right:10px; transition:all 0.5s; overflow:hidden; width:180px; height:0; position: absolute;

        li{
          height:40px;
          input{position:absolute; background:transparent}
          }

        }
      }
      .f-content {
        width: calc(100% - 140px);
        display: flex;
        .radio-input-wrap {
          display: flex;
          .item {
            display: flex;
            align-items: center;
            margin: 0 0 0 20px;
            &:first-of-type {
              margin: 0;
            }
            input[type="radio"] {
              border-radius: 50%;

              &:after {
                width: 19px;
                height: 19px;
                border-radius: 50%;
                background: var(--active-inner);
                opacity: 0;
                transform: scale(var(--s, 0.7));
              }

              &:checked {
                --s: 0.5;
              }
              &:disabled {
                &:checked {
                  background: #146f83 !important;
                }
              }
            }
            input {
              --active: #146f83;
              --active-inner: #fff;
              --focus: 2px rgb(20 111 131 / 34%);
              --border: #bbc1e1;
              --border-hover: rgb(20 111 131 / 34%);
              --background: #fff;
              --disabled: #f6f8ff;
              --disabled-inner: #e1e6f9;
              -webkit-appearance: none;
              -moz-appearance: none;
              height: 21px;
              outline: none;
              display: inline-block;
              vertical-align: top;
              position: relative;
              margin: 0;
              cursor: pointer;
              border: 1px solid var(--bc, var(--border));
              background: var(--b, var(--background));
              transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
              &:after {
                content: "";
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
              }

              &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: 0.3s;
                --d-t: 0.6s;
                --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
              }

              &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: 0.9;

                &:checked {
                  --b: var(--disabled-inner);
                  --bc: var(--border);
                }

                & + label {
                  cursor: not-allowed;
                }
              }

              &:hover {
                &:not(:checked) {
                  &:not(:disabled) {
                    --bc: var(--border-hover);
                  }
                }
              }

              &:focus {
                box-shadow: 0 0 0 var(--focus);
              }

              &:not(.switch) {
                width: 21px;

                &:after {
                  opacity: var(--o, 0);
                }

                &:checked {
                  --o: 1;
                }
              }

              & + label {
                font-family: "NotoSansCJKkr";
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 29px;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
                cursor: pointer;
                margin: 0 0 0 8px;
              }
            }
          }
        }
        .input-wrap {
          width: 110px;
          height: 40px;
          &.wide {
            width: 452px;
            ::placeholder{
              @include initfontKor(
                $font-size: 20px,
                $line-height: 40px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #a9adb1,
                $letter-spacing: -0.6px,
                $text-align: left
              );
            }
          }
          &:first-of-type {
          }
          input {
            width: inherit;
            border: 0;
            padding: 0 20px;
            @include initfontKor(
              $font-size: 22px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
            &.center {
              text-align: center;
            }
          }
        }
        .calendar-wrap {
          display: flex;
          margin: 0 30px 0 0;
          .item {
            min-width: 210px;
            position: relative;
            height: 40px;
            background: transparent;
            cursor: pointer;
            &:after {
              position: absolute;
              content: "";
              background-image: url("~@/assets/images/util/icon_calendar_color.svg");
              width: 30px;
              height: 30px;
              right: 10px;
              top: calc(50% - 1.5px);
              transform: translateY(-50%);
            }
            p {
              width: 100%;
              height: inherit;
              padding: 0 20px;
              background: #fff;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 40px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
          }
        }
        .btn-wrap {
          button {
            margin: 0 0 0 15px;
            min-width: 90px;
            padding: 0;
            background: #fe6867;
            border: 0;
            cursor: pointer;
            border-radius: 5px;
            @include initfontKor(
              $font-size: 19px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              margin: 0;
            }
          }
        }
        span.separate {
          display: block;
          width: 12px;
          margin: 0 10px;
          @include initfontKor(
            $font-size: 22px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-context" },
    [
      _c("side-menu"),
      _c(
        "div",
        { staticClass: "wrap-context" },
        [
          _c("notification", {
            attrs: { tree: _vm.tree },
            on: { "call-logout-popup": _vm.callLogoutPopup }
          }),
          _c("div", { staticClass: "container" }, [
            _c("h5", { staticClass: "title special" }, [_vm._v("앱 관리")]),
            _c("div", { staticClass: "version" }, [
              _c("h5", { staticClass: "title" }, [_vm._v("IOS 앱버전관리")]),
              _c("div", { staticClass: "article-wrap" }, [
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("버전")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "input-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.version1.currentVersion.value,
                            expression: "version1.currentVersion.value"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.version1.currentVersion.value },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.version1.currentVersion,
                                "value",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.isWritedVersion(1)
                            }
                          ]
                        }
                      })
                    ])
                  ])
                ]),
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("옵션")]),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "select-wrap" },
                      [
                        _c("v-select", {
                          attrs: {
                            searchable: false,
                            options: _vm.version1.versionStatusList
                          },
                          on: {
                            input: function($event) {
                              return _vm.choiceVersionOpt(1)
                            }
                          },
                          model: {
                            value: _vm.version1.versionStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.version1, "versionStatus", $$v)
                            },
                            expression: "version1.versionStatus"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("웹스토어")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "input-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.version1.url,
                            expression: "version1.url"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.version1.url },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.version1, "url", $event.target.value)
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.versionApi1()
                      }
                    }
                  },
                  [_vm._v("저장")]
                )
              ])
            ]),
            _c("div", { staticClass: "version" }, [
              _c("h5", { staticClass: "title" }, [
                _vm._v("안드로이드 앱버전관리")
              ]),
              _c("div", { staticClass: "article-wrap" }, [
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("버전")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "input-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.version2.currentVersion.value,
                            expression: "version2.currentVersion.value"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.version2.currentVersion.value },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.version2.currentVersion,
                                "value",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.isWritedVersion(2)
                            }
                          ]
                        }
                      })
                    ])
                  ])
                ]),
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("옵션")]),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "select-wrap" },
                      [
                        _c("v-select", {
                          attrs: {
                            searchable: false,
                            options: _vm.version2.versionStatusList
                          },
                          on: {
                            input: function($event) {
                              return _vm.choiceVersionOpt(2)
                            }
                          },
                          model: {
                            value: _vm.version2.versionStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.version2, "versionStatus", $$v)
                            },
                            expression: "version2.versionStatus"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("옵션")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "input-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.version2.url,
                            expression: "version2.url"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.version2.url },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.version2, "url", $event.target.value)
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.versionApi2()
                      }
                    }
                  },
                  [_vm._v("저장")]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm.checkPopup
        ? _c("default-popup", { attrs: { popupSet: _vm.popupSet } })
        : _vm._e(),
      _vm.checkSearchPopup
        ? _c("search-popup", { on: { "get-member-id": _vm.applyMember } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
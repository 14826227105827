var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-context" },
    [
      _c("side-menu", { on: { "is-unauthorized": _vm.isUnAuthorized } }),
      _c(
        "div",
        { staticClass: "wrap-context" },
        [
          _c("notification", {
            attrs: { tree: _vm.tree },
            on: { "call-logout-popup": _vm.callLogoutPopup }
          }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("h5", { staticClass: "title" }, [_vm._v("신고 내용 관리")]),
              _c("list-filter", {
                ref: "filter",
                attrs: { listInfo: _vm.listInfo },
                on: {
                  "request-list": _vm.injectionResult,
                  "default-search": _vm.getDefaultSearch
                }
              }),
              _vm.isListVisible
                ? _c("list", {
                    ref: "list",
                    attrs: {
                      columnInfo: _vm.columnInfo,
                      listInfo: _vm.listInfo,
                      response0: _vm.response,
                      listIndex: _vm.listIndex
                    },
                    on: {
                      "delete-declare": _vm.deleteDeclare,
                      "cancel-declare": _vm.cancelDeclare,
                      "list-reversal": _vm.fetchData,
                      "declare-detail": _vm.goDeclareDetail,
                      "list-amount-change": _vm.changedListAmount,
                      "declare-withdraw": _vm.declareWithdraw,
                      "declare-withdraw-content": _vm.declareWithdrawContent
                    }
                  })
                : _vm._e(),
              _c("pagination", {
                ref: "pagination",
                attrs: {
                  paginationInfo: _vm.paginationInfo,
                  response0: _vm.listInfo
                },
                on: { "btn-click": _vm.paginationClick }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.checkPopup
        ? _c("default-popup", {
            attrs: { popupSet: _vm.popupSet },
            on: { "result-check": _vm.resultCheck }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
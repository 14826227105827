import axios from "axios";

const domain = process.env.VUE_APP_API_URL;
const request = {
  get(path, header) {
    const currentUrl = document.location.href;
    console.log("get Api Current URL", currentUrl);
    console.log("get Path", path);
    return axios
      .get(`${domain + path}`, header)
      .catch(error => {
        console.log("domain", domain);
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "UNAUTHORIZED") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("storeId");
            // const url = "samkim://logout";
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[sessionStorage] 1.get : ", sessionStorage);

        // 안아주기 관리자 로그아웃됐지만 페이지에 남아있는 부분 해결위한 if문
        if (sessionStorage.length <= 0) {
          window.app.$children[0].$children[0].$children[0].callInUnAuth();
          // const url = '/login';
          // document.location.href = url;
        }
      });
  },
  patch(path, data, header) {
    const currentUrl = document.location.href;
    return axios
      .patch(`${domain + path}`, data, header)
      .catch(error => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[sessionStorage] 2.patch : ", sessionStorage);
      });
  },
  post(path, data, header) {
    const currentUrl = document.location.href;
    return axios
      .post(`${domain + path}`, data, header)
      .catch(error => {
        console.log("찐에러", error);
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[sessionStorage] 3.post : ", sessionStorage);
      });
  },
  delete(path, header) {
    const currentUrl = document.location.href;
    console.log("delete Api Current URL", currentUrl);
    console.log("delete Path", path);
    return axios
      .delete(`${domain + path}`, header)
      .catch(error => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[localStorage] 4.delete : ", localStorage);
      });
  },
  put(path, data, header) {
    const currentUrl = document.location.href;
    console.log("put Api Current URL", currentUrl);
    console.log("put Path", path);
    return axios
      .put(`${domain + path}`, data, header)
      .catch(error => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우identitySuccess
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[sessionStorage] 5.put : ", sessionStorage);
      });
  }
};

export const auth = {};
export const activity = {
  logOut(userInfo) {
    return request
      .post("/account/logout", userInfo, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  }
};
// 안아주기 로그인
export const util = {
  fetchLogin(userData) {
    return request.post("/login", userData).then(({ data }) => data);
  },
  Logout(apiParam) {
    return request
      .post("/admin_account/logout", apiParam, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(data => data);
  },
  /** ##################### */
  fetchNoticeList(apiParam) {
    return request
      .get(`/setting/notice/list/${apiParam}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  fetchNotice(apiParam) {
    return request
      .get(`/setting/notice/${apiParam}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  insertNotice(apiParam) {
    return request
      .post("/setting/notice/", apiParam, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        },
        contentType: "multipart/form-data"
      })
      .then(({ data }) => data);
  },
  updateNotice(apiParam) {
    return request
      .put("/setting/notice/", apiParam, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        },
        contentType: "multipart/form-data"
      })
      .then(({ data }) => data);
  },
  deleteNotice(apiParam) {
    return request
      .delete(`/setting/notice/${apiParam}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  fetchAppVersion() {
    return request
      .get("/setting/app", {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  insertAppVersion(apiParam) {
    return request
      .post("/setting/app", apiParam, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  updateAppVersion(apiParam) {
    return request
      .put("/setting/app", apiParam, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  sendPush(apiParam) {
    return request
      .post("/push", apiParam, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  }
};
export const member = {
  // 안아주기
  fetchAccountList(option) {
    let requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    const firstCondition =
      option.ageFirst === null || option.ageFirst === "" || option.ageFirst === undefined;
    const lastCondition =
      option.ageLast === null || option.ageLast === "" || option.ageLast === undefined;
    const aCondition = option.q === null || option.q === "" || option.q === undefined;
    if (firstCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && aCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (firstCondition && aCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition && aCondition) {
      requestUrl = `/user/list?start=${option.start}&size=${option.size}&gender=${option.gender}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,

          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  AccountListExcel(option) {
    let requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    const firstCondition =
      option.ageFirst === null || option.ageFirst === "" || option.ageFirst === undefined;
    const lastCondition =
      option.ageLast === null || option.ageLast === "" || option.ageLast === undefined;
    const aCondition = option.q === null || option.q === "" || option.q === undefined;
    if (firstCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && aCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (firstCondition && aCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition && aCondition) {
      requestUrl = `/user/list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        responseType: "blob"
      })
      .then(({ data }) => data);
  },
  fetchAccountDetail(uid) {
    return request
      .get(`/user/detail?${uid}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  withdrawList(option) {
    let requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    const firstCondition =
      option.ageFirst === null || option.ageFirst === "" || option.ageFirst === undefined;
    const lastCondition =
      option.ageLast === null || option.ageLast === "" || option.ageLast === undefined;
    const aCondition = option.q === null || option.q === "" || option.q === undefined;
    if (firstCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && aCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (firstCondition && aCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition && aCondition) {
      requestUrl = `/user/withdraw_list?start=${option.start}&size=${option.size}&gender=${option.gender}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  withdrawExcel(option) {
    let requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    const firstCondition =
      option.ageFirst === null || option.ageFirst === "" || option.ageFirst === undefined;
    const lastCondition =
      option.ageLast === null || option.ageLast === "" || option.ageLast === undefined;
    const aCondition = option.q === null || option.q === "" || option.q === undefined;
    if (firstCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && aCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_max=${option.ageLast}&sort=${option.sort}&order=${option.order}`;
    }
    if (firstCondition && aCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&age_min=${option.ageFirst}&sort=${option.sort}&order=${option.order}`;
    }
    if (lastCondition && firstCondition && aCondition) {
      requestUrl = `/user/withdraw_list_excel?start=${option.start}&size=${option.size}&gender=${option.gender}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        responseType: "blob"
      })
      .then(({ data }) => data);
  },
  deleteAccount(uid) {
    return request
      .delete("/user/withdraw", {
        data: {
          uid
        },
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  /** ####################### */
  // 안아주기
  fetchManageList(option) {
    let requestUrl = `/admin/list?start=${option.start}&size=${option.size}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    if (option.q === null || option.q === "" || option.q === undefined) {
      requestUrl = `/admin/list?start=${option.start}&size=${option.size}&sort=${option.sort}&order=${option.order}`;
    } else {
      requestUrl = `/admin/list?start=${option.start}&size=${option.size}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },

  createManage(adminInfo) {
    return request
      .post("/admin/create", adminInfo, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  deleteManage(aid) {
    return request
      .delete("/admin/delete", {
        data: {
          aid
        },
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  editManage(data) {
    return request
      .put("/admin/modify", data, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  fetchDoctorList(option) {
    let requestUrl = `/doctor/list?approval=${option.approval}&start=${option.start}&page=${option.page}&q=${option.q}`;
    if (option.q === null || option.q === "" || option.q === undefined) {
      requestUrl = `/doctor/list?approval=${option.approval}&start=${option.start}&page=${option.page}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  createDoctor(adminInfo) {
    return request
      .post("/doctor/write", adminInfo, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  deleteDoctor(did) {
    return request
      .delete("/doctor/delete", {
        data: {
          did
        },
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  editDoctor(data) {
    return request
      .put("/doctor/modify", data, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  noticeList(option) {
    let requestUrl = `/notice/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&enddate=${option.enddate}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    const startdate =
      option.startdate === null || option.startdate === "" || option.startdate === undefined;
    const enddate =
      option.enddate === null || option.enddate === "" || option.enddate === undefined;
    const aCondition = option.q === null || option.q === "" || option.q === undefined;
    if (startdate) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&enddate=${option.enddate}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (enddate) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&enddate=${option.enddate}&sort=${option.sort}&order=${option.order}`;
    }
    if (startdate && enddate) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    }
    if (startdate && aCondition) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&enddate=${option.enddate}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition && enddate) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&sort=${option.sort}&order=${option.order}`;
    }
    if (startdate && enddate && aCondition) {
      requestUrl = `/notice/list?start=${option.start}&size=${option.size}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,

          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  createNotice(payload) {
    return request
      .post("/notice/write", payload, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  uploadNoticeFile(attachment) {
    return request
      .post("/notice/upload", attachment, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        },
        contentType: "multipart/form-data"
      })
      .then(({ data }) => data);
  },
  editNotice(data) {
    return request
      .put("/notice/modify", data, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  noticeDetail(option) {
    return request
      .get(`/notice/detail?nid=${option.nid}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  noticeDelete(nid) {
    return request
      .delete("/notice/delete", {
        data: {
          nid
        },
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  declareList(option) {
    let requestUrl = `/board/list?start=${option.start}&size=${option.size}&type=${option.type}&status=${option.status}&qtype=${option.qtype}&q=${option.q}&sort=${option.sort}&order=${option.order}`;
    if (option.q === null || option.q === "" || option.q === undefined) {
      requestUrl = `/board/list?start=${option.start}&size=${option.size}&type=${option.type}&status=${option.status}&qtype=${option.qtype}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  declareDetail(option) {
    return request
      .get(`/board/detail?type=${option.type}&id=${option.id}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  declareCancel(bdid) {
    return request
      .delete(`/board/cancel?bdid=${bdid}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  declareDelete(bdid) {
    return request
      .delete(`/board/delete?bdid=${bdid}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  getPhotos(name) {
    return request
      .get(`/photos/${name}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        },
        responseType: "blob"
      })
      .then(({ data }) => data);
  },
  qnaList(option) {
    let requestUrl = `/qna/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&enddate=${option.enddate}&q=${option.q}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    const startdate =
      option.startdate === null || option.startdate === "" || option.startdate === undefined;
    const enddate =
      option.enddate === null || option.enddate === "" || option.enddate === undefined;
    const aCondition = option.q === null || option.q === "" || option.q === undefined;
    if (startdate) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&enddate=${option.enddate}&q=${option.q}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    if (enddate) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&q=${option.q}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&enddate=${option.enddate}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    if (startdate && enddate) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&q=${option.q}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    if (startdate && aCondition) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&enddate=${option.enddate}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    if (aCondition && enddate) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&startdate=${option.startdate}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    if (startdate && enddate && aCondition) {
      requestUrl = `/qna/list?start=${option.start}&size=${option.size}&status=${option.status}&sort=${option.sort}&order=${option.order}`;
    }
    return request
      .get(requestUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,

          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  qnaUpdate(option) {
    return request
      .put("/qna/update", option, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  qnaUpdate(option) {
    return request
      .put("/qna/update", option, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },

  qnaUpdate2(option) {
    return request
      .put("/qna/update2", option, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  versionCheck(device) {
    return request
      .get(`/version/check?device=${device}`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  },
  versionWrite(option) {
    return request
      .post("/version/write", option, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,

          "Content-Type": "application/json"
        },
        contentType: "application/json"
      })
      .then(({ data }) => data);
  },
  /** ########################## */
  deleteManageDetail(apiParam) {
    return request
      .delete(`/admin_account/admin_detail/${apiParam}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      })
      .then(({ data }) => data);
  }
};

<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">신고 내용 관리</h5>
        <list-filter
          @request-list="injectionResult"
          @default-search="getDefaultSearch"
          :listInfo="listInfo"
          ref="filter"
        ></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          :response0="response"
          :listIndex="listIndex"
          @delete-declare="deleteDeclare"
          @cancel-declare="cancelDeclare"
          @list-reversal="fetchData"
          @declare-detail="goDeclareDetail"
          @list-amount-change="changedListAmount"
          @declare-withdraw="declareWithdraw"
          @declare-withdraw-content="declareWithdrawContent"
          v-if="isListVisible"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          :response0="listInfo"
          ref="pagination"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @result-check="resultCheck"/>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import ListFilter from '@/components/ListFilter.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 30,
        next: null,
        first: null,
        last: null,
        list: null,
        // 추가
        active: [true],
        currentPage: 0,
        block: 5,
      },
      deep: 5,
      tree: {
        title: '게시판 관리',
        detail: '신고 내용 관리',
      },
      option: {
        start: 0,
        size: 30,
        type: 0,
        status: 0,
      },
      columnInfo: [
        {
          word: '번호',
        },
        {
          word: '유형',
        },
        {
          word: '신고 당한 회원',
        },
        {
          word: '신고 내용',
        },
        {
          word: '신고한 회원',
        },
        {
          word: '신고 일시',
        },
        {
          word: '신고한 횟수',
        },
        {
          word: '글 상태',
        },
        {
          word: '기능',
        },
      ],
      response: {
        List: [], count: '',
      },
      // 앞에 번호를 위한 데이터값
      listIndex: { value: 0, flag: true },
      listInfo: {
        type: 5,
        data: [],
        isHideAmount: true,
      },
      isListVisible: true,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions('member', ['DECLARE_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    /** 신고하기 글의 상태 메서드 */
    setDeclareActive() {
      const response = this.response.List;
      for (let i = 0; i < response.length; i++) {
        if (response[i].active === 1) {
          this.response.List[i].textActive = '게시';
        } else if (response[i].active === 0) {
          this.response.List[i].textActive = '비활성';
        } else if (response[i].active === -1) {
          this.response.List[i].textActive = '삭제';
        }
        if (response[i].target === 1) {
          this.response.List[i].target = '게시물';
        } else if (response[i].target === 2) {
          this.response.List[i].target = '댓글';
        }
        if (response[i].target === 3) {
          this.response.List[i].target = '답글';
        }
      }
    },
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    declareWithdraw() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '탈퇴한 회원입니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = undefined;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    declareWithdrawContent(type) {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = `삭제된 ${type}입니다.`;
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = undefined;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    // 한 번더 확인해주는 팝업
    async resultCheck(comments) {
      if (comments === '삭제되었습니다' || comments === '취소되었습니다') {
        await this.SET_POPUP(false);
      }
      this.SET_POPUP(true);
      this.popupSet.popType = 'resultCheck';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = comments;
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = undefined;
      this.popupSet.confirmBtnText = '확인';
    },
    cancelDeclare(bdid) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 'cancel_declare';
      this.popupSet.popType = 'anajugiDel';
      this.popupSet.deleteBid = bdid;
      this.popupSet.content = '신고를 취소하시겠습니까?';
      this.popupSet.attention = '*회원 정보의 ‘신고한 횟수’는 유지됩니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.destination = true;
      this.popupSet.confirmBtnText = '확인';
    },
    deleteDeclare(bdid) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 'delete_declare';
      this.popupSet.popType = 'anajugiDel';
      this.popupSet.deleteBid = bdid;
      this.popupSet.content = '삭제하시겠습니까?';
      this.popupSet.attention = '*회원 정보의 ‘신고 당한 횟수’와 ‘신고한 횟수’는 유지됩니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.destination = true;
      this.popupSet.confirmBtnText = '삭제';
    },
    goDeclareDetail(id, type) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 5;
      this.popupSet.popType = 'declareDetail';
      this.popupSet.huge = true;
      this.popupSet.declareId = { id, type };
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.destination = true;
    },
    async getDefaultSearch(option) {
      this.option = option;
      const data = await this.DECLARE_LIST(option);
      this.response = data.response;
      this.setDeclareActive();
      this.listInfo.data = this.response.List;
      this.paginationInfo.currentPage = 0;
      this.paginationInfo.startPage = 0;
      // 페이징 을 위한
      const { count } = this.response;

      this.initPagination(this.paginationInfo.currentPage, count);
      return this.response;
    },

    // 페이지에 나오는 리스트 의 데이터 갯수 메서드
    changedListAmount(listAmountVal) {
      this.option.size = listAmountVal;
      this.paginationInfo.dataOnPage = this.option.size;
      this.option.start = 0; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0;
      this.listIndex.value = this.option.start;

      this.$refs.filter.changeListAmount(listAmountVal);
      this.initPagination(this.paginationInfo.currentPage);
    },
    async fetchData() {
      const { option } = this.option;
      try {
        await this.DECLARE_LIST(option).then((data) => {
          this.response = data.response;
          this.setDeclareActive();
          this.listInfo.data = data.response.List;
          this.listInfo.totalDataCnt = data.response.count;
          this.response.List = data.response.List;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    async injectionResult(option, search) {
      /**
       * 리스트 다시 그리는 요청을 받았을 때 실행
       * (필터 설정 후 검색, 페이지네이션 클릭, 리스트 수 셀렉트박스 변동)
       * */
      if (search === 0) { this.option.start = 0; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0; }
      const option0 = option;
      this.option = option;
      const data = await this.DECLARE_LIST(option0);
      this.response = data.response;
      this.setDeclareActive();
      this.listIndex.value = this.option.start;

      this.paginationInfo.totalDataCnt = this.response.count;
      this.initPagination(this.paginationInfo.currentPage, this.paginationInfo.totalDataCnt);
    },
    getAddClass(check) {
      /** 페이지네이션 번호 클릭 */
      const activeArray = [];
      for (let j = 0; j < Math.ceil(this.paginationInfo.totalDataCnt / this.paginationInfo.dataOnPage); j++) {
        activeArray.push(false);
      }
      activeArray[check] = true;
      this.paginationInfo.active = activeArray;
    },
    paginationClick(start) {
      this.getAddClass(start);
      const start0 = start * this.paginationInfo.dataOnPage;
      this.option.start = start0;
      this.paginationInfo.currentPage = start;

      this.initPagination(start);
      this.DECLARE_LIST(this.option).then((data) => {
        this.listInfo.data = data.response.List;
        this.listInfo.totalDataCnt = data.response.count;
        this.response.List = data.response.List;
        this.listIndex.value = this.option.start;
      });
    },

    /** 페이징 처리 */
    initPagination(page, count) {
      if (count !== undefined) { this.paginationInfo.totalDataCnt = count; }
      this.pageDataSetting(this.paginationInfo.totalDataCnt, this.paginationInfo.dataOnPage, this.paginationInfo.block, page);
    },
    pageDataSetting(total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);

      const currentPage = page;

      const startIndex = currentPage % 4 === 0 ? currentPage : currentPage - (currentPage % 4);
      const endIndex = startIndex + 3 >= totalPage ? totalPage - 1 : startIndex + 3;

      this.paginationInfo.startIndex = startIndex;
      this.paginationInfo.endIndex = endIndex;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        if (index >= 0) {
          list.push(index);
        }
      }

      this.paginationInfo.first = totalPage > 1 && currentPage >= 0 ? true : null;
      this.paginationInfo.end = totalPage > 1 && currentPage + 1 < totalPage ? true : null;
      this.paginationInfo.firstMore = totalPage > 3 && currentPage >= 0 ? true : null;
      this.paginationInfo.endMore = totalPage > 4 && startIndex + 3 < totalPage ? true : null;
      this.paginationInfo.list = list;
      return {
        list, currentPage,
      };
    },

  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
    ListFilter,
    Pagination,
  },
};
</script>
<style lang="scss" scoped></style>

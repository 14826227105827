<template>
  <div class="calendar-flex">
    <div class="calendar-wrap">
      <div class="datepicker-trigger">
        <input
          type="text"
          id="datepicker-trigger"
          placeholder="Select dates"
          :value="formatDates(calendar.dateOne)"
        />

        <AirbnbStyleDatepicker
          :trigger-element-id="'datepicker-trigger'"
          :mode="'single'"
          :date-one="calendar.dateOne"
          :monthsToShow="1"
          :inline="true"
          :enabled-dates="calendar.enabled"
          :disabled-dates="calendar.dimm"
          :widthSet="calendar.width"
          :showShortcutsMenuTrigger="false"
          @date-one-selected="
            val => {
              calendar.dateOne = val;
              this.selectDate(val);
            }
          "
          @next-month="
            val => {
              this.getMonthInfo(val, 'next');
            }
          "
          @previous-month="
            val => {
              this.getMonthInfo(val, 'prev');
            }
          ">
          <template slot="previous-month-icon">
            <img src="@assets/images/prevIcon.svg" />
          </template>
          <template slot="next-month-icon">
            <img src="@assets/images/nextIcon.svg" />
          </template>
        </AirbnbStyleDatepicker>
      </div>
      <div class="notification" v-if="notificationType === 0">
        <div class="item implement">
          <p v-text="!isTimeTable ? '실시' : '예약가능'" v-if="false"></p>
        </div>
        <div class="item noneImplement">
          <p v-text="!isTimeTable ? '미실시' : '예약불가능'" v-if="false"></p>
        </div>
        <div class="item choice">
          <p>선택</p>
        </div>
      </div>
      <div class="notification" v-if="notificationType === 1">
        <div class="item implement">
          <p v-text="!isTimeTable ? '실시' : '예약가능'"></p>
        </div>
        <div class="item noneImplement">
          <p v-text="!isTimeTable ? '미실시' : '예약불가능'"></p>
        </div>
        <div class="item choice">
          <p>선택</p>
        </div>
      </div>
    </div>
    <div class="time-status-wrap" v-if="isTimeTable">
      <div class="time-status" v-if="calendar.medicalTime.length > 0">
        <h3 class="date" v-text="dateTitle(calendar.dateOne)"></h3>
        <div class="time-table-wrap">
          <h5 class="title">예약가능한 시간</h5>
          <ul class="time-table">
            <!-- 오전 오후 색깔 처리 -->
            <li
              :class="{
                inActivated: !timeItem.available,
                afternoon:
                  Number(
                    timeItem.time
                      .trim()
                      .split('~')[0]
                      .split(':')[0]
                  ) >= 13,
                morning:
                  Number(
                    timeItem.time
                      .trim()
                      .split('~')[0]
                      .split(':')[0]
                  ) < 13
              }"
              v-for="(timeItem, index) in calendar.medicalTime"
              :key="index + 'A'"
              v-text="transTime(timeItem.time, timeItem.available)"
              @click="timePick(index)"
            />
          </ul>
        </div>
        <div class="notification">
          <div class="item afternoon">
            <p>오후</p>
          </div>
          <div class="item morning">
            <p>오전</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format';
import { mapActions } from 'vuex';

export default {
  props: {
    notificationType: {
      type: Number,
      default: 0,
    },
    isTimeTable: {
      type: Boolean,
      default: false,
    },
    rangeOrder: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      calendar: {
        dateFormat: 'D MMM',
        dateOne: '',
        today: '',
        nextMonth: '',
        previousMonth: '',
        currentYear: '',
        currentMonth: '',
        currentDateSet: [],
        enabled: [],
        dimm: [],
        medicalTime: [],
        width: '',
      },
    };
  },
  created() {
    this.calendarVisualize();
    this.calendarDataSet(null, null, 'current');
    this.getCalendarList(this.calendar.currentYear, this.calendar.currentMonth);
  },
  methods: {
    ...mapActions('reservation', ['FETCH_CONSULTATION_AVAILABLE_LIST']),
    getDayInfo(date) {
      const week = [];
      week.push('일', '월', '화', '수', '목', '금', '토');
      const day = new Date(date);
      const dayName = day.getDay();
      return week[dayName];
    },
    timePick(index) {
      let concatData;
      let calendarDate;
      if (document.querySelector('.time-table').childNodes[index].classList.contains('selected')) {
        document.querySelector('.time-table').childNodes[index].classList.remove('selected');
        this.$emit('selected-time-data', null);
      } else {
        for (let i = 0; i < document.querySelector('.time-table').childElementCount; i++) {
          if (document.querySelector('.time-table').childNodes[i].classList.contains('selected')) {
            document.querySelector('.time-table').childNodes[i].classList.remove('selected');
          }
        }
        document.querySelector('.time-table').childNodes[index].classList.add('selected');
        concatData = `${this.calendar.dateOne
        }T${
          this.calendar.medicalTime[index].time.split('~')[0].trim()
        }:00`;
        calendarDate = {
          concatTime: concatData,
          date: this.calendar.dateOne,
          time: this.calendar.medicalTime[index].time.split('~')[0].trim(),
          dayName: this.getDayInfo(this.calendar.dateOne),
        };
        this.$emit('selected-time-data', calendarDate);
      }
    },
    getTimeTable(day, month, year) {
      /** 타임 테이블 불러오는 api */
      console.log('타임테이블 day :', day, 'month :', month, 'year :', year);
      this.FETCH_CONSULTATION_AVAILABLE_LIST(`?type=time&year=${year}&month=${month}&day=${day}`).then((data) => {
        console.log('상담시간 목록', data.response.list);
        const timeList = [];
        // { time: ?, timeUnit: ?, available: ? }^
        for (let i = 0; i < data.response.list.length; i++) {
          const item = data.response.list[i];

          // TODO : 시간 음영처리 하고싶은데.. 방법을 몰라서 예약 불가 시간은 화면에 보여주지 않음 available = False => 선택 불가
          if (!item.available) { continue; }

          timeList.push({
            time: item.time,
            timeUnit: 30,
            available: item.available,
          });
        }

        this.calendar.medicalTime = timeList;
      });
    },
    getCalendarList(year, month) {
      if (this.notificationType !== 1) { return; }

      /** 예약날짜를 불러오는 api */
      this.FETCH_CONSULTATION_AVAILABLE_LIST(`?type=date&year=${year}&month=${month}`).then((data) => {
        const dateList = [];
        for (let i = 0; i < data.response.list.length; i++) {
          const item = data.response.list[i];

          try {
            if (item.available === true) {
              const date = new Date(item.date);
              let currentDate = new Date();
              currentDate = new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`);

              if (date >= currentDate) {
                dateList.push(item.date);
              }
            }
          } catch { }
        }
        // TODO : 날짜도 음영처리 하고싶은데.. 방법을 모르겠음
        this.calendar.enabled = dateList;
        this.dimmMonthDate();
      });
    },
    calendarVisualize() {
      if (this.isTimeTable) {
        this.calendar.width = 420;
      } else {
        this.calendar.width = 420;
      }
    },
    selectDate(date) {
      const transVal = date.split('-');
      this.$emit('selected-date-val', date, this.rangeOrder);
      if (this.isTimeTable) {
        this.getTimeTable(transVal[2], transVal[1], transVal[0]);
      }
    },
    formatDates(dateOne) {
      let formattedDates = '';
      if (dateOne) {
        formattedDates = format(dateOne, this.calendar.dateFormat);
      }
      return formattedDates;
    },
    calendarDataSet(yearVal, monthVal, direction) {
      const date = new Date();
      let year;
      let month;
      let nextMonth;
      let previousMonth;
      let nowYear;
      if (yearVal === null) {
        year = date.getFullYear();
      } else {
        year = yearVal;
      }
      nowYear = year;
      if (monthVal === null) {
        month = date.getMonth() + 1;
        if (date.getMonth() === 0) {
          previousMonth = 12;
          nextMonth = date.getMonth() + 2;
        } else if (date.getMonth() > 10) {
          previousMonth = date.getMonth();
          nextMonth = 1;
        } else {
          previousMonth = date.getMonth();
          nextMonth = date.getMonth() + 2;
        }
      } else {
        console.log('함께', monthVal);
        month = monthVal;
        if (monthVal === 1) {
          previousMonth = 12;
          nextMonth = monthVal + 1;
        } else if (monthVal > 11) {
          previousMonth = monthVal - 1;
          nextMonth = 1;
        } else {
          previousMonth = monthVal - 1;
          nextMonth = monthVal + 1;
        }
      }
      const day = date.getDate();
      const wholeDateArray = [];
      const currentFullDaysLength = new Date(year, month, 0).getDate();
      let monthValue;
      let nextMonthValue;
      let transDate;
      let today;
      let nowMonth;
      if (String(month).length < 2) {
        monthValue = `${String(year)}-0${String(month)}`;
        if (month !== 9) {
          nextMonthValue = `${String(year)}-0${String(nextMonth)}`;
        } else {
          nextMonthValue = `${String(year)}-${String(nextMonth)}`;
        }
      } else {
        monthValue = `${String(year)}-${String(month)}`;
        if (month !== 12) {
          nextMonthValue = `${String(year)}-${String(nextMonth)}`;
        } else {
          nextMonthValue = `${String(year + 1)}-0${String(1)}`;
        }
      }
      const material = `${monthValue}-`;
      const material2 = `${monthValue}-0`;
      for (let i = 1; i < currentFullDaysLength + 1; i++) {
        if (i < 10) {
          transDate = material2 + i;
        } else {
          transDate = material + i;
        }
        nowMonth = month;
        wholeDateArray.push(transDate);
      }
      this.calendar.currentYear = year;
      this.calendar.currentMonth = month;
      if (this.calendar.nowMonth === undefined) {
        this.calendar.nowMonth = nowMonth;
      }
      if (this.calendar.nowYear === undefined) {
        this.calendar.nowYear = nowYear;
      }
      this.calendar.nextMonth = nextMonth;
      this.calendar.previousMonth = previousMonth;
      this.calendar.currentDateSet = wholeDateArray;
      if (direction === 'current') {
        if (String(day).length < 2) {
          today = String(material2 + day);
        } else {
          today = String(material + day);
        }
        this.calendar.today = today;
      }
    },
    getMonthInfo(val, direction) {
      document.querySelector('.asd__change-month-button--previous').style.display = 'block';
      document.querySelector('.asd__change-month-button--next').style.display = 'block';
      const transVal = String(val).split('-');
      if (direction === 'prev') {
        if (this.calendar.nowMonth <= 3) {
          if (Number(transVal[1]) >= 10) {
            const su = this.calendar.nowMonth + 12; /** 15 */
            if (su - Number(transVal[1]) === this.calendar.nowMonth) {
              document.querySelector('.asd__change-month-button--previous').style.display = 'none';
            }
          }
        } else if (Number(transVal[1]) === this.calendar.nowMonth - 3) {
          document.querySelector('.asd__change-month-button--previous').style.display = 'none';
        }
      } else if (this.calendar.nowMonth >= 10) {
        if (Number(transVal[1]) < 10) {
          const su = Number(transVal[1]) + 12;
          if (su - this.calendar.nowMonth === 3) {
            document.querySelector('.asd__change-month-button--next').style.display = 'none';
          }
        }
      } else if (Number(transVal[1]) === this.calendar.nowMonth + 3) {
        document.querySelector('.asd__change-month-button--next').style.display = 'none';
      }
      this.calendarDataSet(Number(transVal[0]), Number(transVal[1]), direction);
      this.getCalendarList(this.calendar.currentYear, this.calendar.currentMonth);
    },
    dimmMonthDate() {
      console.log('전체 배열 재료', this.calendar.currentDateSet);
      console.log('가능한 일자 배열', this.calendar.enabled);
      const wholeArray = JSON.parse(JSON.stringify(this.calendar.currentDateSet));
      const enabledArray = JSON.parse(JSON.stringify(this.calendar.enabled));
      for (let i = 0; i < wholeArray.length; i++) {
        for (let j = 0; j < enabledArray.length; j++) {
          if (wholeArray[i] === enabledArray[j]) {
            this.removeA(wholeArray, enabledArray[j]);
          }
        }
      }
      this.calendar.dimm = wholeArray;
    },
    // 배열지우기
    removeA(arr) {
      let what;
      const a = arguments;
      let L = a.length;
      let ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
  },
  computed: {
    dateTitle() {
      return (date) => {
        /** 2021-12-30 */
        console.log(date);
        const dateSplit = date.split('-');
        return `${dateSplit[1]}월 ${dateSplit[2]}일 ` + `(${this.getDayInfo(date)})`;
      };
    },
    transTime() {
      return (time, flag) => {
        const mutateTime = time.trim();
        const timeSplit = mutateTime.split('~');
        let result;
        if (flag) {
          result = timeSplit[0];
        } else {
          result = '';
        }
        return result;
      };
    },
  },
  components: {},
};
</script>
<style lang="scss">
.calendar-flex {
  display: flex;
  .calendar-wrap {
    max-width: 480px;
    margin: 0 auto 85px auto;
    position: relative;
  }
  .time-status-wrap {
    width: calc(100% - 480px);
    position: relative;
    .notification {
      position: absolute;
      bottom: 45px;
      right: 30px;
      display: flex;
    }
    .time-status {
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
          p:after {
            background: #e8f7f6;
          }
        }
        &:nth-of-type(2) {
          p:after {
            background: #fff3e6;
          }
        }
        &:nth-of-type(3) {
          p:after {
            background: #315754;
          }
        }
        p {
          position: relative;
          padding: 0 0 0 27.5px;
          @include initfontKor(
            $font-size: 18px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.36px,
            $text-align: left
          );
          &:after {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            position: absolute;
          }
        }
      }
    }
    h3.date {
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      margin: 0 0 28px 0;
    }
    .time-table-wrap {
      width: 260px;
      margin: 0 auto;
      h5.title {
        @include initfontKor(
          $font-size: 22px,
          $line-height: 28px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fd8b11,
          $letter-spacing: -0.44px,
          $text-align: center
        );
        margin: 0 0 10px 0;
      }
      ul.time-table {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-left: 10px;
          width: calc((100% / 3) - 7px);
          @include initfontKor(
            $font-size: 24px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          border-radius: 19px;
          cursor: pointer;
          &:nth-of-type(3n + 1) {
            margin-left: 0;
          }
          &:nth-of-type(n + 4) {
            margin-top: 10px;
          }
          &.afternoon {
            background: rgba(253, 139, 17, 0.1);
          }
          &.morning {
            background-color: rgba(33, 177, 165, 0.1);
          }
          &.inActivated {
            display: none;
          }
          &.selected {
            background: #fe6867;
            color: #fff;
          }
        }
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      height: calc(100% - 40px);
      top: 0;
      width: 1px;
      background: #eaebf1;
    }
  }
}
.calendar-wrap {
  .notification {
    position: absolute;
    bottom: -45px;
    right: 30px;
    display: flex;
    .item {
      margin: 0 0 0 20px;
      &:first-of-type {
        margin: 0;
        p:after {
          background: #fff;
          border: 1px solid #8b8d94;
        }
      }
      &:nth-of-type(2) {
        p:after {
          background: #eaebf1;
        }
      }
      &:nth-of-type(3) {
        p:after {
          background: #315754;
        }
      }
      p {
        position: relative;
        padding: 0 0 0 27.5px;
        @include initfontKor(
          $font-size: 15px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.36px,
          $text-align: left
        );
        &:after {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          position: absolute;
        }
      }
    }
  }
}
.datepicker-trigger {
  margin: 0 0 15px 0;
  display: flex;
  justify-content: center;
  input#datepicker-trigger {
    display: none;
  }
  .asd__wrapper {
    background: #fff;
    border: 0 !important;

    .asd__datepicker-header {
      .asd__days-legend {
        top: 65px;
        padding: 0;
        .asd__day-title {
          @include initfontKor(
            $font-size: 24px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
        }
      }
      .asd__change-month-button {
        display: block;
        background: transparent;

        button {
          border: 0;
          padding: 0;
          color: #fff;
          background: transparent;

          svg {
            fill: #fff;
            width: 30px;
            height: 30px;
          }
        }

        &.asd__change-month-button--next {
          top: -11px;
          padding: 0 28px 0px 0;
          // right: 35px;

          button {
          }

          svg {
            fill: #fff;
            width: 30px;
            height: 30px;
          }
        }

        &.asd__change-month-button--previous {
          top: -11px;
          padding: 0 0px 0px 28px;
          // left: 35px;
          svg {
          }
        }
      }
    }
    .asd__month {
      padding: 0;
      &:not(.asd__month--hidden) {
        .asd__day {
          &.asd__day--enabled {
            &.asd__day--disabled {
              background-color: rgba(234, 235, 241, 0.3) !important;
              button {
                color: #c8cacf;
              }
            }
            &:not(.asd__day--disabled):not(.asd__day--selected) {
              button {
                /**
                background-color: #315754;
                border-radius: 0;
                width: 100%;
                height: 100%;
                color: #fff;
                 */
              }
            }
          }
        }
      }
    }
    .asd__day {
      color: #fff !important;
      border: 1px solid #eaebf1;

      &:hover {
        background: transparent;
      }

      &.asd__day--enabled {
        &:focus {
          outline: none;
        }
        /**
                &:not(.asd__day--disabled):not(.asd__day--selected) {
                    button {
                        background-color: #a15252 !important;
                    }
                }
                 */
      }

      &.asd__day--selected {
        background: transparent !important;

        button {
          background: #315754;
          color: #fff;
        }
      }

      button {
        width: 100%;
        height: 100%;
        border-radius: 0;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 60px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }
    }

    .asd__day--selected {
      color: #fff !important;
    }

    .asd__day--disabled,
    .asd__day--empty {
      opacity: 1;
    }

    .asd__day-title {
      color: #fff;
    }
  }
}
.asd__month {
  .asd__month-table {
    background: transparent;
  }
  .asd__month-name {
    margin: 0px 0 65px 0;
    span {
      @include initfontKor(
        $font-size: 22px,
        $line-height: 42px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #292a2b,
        $letter-spacing: -0.48px,
        $text-align: center
      );
    }
  }
}

/**
@import '@assets/css/datepicker';
*/
</style>

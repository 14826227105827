<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">탈퇴회원</h5>
        <list-filter
          @request-list="injectionResult"
          @default-search="getDefaultSearch"
          :listInfo="listInfo"
          ref="filter"
        ></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          :response0="response"
          :reverse="reverse"
          @list-reversal="reversalResult"
          @list-amount-change="changedListAmount"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          :response0="listInfo"
          ref="pagination"
        ></pagination>
        <div class="excelButton"><p @click="excelPrint()">엑셀로 출력</p></div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import ListFilter from '@/components/ListFilter.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        next: null,
        first: null,
        last: null,
        list: null,
        // 추가
        active: [true],
        currentPage: 0,
        block: 5,
      },
      response: {
        List: [], count: '',
      },
      tree: {
        title: '일반 회원 관리',
        detail: '탈퇴회원',
      },
      listInfo: {
        type: 2,
        data: [],
      },
      columnInfo: [
        {
          word: '가입일',
        },
        {
          word: '아이디',
        },
        {
          word: '닉네임',
        },
        {
          word: '이메일',
        },
        {
          word: '성별',
        },
        {
          word: '생년월일',
        },
        {
          word: '탈퇴일',
        },
        {
          word: '탈퇴사유',
        },
      ],
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
      dateLabel1: '가입일',
      dateLabel2: '최근상담일',
      option: {
        ageFirst: null,
        ageLast: null,
        gender: 0,
        order: 1,
        q: null,
        size: 10,
        sort: 0,
        start: 0,
      },
      reverse: [0, 0, '', '', 0, 0, 0, ''],
    };
  },
  /** API 데이터 로딩주기 */
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions('member', ['WITHDRAW_LIST', 'WITHDRAW_EXCEL']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async excelPrint() {
      const { option } = this.option;
      try {
        await this.WITHDRAW_EXCEL(this.option).then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'test.xlsx');
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) { console.log('에러', e); }
    },
    async getDefaultSearch(option) {
      this.option = option;
      try {
        const data = await this.WITHDRAW_LIST(option);
        this.response = data.response;

        this.listInfo.data = this.response.List;
        // 탈퇴 사유 추출

        for (let i = 0; i < this.response.List.length; i++) {
          const reason = this.response.List[i].reasonContent;
          if (reason !== null) {
            if (reason.indexOf('useless') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('useless', '유용한 정보가 부족해요.'); }
            if (reason.indexOf('difficult') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('difficult', '사용하는데 어려워요.'); }
            if (reason.indexOf('|') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replaceAll('|', ' </br>'); }
          }
        }
        this.paginationInfo.currentPage = 0;
        this.paginationInfo.startPage = 0;
        // 페이징 을 위한
        const { count } = this.response;

        this.initPagination(this.paginationInfo.currentPage, count);
      } catch (e) { console.log('error', e); }
      return this.response;
    },

    // 페이지에 나오는 리스트 의 데이터 갯수 메서드
    changedListAmount(listAmountVal) {
      this.option.size = listAmountVal;
      this.paginationInfo.dataOnPage = this.option.size;
      this.$refs.filter.changeListAmount(listAmountVal);
      this.initPagination(this.paginationInfo.currentPage);
    },
    reversalResult(info) {
      this.option.sort = info;
      if (this.reverse[info] === 0) {
        this.reverse[info] = 1;
      } else {
        this.reverse[info] = 0;
      }
      const order = this.reverse[info];
      const curPage = this.$refs.pagination.paginationStatus.currentPage;
      this.fetchData(info, order);
    },
    // 리스트 추출 메서드
    async fetchData(sort, order) {
      // this.dateLabel2 = '최근상담일';
      this.option.sort = sort;
      this.option.order = order;

      if (sort === false) {
        this.option.sort = 0;
        this.option.order = 1;
      }

      try {
        await this.WITHDRAW_LIST(this.option).then((data) => {
          // if (isInit) {
          this.listInfo.data = data.response.List;
          this.listInfo.totalDataCnt = data.response.count;
          this.response.List = data.response.List;
          // 탈퇴 사유 추출

          for (let i = 0; i < this.response.List.length; i++) {
            const reason = this.response.List[i].reasonContent;
            if (reason !== null) {
              if (reason.indexOf('useless') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('useless', '유용한 정보가 부족해요.'); }
              if (reason.indexOf('difficult') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('difficult', '사용하는데 어려워요.'); }
              if (reason.indexOf('|') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replaceAll('|', ' </br>'); }
            }
          }
          this.isListVisible = true;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    getAddClass(check) {
      /** 페이지네이션 번호 클릭 */
      const activeArray = [];
      for (let j = 0; j < Math.ceil(this.paginationInfo.totalDataCnt / this.paginationInfo.dataOnPage); j++) {
        activeArray.push(false);
      }
      activeArray[check] = true;
      this.paginationInfo.active = activeArray;
    },
    paginationClick(start) {
      this.getAddClass(start);
      const start0 = start * this.paginationInfo.dataOnPage;
      this.option.start = start0;
      this.paginationInfo.currentPage = start;

      this.initPagination(start);
      this.WITHDRAW_LIST(this.option).then((data) => {
        // if (isInit) {
        this.listInfo.data = data.response.List;
        this.listInfo.totalDataCnt = data.response.count;
        this.response.List = data.response.List;
        // 탈퇴 사유 추출

        for (let i = 0; i < this.response.List.length; i++) {
          const reason = this.response.List[i].reasonContent;
          if (reason !== null) {
            if (reason.indexOf('useless') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('useless', '유용한 정보가 부족해요.'); }
            if (reason.indexOf('difficult') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('difficult', '사용하는데 어려워요.'); }
            if (reason.indexOf('|') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replaceAll('|', ' </br>'); }
          }
        }
      });

      // this.paginationInfo.currentPage = start;
    },
    /** 페이징 처리 */
    initPagination(page, count) {
      if (count !== undefined) { this.paginationInfo.totalDataCnt = count; }
      this.pageDataSetting(this.paginationInfo.totalDataCnt, this.paginationInfo.dataOnPage, this.paginationInfo.block, page);
    },
    pageDataSetting(total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);
      const currentPage = page;
      // const first = currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null;
      // const end = totalPage !== currentPage
      //   ? parseInt(currentPage, 10) + parseInt(1, 10)
      //   : null;
      const startIndex = currentPage % 4 === 0 ? currentPage : currentPage - (currentPage % 4);
      const endIndex = startIndex + 3 >= totalPage ? totalPage - 1 : startIndex + 3;

      this.paginationInfo.startIndex = startIndex;
      this.paginationInfo.endIndex = endIndex;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        if (index >= 0) {
          list.push(index);
        }
      }

      this.paginationInfo.first = totalPage > 1 && currentPage >= 0 ? true : null;
      this.paginationInfo.end = totalPage > 1 && currentPage + 1 < totalPage ? true : null;
      this.paginationInfo.firstMore = totalPage > 3 && currentPage >= 0 ? true : null;
      this.paginationInfo.endMore = totalPage > 4 && startIndex + 3 < totalPage ? true : null;
      this.paginationInfo.list = list;
      return {
        list, currentPage,
      };
    },
    async injectionResult(option, search) {
      /**
       * 리스트 다시 그리는 요청을 받았을 때 실행
       * (필터 설정 후 검색, 페이지네이션 클릭, 리스트 수 셀렉트박스 변동)
       * */
      if (search === 0) { this.option.start = 0; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0; }

      const option0 = option;
      this.option = option;
      const data = await this.WITHDRAW_LIST(option0);
      this.response = data.response;
      // 탈퇴 사유 추출

      for (let i = 0; i < this.response.List.length; i++) {
        const reason = this.response.List[i].reasonContent;
        if (reason !== null) {
          if (reason.indexOf('useless') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('useless', '유용한 정보가 부족해요.'); }
          if (reason.indexOf('difficult') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replace('difficult', '사용하는데 어려워요.'); }
          if (reason.indexOf('|') >= 0) { this.response.List[i].reasonContent = this.response.List[i].reasonContent.replaceAll('|', ' </br>'); }
        }
      }
      this.paginationInfo.totalDataCnt = this.response.count;
      this.initPagination(this.paginationInfo.currentPage, this.paginationInfo.totalDataCnt);
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
}
.excelButton{width:100%; height:50px; position:relative;}
.excelButton p{width:100px; height:100%;  text-align:center; line-height:50px;  color:#fff; background-color:#fe6867; position:absolute; right:0; border-radius: 5px;}
</style>

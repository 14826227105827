var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-context" },
    [
      _c("side-menu", { attrs: { deepNavi: _vm.deep } }),
      _c(
        "div",
        { staticClass: "wrap-context" },
        [
          _c("notification", {
            attrs: { tree: _vm.tree },
            on: { "call-logout-popup": _vm.callLogoutPopup }
          }),
          _c("div", { staticClass: "container" }, [
            _c("h5", { staticClass: "title" }, [_vm._v("공지사항 상세")]),
            _c("div", { staticClass: "notice" }, [
              _c("div", { staticClass: "column-info" }, [
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("제목")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "input-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.title.value,
                            expression: "form.title.value"
                          }
                        ],
                        attrs: {
                          type: "text",
                          readonly: _vm.readonlyFlag === false
                        },
                        domProps: { value: _vm.form.title.value },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form.title,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _c("div", { staticClass: "article" }, [
                  _c("h6", { staticClass: "header" }, [_vm._v("작성일")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "input-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.writedDate.value,
                            expression: "form.writedDate.value"
                          }
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.form.writedDate.value },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form.writedDate,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "content-wrap" },
                [
                  _vm.editButtonFlag !== false
                    ? _c("editor", {
                        attrs: {
                          editorData: _vm.form.content.value,
                          readonly: "",
                          afterEditorData: _vm.form.content.value
                        },
                        on: { sendEditContent: _vm.synchronization }
                      })
                    : _c("div", {
                        staticClass: "content",
                        domProps: { innerHTML: _vm._s(_vm.form.content.value) }
                      })
                ],
                1
              ),
              _vm.form.attachment.filename || _vm.editButtonFlag !== "read"
                ? _c("div", { staticClass: "attachment-wrap" }, [
                    _c("div", { staticClass: "attachment viewer" }, [
                      _c("div", { staticClass: "a-input-wrap" }, [
                        _c("span", {
                          staticClass: "file-name",
                          domProps: {
                            textContent: _vm._s(_vm.form.attachment.filename)
                          }
                        }),
                        _c("input", {
                          ref: "inputFile",
                          staticClass: "hidden file-upload",
                          attrs: { type: "file", readonly: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            },
                            change: function($event) {
                              return _vm.onFileChange($event)
                            }
                          }
                        })
                      ]),
                      _vm.editButtonFlag !== "read"
                        ? _c("div", { staticClass: "a-btn-wrap" }, [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.attachmentFile()
                                  }
                                }
                              },
                              [_vm._v(" 파일첨부 ")]
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "cancelUploadFile" }, [
                        _vm.editButtonFlag !== "read"
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.cancelUploadFile()
                                  }
                                }
                              },
                              [_vm._v("X")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "bottom-btn-wrap" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [_vm._v("이전으로")]
                ),
                _vm.editButtonFlag === "edit"
                  ? _c(
                      "button",
                      {
                        staticClass: "confirm",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.submitNotice()
                          }
                        }
                      },
                      [_vm._v(" 수정하기 ")]
                    )
                  : _vm._e(),
                _vm.editButtonFlag === "register"
                  ? _c(
                      "button",
                      {
                        staticClass: "confirm",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.submitNotice()
                          }
                        }
                      },
                      [_vm._v(" 등록하기 ")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "checkboxWrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formInner.important,
                        expression: "formInner.important"
                      }
                    ],
                    ref: "input",
                    staticClass: "topCheckbox",
                    attrs: { type: "checkbox", disabled: _vm.disabledFlag },
                    domProps: {
                      checked: _vm.formInner.important,
                      checked: Array.isArray(_vm.formInner.important)
                        ? _vm._i(_vm.formInner.important, null) > -1
                        : _vm.formInner.important
                    },
                    on: {
                      click: function($event) {
                        return _vm.importancyFlag()
                      },
                      change: function($event) {
                        var $$a = _vm.formInner.important,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.formInner,
                                "important",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.formInner,
                                "important",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.formInner, "important", $$c)
                        }
                      }
                    }
                  }),
                  _c("span", { staticClass: "checkboxText" }, [
                    _vm._v("TOP 공지 설정")
                  ])
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm.checkPopup
        ? _c("default-popup", {
            attrs: { popupSet: _vm.popupSet },
            on: { "api-called": _vm.deleteNotice }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
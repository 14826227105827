var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    attrs: { wide: true },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "search-pop frame" }, [
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: require("@assets/images/util/close.svg") }
                  })
                ]
              ),
              _c("div", { staticClass: "search" }, [
                _c("h5", { staticClass: "title" }, [_vm._v("회원검색")]),
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.value,
                          expression: "search.value"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.search.value },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.search, "value", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "btn-wrap" }, [
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.search()
                          }
                        }
                      },
                      [_vm._v("검색")]
                    )
                  ])
                ]),
                _vm.listInfo.data.length > 0
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _vm.isListVisible
                          ? _c("list", {
                              ref: "list",
                              attrs: {
                                columnInfo: _vm.columnInfo,
                                listInfo: _vm.listInfo
                              },
                              on: { "member-selected": _vm.memberToParent }
                            })
                          : _vm._e(),
                        _c("br"),
                        _vm.isPaginationVisible
                          ? _c("pagination", {
                              ref: "pagination",
                              attrs: { paginationInfo: _vm.paginationInfo },
                              on: { "btn-click": _vm.paginationClick }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
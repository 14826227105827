import _ from 'lodash'

export default {
  parse (error) {
    if (error.response) {
      const { status } = error.response
      const { data } = error.response
      if (status === 400) {
        if (data && data.message) {
          return new Error(data.message)
        }
        return new Error('잘못된 요청입니다.')
      } if (status === 401) {
        return new Error('사용 권한 없음: 액세스가 거부되었습니다.')
      } if (status === 403) {
        return new Error('잘못된 접근입니다.')
      } if (status === 404) {
        return new Error('요청하신 페이지를 찾을 수 없습니다.')
      } if (status === 500) {
        if (data && data.message) {
          return new Error(`${data.message} 잠시 후 다시 시도해 주세요.`)
        }
        return new Error('서버 통신에 오류가 있습니다. 잠시 후 다시 시도해 주세요.')
      }
      return new Error('요청이 실패하였습니다. 잠시 후 다시 시도해 주세요.')
    } if (error.request) {
      // Request was made and no response
      return new Error('요청이 실패하였습니다.')
    }
    return _.isError(error) ? error : new Error(error)
  }
}

<template>
  <transition name="modal">
    <div class="modal-mask" @click="overlayClick($event)" :class="{ huge: huge, medium: medium }">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    destination: {
      type: Boolean,
      default: false,
    },
    isFixing: {
      type: Boolean,
      default: false,
    },
    huge: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    overlayRestrict: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    overlayClick(event) {
      const target = event;
      if (this.destination) {
        this.$emit('forced-nextlink');
      }
      if (!this.overlayRestrict) {
        this.SET_POPUP(false);
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  transition: opacity 0.3s ease;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.huge {
    .modal-wrapper {
      .modal-container {
        width: 940px;
        box-shadow: 0 3px 30px 0 rgba(44, 57, 55, 0.3);
        border-radius: 15px;
        overflow: visible;
      }
    }
  }
  &.medium {
    .modal-wrapper {
      .modal-container {
        width: 760px;
        box-shadow: 0 3px 30px 0 rgba(44, 57, 55, 0.3);
        border-radius: 15px;
        overflow: visible;
      }
    }
  }
}

.modal-wrapper {
  &.transparent {
    background: transparent;

    .modal-container {
      background: transparent;
      backdrop-filter: none;
      height: 0;
      width: 0;
    }
  }
  .modal-container {
    border-radius: 0;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #fff5de;
    overflow:hidden;
    border-radius: 15px;
    border: solid 3px #fe6867;
    border: 0;
    min-height: 163px;
    margin: 0px auto;
    background: #fff;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 0px 0;
    cursor: default;
    .modal-header {
      display: flex;
      width: 100%;
      position: relative;
      h3 {
        margin-top: 0;
        color: #42b983;
      }
    }
    .modal-flex {
      display: flex;
      height: calc(100% - 52px);
      flex-wrap: wrap;
    }
    .modal-body {
      padding: 0px;
      width: 100%;
      h3 {
        font-family: "NotoSansCJKkr";
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.01px;
        text-align: center;
        color: #000000;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.45;
        color: #707070;
        padding-bottom: 5px;
      }
      span {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0px;
        text-align: center;
        color: #000000;
      }
      p {
        font-family: "NotoSansCJKkr";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0px;
        text-align: center;
        color: #000000;
        white-space: break-spaces;
        &.red {
          color: #146f83;
        }
      }
    }
    .modal-footer {
      text-decoration: none;
      color: #999;
      position: relative;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 12px 10px;
      &.hide {
        display: none;
      }
      .modal_btnWrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &.hide {
          display: none;
        }
        button {
          border: 0;
          color: #fe6867;
          height: 52px;
          font-weight: 500;
          font-family: "NotoSansCJKkr";
          font-size: 17px;
          cursor: pointer;
          padding: 0;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: -0.01px;
          text-align: center;
          width: calc(100% / 2);
          border-top: 0.5px solid #3f3f3f;
          border-right: 0.5px solid #3f3f3f;
          background-color: transparent;
        }
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

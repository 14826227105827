<template>
  <div class="app-context editor-wrap">
    <ckeditor
      :editor="editor"
      v-model="afterEditorData"
      :config="editorConfig"
      @input="detact()"
      @ready="onReady"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import ParagraphButtonUI from '@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import UploadAdapter from '@/utils/uploadadapter';

export default {
  props: {
    editorData: {
      type: String,
    },
    afterEditorData: '',
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        /**
        extraPlugins: [this.uploader],
         */
        plugins: [
          Indent,
          IndentBlock,
          Font,
          ListStyle,
          BoldPlugin,
          EssentialsPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          HtmlEmbed,
          Highlight,
          HorizontalLine,
          RemoveFormat,
          Heading,
          HeadingButtonsUI,
          ParagraphButtonUI,
          Image,
          ImageInsert,
          ImageToolbar,
          ImageStyle,
          ImageResize,
          ImageResizeEditing,
          ImageResizeHandles,
        ],
        toolbar: {
          items: [
            'fontSize',
            'bold',
            'italic',
            'link',
            '|',
            'paragraph',
            'heading1',
            'heading2',
            'highlight',
            '|',
            'horizontalLine',
            'indent',
            'outdent',
            'bulletedList',
            'numberedList',
            '|',
            'imageInsert',
            '|',

            'undo',
            'redo',
            '|',
            'htmlEmbed',
            'removeFormat',
          ],
          shouldNotGroupWhenFull: true,
        },
        htmlEmbed: {
          showPreviews: true,
          sanitizeHtml: (inputHtml) => {
            // Strip unsafe elements and attributes, e.g.:
            // the `<script>` elements and `on*` attributes.
            const outputHtml = this.$sanitize(inputHtml);

            return {
              html: outputHtml,
              // true or false depending on whether the sanitizer stripped anything.
              hasChanged: true,
            };
          },
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
          ],
        },
        highlight: {
          options: [
            {
              model: 'greenMarker',
              class: 'marker-green',
              title: 'Green marker',
              color: 'rgb(25, 156, 25)',
              type: 'marker',
            },
            {
              model: 'yellowMarker',
              class: 'marker-yellow',
              title: 'Yellow marker',
              color: '#cac407',
              type: 'marker',
            },
            {
              model: 'pinkMarker',
              class: 'marker-pink',
              title: 'Pink marker',
              color: 'rgb(255, 192, 203)',
              type: 'marker',
            },
            {
              model: 'blueMarker',
              class: 'marker-blue',
              title: 'Blue marker',
              color: 'rgb(50, 82, 123)',
              type: 'marker',
            },
            {
              model: 'redPen',
              class: 'pen-red',
              title: 'Red pen',
              color: 'hsl(343, 82%, 58%)',
              type: 'pen',
            },
            {
              model: 'greenPen',
              class: 'pen-green',
              title: 'Green pen',
              color: 'hsl(148, 100%, 38%)',
              type: 'pen',
            },
          ],
        },
        fontFamily: {
          options: ['tiny', 'default', 'big', 'huge'],
          supportAllValues: true,
        },
        image: {
          styles: ['alignLeft', 'alignCenter', 'alignRight'],
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'imageResize:original',
              label: 'Original',
              value: null,
              icon: 'original',
            },
            {
              name: 'imageResize:25',
              label: '25%',
              value: '25',
              icon: 'small',
            },
            {
              name: 'imageResize:50',
              label: '50%',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'imageResize:75',
              label: '75%',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            // ...,
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageResize:25',
            'imageResize:50',
            'imageResize:75',
            'imageResize:original',
          ],
        },
        // The configuration of the editor.
      },
    };
  },
  components: {},
  created() {
    this.afterEditorData = this.editorData;
  },
  methods: {
    detact() {
      this.$emit('sendEditContent', this.afterEditorData);
      console.log('detact 발동');
    },
    onReady(editor) {
      editor.execute('removeFormat');
      editor.model.schema.setAttributeProperties('linkHref', {
        isFormatting: true,
      });
      editor.disableAutoInline = true;
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
      editor.execute('listStyle', { type: 'decimal' });
      editor.execute('listStyle', { type: 'disc' });
    },
    uploader(editor) {
      console.log('editor.plugins', editor.plugins.get('FileRepository'));
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new UploadAdapter(loader);
    },
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss">
.editor-wrap {
  .ck-editor__editable {
    min-height: 400px !important;
    min-width: 710px !important;
  }

  .ck-button {
    zoom: 0.7;
  }

  .ck > h1 {
    font-size: 40px;
    font-weight: normal;
    font-family: "NanumSquareB";
  }

  .ck > h2 {
    font-size: 27px;
    font-weight: normal;
    font-family: "NanumSquareB";
  }

  .ck > p {
    font-family: "NanumSquareB";
    width:100%;
  }
  .ck > ul.ck-reset {
    padding-left: 0 !important;
  }
  .ck > ol,
  .ck > ul {
    padding-left: 20px;
    li {
      position: relative;
      &::marker {
        position: absolute;
      }
    }
  }
}
</style>

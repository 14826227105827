var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-context" },
    [
      _c("side-menu", { attrs: { deepNavi: _vm.deep } }),
      _c(
        "div",
        { staticClass: "wrap-context" },
        [
          _c("notification", {
            attrs: { tree: _vm.tree },
            on: { "call-logout-popup": _vm.callLogoutPopup }
          }),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "leaveWrap" }, [
              _c("p", { staticClass: "info" }, [_vm._v("아이디 정보")]),
              _c(
                "p",
                {
                  staticClass: "leave",
                  on: {
                    click: function($event) {
                      return _vm.withDraw()
                    }
                  }
                },
                [_vm._v("회원탈퇴")]
              )
            ]),
            _c("div", { staticClass: "member-result" }, [
              _c("div", { staticClass: "member-info-set" }, [
                _c(
                  "div",
                  { staticClass: "content" },
                  _vm._l(_vm.memberInner[0], function(value, index) {
                    return _c(
                      "div",
                      { key: index + "A", staticClass: "item" },
                      [
                        _c("p", {
                          staticClass: "cate",
                          domProps: { textContent: _vm._s(value.questionText) }
                        }),
                        _c("p", {
                          staticClass: "info",
                          domProps: { textContent: _vm._s(value.answerResult) }
                        })
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "bottom-btn-wrap" }, [
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("목록으로")]
              )
            ])
          ])
        ],
        1
      ),
      _vm.checkPopup
        ? _c("default-popup", {
            attrs: { popupSet: _vm.popupSet },
            on: { "result-check": _vm.resultCheck }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    attrs: { destination: _vm.destination, huge: _vm.huge, medium: _vm.medium },
    on: {
      "forced-nextlink": function($event) {
        return _vm.goNextLink()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _vm.popType === "anajugi"
              ? _c(
                  "div",
                  {
                    staticClass: "default-pop frame anajugi",
                    class: { askWrap: _vm.popNumber === 7 }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@assets/images/util/x.svg") }
                        })
                      ]
                    ),
                    _vm.popNumber === 3
                      ? _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("관리자 정보 등록")
                          ]),
                          _c("div", { staticClass: "inputWrap" }, [
                            _c("div", { staticClass: "checkId" }, [
                              _c("label", [_vm._v("아이디")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminInfo.adminId,
                                    expression: "adminInfo.adminId"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.adminInfo.adminId },
                                on: {
                                  keyup: function($event) {
                                    return _vm.checkId()
                                  },
                                  focus: function($event) {
                                    return _vm.checkId()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.adminInfo,
                                      "adminId",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "checkPass" }, [
                              _c("label", [_vm._v("비밀번호")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminInfo.password,
                                    expression: "adminInfo.password"
                                  }
                                ],
                                attrs: { type: "password" },
                                domProps: { value: _vm.adminInfo.password },
                                on: {
                                  keyup: function($event) {
                                    return _vm.checkPw("")
                                  },
                                  focus: function($event) {
                                    return _vm.checkPw("")
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.adminInfo,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "checkPass" }, [
                              _c("label", [_vm._v("비밀번호 확인")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminInfo.passConfirm,
                                    expression: "adminInfo.passConfirm"
                                  }
                                ],
                                attrs: { type: "password" },
                                domProps: { value: _vm.adminInfo.passConfirm },
                                on: {
                                  keyup: function($event) {
                                    return _vm.checkPwConfirm("")
                                  },
                                  focus: function($event) {
                                    return _vm.checkPwConfirm("")
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.adminInfo,
                                      "passConfirm",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "userName" }, [
                              _c("label", [_vm._v("이름")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminInfo.name,
                                    expression: "adminInfo.name"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.adminInfo.name },
                                on: {
                                  keyup: function($event) {
                                    return _vm.checkName()
                                  },
                                  focus: function($event) {
                                    return _vm.checkName()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.adminInfo,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "email" }, [
                              _c("label", [_vm._v("이메일")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminInfo.email,
                                    expression: "adminInfo.email"
                                  }
                                ],
                                attrs: { type: "email" },
                                domProps: { value: _vm.adminInfo.email },
                                on: {
                                  keyup: function($event) {
                                    return _vm.checkEmail()
                                  },
                                  focus: function($event) {
                                    return _vm.checkEmail()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.adminInfo,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "phone" }, [
                              _c("label", [_vm._v("연락처")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminInfo.phone,
                                    expression: "adminInfo.phone"
                                  }
                                ],
                                attrs: {
                                  type: "tel",
                                  placeholder: "010-1234-5678",
                                  pattern: "[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}",
                                  maxlength: "13"
                                },
                                domProps: { value: _vm.adminInfo.phone },
                                on: {
                                  keyup: function($event) {
                                    return _vm.checkPhone()
                                  },
                                  focus: function($event) {
                                    return _vm.checkPhone()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.adminInfo,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm.adminInfo.errorFlag === true
                              ? _c("div", { staticClass: "warn-message" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.adminInfo.errorMessage) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e(),
                    _vm.popNumber === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "btn-wrap",
                            class: {
                              alone:
                                _vm.confirmBtnText === null ||
                                _vm.cancelBtnText === null
                            }
                          },
                          [
                            _vm.cancelBtnText !== null
                              ? _c("button", {
                                  attrs: { type: "button" },
                                  domProps: {
                                    textContent: _vm._s(_vm.cancelBtnText)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.close()
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.confirmBtnText !== null
                              ? _c("button", {
                                  staticClass: "active",
                                  attrs: { type: "button" },
                                  domProps: {
                                    textContent: _vm._s(_vm.confirmBtnText)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.registerManage()
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm.popNumber === 7
                      ? _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("상세보기")
                          ]),
                          _c("div", { staticClass: "inputWrap askInput" }, [
                            _c("div", { staticClass: "checkId" }, [
                              _c("label", [_vm._v("아이디")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.ask.user_id,
                                    expression: "ask.user_id"
                                  }
                                ],
                                staticClass: "readonly",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.ask.user_id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.ask,
                                      "user_id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "checkPass" }, [
                              _c("label", [_vm._v("제목")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.ask.title,
                                    expression: "ask.title"
                                  }
                                ],
                                staticClass: "readonly",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.ask.title },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.ask,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "userName" }, [
                              _c("label", [_vm._v("궁금한 사항")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.ask.content,
                                    expression: "ask.content"
                                  }
                                ],
                                staticClass: "readonly",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.ask.content },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.ask,
                                      "content",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "answer" }, [
                              _c("label", [_vm._v("답변")]),
                              _c("input", {
                                attrs: { type: "text" },
                                domProps: { value: _vm.askReply },
                                on: {
                                  input: _vm.changeName,
                                  keyup: function($event) {
                                    return _vm.checkAsk()
                                  }
                                }
                              })
                            ]),
                            _vm.askErrorFlag === true
                              ? _c("div", { staticClass: "warn-message" }, [
                                  _vm._v(_vm._s(_vm.askErrorMessage))
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e(),
                    _vm.popNumber === 7
                      ? _c(
                          "div",
                          {
                            staticClass: "btn-wrap",
                            class: {
                              alone:
                                _vm.confirmBtnText === null ||
                                _vm.cancelBtnText === null
                            }
                          },
                          [
                            _vm.cancelBtnText !== null
                              ? _c("button", {
                                  attrs: { type: "button" },
                                  domProps: {
                                    textContent: _vm._s(_vm.cancelBtnText)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.close()
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.confirmBtnText !== null
                              ? _c("button", {
                                  staticClass: "active",
                                  attrs: { type: "button" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.ask.reply === null
                                        ? _vm.confirmBtnText
                                        : "수정"
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.registerAnswer()
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.popType === "anajugiDoc"
              ? _c("div", { staticClass: "default-pop frame anajugi" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  _vm.popNumber === 4
                    ? _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("의사 회원 정보 등록")
                        ]),
                        _c("div", { staticClass: "inputWrap" }, [
                          _c("div", { staticClass: "checkId" }, [
                            _c("label", [_vm._v("아이디")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.userId,
                                  expression: "adminInfo.userId"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.adminInfo.userId },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkDocId("")
                                },
                                focus: function($event) {
                                  return _vm.checkDocId()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "userId",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "checkPass" }, [
                            _c("label", [_vm._v("비밀번호")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.password,
                                  expression: "adminInfo.password"
                                }
                              ],
                              attrs: { type: "password" },
                              domProps: { value: _vm.adminInfo.password },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkPw("")
                                },
                                focus: function($event) {
                                  return _vm.checkPw("")
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "password",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "checkPass" }, [
                            _c("label", [_vm._v("비밀번호 확인")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.passConfirm,
                                  expression: "adminInfo.passConfirm"
                                }
                              ],
                              attrs: { type: "password" },
                              domProps: { value: _vm.adminInfo.passConfirm },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkPwConfirm("")
                                },
                                focus: function($event) {
                                  return _vm.checkPwConfirm("")
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "passConfirm",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "userName" }, [
                            _c("label", [_vm._v("이름")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.nickname,
                                  expression: "adminInfo.nickname"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.adminInfo.nickname },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkDocName("")
                                },
                                focus: function($event) {
                                  return _vm.checkDocName("")
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "nickname",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "email" }, [
                            _c("label", [_vm._v("이메일")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.email,
                                  expression: "adminInfo.email"
                                }
                              ],
                              attrs: { type: "email" },
                              domProps: { value: _vm.adminInfo.email },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkEmail("")
                                },
                                focus: function($event) {
                                  return _vm.checkEmail("")
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "hospital" }, [
                            _c("label", [_vm._v("소속 병원")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.hospital,
                                  expression: "adminInfo.hospital"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.adminInfo.hospital },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkDochospital("")
                                },
                                focus: function($event) {
                                  return _vm.checkDochospital("")
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "hospital",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "phone" }, [
                            _c("label", [_vm._v("연락처")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adminInfo.phone,
                                  expression: "adminInfo.phone"
                                }
                              ],
                              attrs: {
                                type: "tel",
                                placeholder: "010-1234-5678",
                                maxlength: "13"
                              },
                              domProps: { value: _vm.adminInfo.phone },
                              on: {
                                keyup: function($event) {
                                  return _vm.checkPhone("")
                                },
                                focus: function($event) {
                                  return _vm.checkPhone("")
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adminInfo,
                                    "phone",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm.adminInfo.errorFlag
                            ? _c("div", { staticClass: "warn-message" }, [
                                _vm._v(_vm._s(_vm.adminInfo.errorMessage))
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.registerDoc()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "anajugiEdit"
              ? _c("div", { staticClass: "default-pop frame anajugi" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("관리자 정보 수정")
                    ]),
                    _c("div", { staticClass: "inputWrap" }, [
                      _c("div", { staticClass: "checkId" }, [
                        _c("label", [_vm._v("아이디")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.userId,
                              expression: "adminInfo.userId"
                            }
                          ],
                          staticClass: "readonly",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.adminInfo.userId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.adminInfo,
                                "userId",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "checkPass" }, [
                        _c("label", [_vm._v("비밀번호")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.password,
                              expression: "adminInfo.password"
                            }
                          ],
                          attrs: { type: "password" },
                          domProps: { value: _vm.adminInfo.password },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "password",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkPw("edit")
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkPwConfirm("edit")
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "checkPass" }, [
                        _c("label", [_vm._v("비밀번호 확인")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.passConfirm,
                              expression: "adminInfo.passConfirm"
                            }
                          ],
                          attrs: { type: "password" },
                          domProps: { value: _vm.adminInfo.passConfirm },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "passConfirm",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkPwConfirm("edit")
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkPwConfirm("edit")
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "userName" }, [
                        _c("label", [_vm._v("이름")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.nickname,
                              expression: "adminInfo.nickname"
                            }
                          ],
                          staticClass: "readonly",
                          attrs: { readonly: "", type: "text" },
                          domProps: { value: _vm.adminInfo.nickname },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.adminInfo,
                                "nickname",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "email" }, [
                        _c("label", [_vm._v("이메일")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.email,
                              expression: "adminInfo.email"
                            }
                          ],
                          attrs: { type: "email" },
                          domProps: { value: _vm.adminInfo.email },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "email",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkEmail()
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkEmail()
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "phone" }, [
                        _c("label", [_vm._v("연락처")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.phone,
                              expression: "adminInfo.phone"
                            }
                          ],
                          attrs: {
                            type: "tel",
                            placeholder: "010-1234-5678",
                            pattern: "[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}",
                            maxlength: "13"
                          },
                          domProps: { value: _vm.adminInfo.phone },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "phone",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkPhone()
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkPhone()
                            }
                          }
                        })
                      ]),
                      _vm.adminInfo.errorFlag === true
                        ? _c("span", {
                            staticClass: "warn-message",
                            domProps: {
                              textContent: _vm._s(_vm.adminInfo.errorMessage)
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.editManage()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "anajugiEditDoc"
              ? _c("div", { staticClass: "default-pop frame anajugi" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("의사회원 정보 수정")
                    ]),
                    _c("div", { staticClass: "inputWrap" }, [
                      _c("div", { staticClass: "checkId" }, [
                        _c("label", [_vm._v("아이디")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.userId,
                              expression: "adminInfo.userId"
                            }
                          ],
                          staticClass: "readonly",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.adminInfo.userId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.adminInfo,
                                "userId",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "checkPass" }, [
                        _c("label", [_vm._v("비밀번호")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.password,
                              expression: "adminInfo.password"
                            }
                          ],
                          attrs: { type: "password" },
                          domProps: { value: _vm.adminInfo.password },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "password",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkPw("edit")
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkPw("edit")
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "checkPass" }, [
                        _c("label", [_vm._v("비밀번호 확인")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.passConfirm,
                              expression: "adminInfo.passConfirm"
                            }
                          ],
                          attrs: { type: "password" },
                          domProps: { value: _vm.adminInfo.passConfirm },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "passConfirm",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkPwConfirm("edit")
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkPwConfirm("edit")
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "userName" }, [
                        _c("label", [_vm._v("이름")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.nickname,
                              expression: "adminInfo.nickname"
                            }
                          ],
                          staticClass: "readonly",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.adminInfo.nickname },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.adminInfo,
                                "nickname",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "email" }, [
                        _c("label", [_vm._v("이메일")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.email,
                              expression: "adminInfo.email"
                            }
                          ],
                          attrs: { type: "email" },
                          domProps: { value: _vm.adminInfo.email },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "email",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkEmail()
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkEmail()
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "hospital" }, [
                        _c("label", [_vm._v("소속 병원")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.hospital,
                              expression: "adminInfo.hospital"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.adminInfo.hospital },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "hospital",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkDochospital()
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkDochospital()
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "phone" }, [
                        _c("label", [_vm._v("연락처")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.adminInfo.phone,
                              expression: "adminInfo.phone"
                            }
                          ],
                          attrs: { type: "tel" },
                          domProps: { value: _vm.adminInfo.phone },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.adminInfo,
                                  "phone",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.checkPhone(_vm.adminInfo.phone)
                              }
                            ],
                            focus: function($event) {
                              return _vm.checkPhone(_vm.adminInfo.phone)
                            }
                          }
                        })
                      ]),
                      _vm.adminInfo.errorFlag
                        ? _c("div", { staticClass: "warn-message" }, [
                            _vm._v(_vm._s(_vm.adminInfo.errorMessage))
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.editDoc("edit")
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "declareDetail"
              ? _c(
                  "div",
                  {
                    staticClass: "default-pop frame huge anajugi declareDetail"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@assets/images/util/x.svg") }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "content" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("신고 당한 내용")
                      ]),
                      _c("div", { staticClass: "inputWrap" }, [
                        _c(
                          "div",
                          { staticClass: " declareTitle declareContext" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "declareT",
                                class: {
                                  addDeclare: _vm.popupSet.declareId.type === 1
                                }
                              },
                              [_vm._v(" 제목 ")]
                            ),
                            _c("div", { staticClass: " declareC" }, [
                              _c("div", {
                                class: {
                                  addDeclare: _vm.popupSet.declareId.type === 1
                                },
                                domProps: {
                                  textContent: _vm._s(_vm.declareContent.title)
                                }
                              })
                            ])
                          ]
                        ),
                        _vm.declareContent.photos.length > 0
                          ? _c("div", { staticClass: " declarePicture" }, [
                              _c("div", { staticClass: "declareT" }, [
                                _vm._v("사진")
                              ]),
                              _c(
                                "div",
                                { staticClass: " declareC imgWrap" },
                                _vm._l(_vm.declareContent.photos, function(
                                  items,
                                  index
                                ) {
                                  return _c("p", [
                                    _c("img", {
                                      staticStyle: { height: "150px" },
                                      attrs: { src: items.photoUrl }
                                    })
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: " declareContent declareContext" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "declareT",
                                class: {
                                  addDeclare: _vm.popupSet.declareId.type === 1
                                }
                              },
                              [_vm._v(" 내용 ")]
                            ),
                            _c("div", { staticClass: " declareC" }, [
                              _c("div", {
                                class: {
                                  addDeclare: _vm.popupSet.declareId.type === 1
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.declareContent.content
                                  )
                                }
                              })
                            ])
                          ]
                        ),
                        _vm.declareContent.comments.length > 0
                          ? _c(
                              "div",
                              { staticClass: "declareComment declareContext" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "declareT",
                                    class: {
                                      addDeclare: _vm.declareClass[0] === 2
                                    }
                                  },
                                  [_vm._v("댓글")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "declareC" },
                                  _vm._l(_vm.declareContent.comments, function(
                                    items,
                                    index
                                  ) {
                                    return _c("div", [
                                      _c("div", {
                                        class: {
                                          addDeclare:
                                            _vm.declareClass[0] === 2 &&
                                            _vm.declareClass[1] === index
                                        },
                                        domProps: {
                                          textContent: _vm._s(items.content)
                                        }
                                      })
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.declareContent.replys.length > 0
                          ? _c(
                              "div",
                              { staticClass: " declareReply declareContext" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "declareT",
                                    class: {
                                      addDeclare: _vm.declareClass[0] === 3
                                    }
                                  },
                                  [_vm._v("답글")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: " declareC" },
                                  _vm._l(_vm.declareContent.replys, function(
                                    items,
                                    index
                                  ) {
                                    return _c("div", [
                                      _c("div", {
                                        class: {
                                          addDeclare:
                                            _vm.declareClass[0] === 3 &&
                                            _vm.declareClass[1] === index
                                        },
                                        domProps: {
                                          textContent: _vm._s(items.content)
                                        }
                                      })
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm.popType === "anajugiDel"
              ? _c("div", { staticClass: "default-pop frame" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  !_vm.imgHide
                    ? _c("div", { staticClass: "pop-img" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/popup/" + _vm.popImg)
                          }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "content" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                    }),
                    _vm.popupSet.attention
                      ? _c("p", {
                          staticClass: "attention",
                          domProps: {
                            innerHTML: _vm._s(_vm.popupSet.attention)
                          }
                        })
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.deleteManage()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "anajugiAdminEdit"
              ? _c("div", { staticClass: "default-pop frame" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  !_vm.imgHide
                    ? _c("div", { staticClass: "pop-img" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/popup/" + _vm.popImg)
                          }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "content" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.editManage()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "withDraw"
              ? _c("div", { staticClass: "default-pop frame" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  !_vm.imgHide
                    ? _c("div", { staticClass: "pop-img" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/popup/" + _vm.popImg)
                          }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "content" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.withDraw()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "warn"
              ? _c("div", { staticClass: "default-pop frame" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  !_vm.imgHide
                    ? _c("div", { staticClass: "pop-img" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/popup/" + _vm.popImg)
                          }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "content" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.action()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "resultCheck"
              ? _c("div", { staticClass: "default-pop frame" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  !_vm.imgHide
                    ? _c("div", { staticClass: "pop-img" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/popup/" + _vm.popImg)
                          }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "content" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-wrap",
                      class: {
                        alone:
                          _vm.confirmBtnText === null ||
                          _vm.cancelBtnText === null
                      }
                    },
                    [
                      _vm.cancelBtnText !== null
                        ? _c("button", {
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.cancelBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.confirmBtnText !== null
                        ? _c("button", {
                            staticClass: "active",
                            attrs: { type: "button" },
                            domProps: {
                              textContent: _vm._s(_vm.confirmBtnText)
                            },
                            on: {
                              click: function($event) {
                                return _vm.resultCheckAction()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm.popType === "write"
              ? _c("div", { staticClass: "default-pop frame tat" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@assets/images/util/x.svg") }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                    })
                  ]),
                  _c("div", { staticClass: "textarea-wide" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dataValue,
                          expression: "dataValue"
                        }
                      ],
                      domProps: { value: _vm.dataValue },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.write()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.dataValue = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "btn-wrap" }, [
                    _c("button", {
                      attrs: { type: "button" },
                      domProps: { textContent: _vm._s(_vm.cancelBtnText) },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    }),
                    _c("button", {
                      staticClass: "active",
                      attrs: { type: "button" },
                      domProps: { textContent: _vm._s(_vm.confirmBtnText) },
                      on: {
                        click: function($event) {
                          return _vm.write()
                        }
                      }
                    })
                  ])
                ])
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
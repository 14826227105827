<template>
  <modal :destination="destination" :huge="huge" :medium="medium" @forced-nextlink="goNextLink()">
    <template v-slot:body>
      <div
        class="default-pop frame anajugi"
        :class="{ askWrap: popNumber === 7 }"
        v-if="popType === 'anajugi'"
      >
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div v-if="popNumber === 3" class="content">
          <div class="title">관리자 정보 등록</div>
          <div class="inputWrap">
            <div class="checkId">
              <label>아이디</label
              ><input
                type="text"
                @keyup="checkId()"
                @focus="checkId()"
                v-model="adminInfo.adminId"
              />
            </div>
            <div class="checkPass">
              <label>비밀번호</label
              ><input
                @keyup="checkPw('')"
                @focus="checkPw('')"
                type="password"
                v-model="adminInfo.password"
              />
            </div>
            <div class="checkPass">
              <label>비밀번호 확인</label
              ><input
                @keyup="checkPwConfirm('')"
                @focus="checkPwConfirm('')"
                type="password"
                v-model="adminInfo.passConfirm"
              />
            </div>
            <div class="userName">
              <label>이름</label
              ><input
                @keyup="checkName()"
                @focus="checkName()"
                type="text"
                v-model="adminInfo.name"
              />
            </div>
            <div class="email">
              <label>이메일</label
              ><input
                @keyup="checkEmail()"
                @focus="checkEmail()"
                type="email"
                v-model="adminInfo.email"
              />
            </div>
            <div class="phone">
              <label>연락처</label
              ><input
                @keyup="checkPhone()"
                @focus="checkPhone()"
                type="tel"
                v-model="adminInfo.phone"
                placeholder="010-1234-5678"
                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}"
                maxlength="13"
              />
            </div>
            <div class="warn-message" v-if="adminInfo.errorFlag === true">
              {{ adminInfo.errorMessage }}
            </div>
          </div>
        </div>
        <div
          class="btn-wrap"
          v-if="popNumber === 3"
          :class="{ alone: confirmBtnText === null || cancelBtnText === null }"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="registerManage()"
            v-if="confirmBtnText !== null"
          />
        </div>
        <div v-if="popNumber === 7" class="content">
          <div class="title">상세보기</div>
          <div class="inputWrap askInput">
            <div class="checkId">
              <label>아이디</label
              ><input class="readonly" type="text" v-model="ask.user_id" readonly />
            </div>
            <div class="checkPass">
              <label>제목</label><input class="readonly" type="text" v-model="ask.title" readonly />
            </div>
            <div class="userName">
              <label>궁금한 사항</label
              ><input class="readonly" type="text" v-model="ask.content" readonly />
            </div>
            <div class="answer">
              <label>답변</label
              ><input :value="askReply" @input="changeName" @keyup="checkAsk()" type="text" />
            </div>
            <div class="warn-message" v-if="askErrorFlag === true">{{ askErrorMessage }}</div>
          </div>
        </div>
        <div
          class="btn-wrap"
          v-if="popNumber === 7"
          :class="{ alone: confirmBtnText === null || cancelBtnText === null }"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="ask.reply === null ? confirmBtnText : '수정'"
            type="button"
            @click="registerAnswer()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame anajugi" v-if="popType === 'anajugiDoc'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div v-if="popNumber === 4" class="content">
          <div class="title">의사 회원 정보 등록</div>
          <div class="inputWrap">
            <div class="checkId">
              <label>아이디</label
              ><input
                @keyup="checkDocId('')"
                @focus="checkDocId()"
                type="text"
                v-model="adminInfo.userId"
              />
            </div>
            <div class="checkPass">
              <label>비밀번호</label
              ><input
                type="password"
                @keyup="checkPw('')"
                @focus="checkPw('')"
                v-model="adminInfo.password"
              />
            </div>
            <div class="checkPass">
              <label>비밀번호 확인</label
              ><input
                @keyup="checkPwConfirm('')"
                @focus="checkPwConfirm('')"
                type="password"
                v-model="adminInfo.passConfirm"
              />
            </div>
            <div class="userName">
              <label>이름</label
              ><input
                @keyup="checkDocName('')"
                @focus="checkDocName('')"
                type="text"
                v-model="adminInfo.nickname"
              />
            </div>
            <div class="email">
              <label>이메일</label
              ><input
                @keyup="checkEmail('')"
                @focus="checkEmail('')"
                type="email"
                v-model="adminInfo.email"
              />
            </div>
            <div class="hospital">
              <label>소속 병원</label
              ><input
                @keyup="checkDochospital('')"
                @focus="checkDochospital('')"
                type="text"
                v-model="adminInfo.hospital"
              />
            </div>
            <div class="phone">
              <label>연락처</label
              ><input
                @keyup="checkPhone('')"
                @focus="checkPhone('')"
                type="tel"
                placeholder="010-1234-5678"
                v-model="adminInfo.phone"
                maxlength="13"
              />
            </div>
            <div class="warn-message" v-if="adminInfo.errorFlag">{{ adminInfo.errorMessage }}</div>
          </div>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="registerDoc()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame anajugi" v-if="popType === 'anajugiEdit'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <div class="title">관리자 정보 수정</div>
          <!-- <div class="checkId"><label>아이디</label><input type="text" v-model="adminInfo.adminId"/></div>
            <div class="checkPass"><label>비밀번호</label><input type="password" v-model="pw"/></div>
            <div class="userName"><label>이름</label><input type="text" v-model="adminInfo.name"/></div>
            <div class="email"><label>이메일</label><input type="email" v-model="adminInfo.email"/></div>
            <div class="phone"><label>연락처</label><input type="tel" v-model="adminInfo.phone" placeholder="000-000-0000" @keyup="getTel()" pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}" maxlength="13"></div> -->

          <div class="inputWrap">
            <div class="checkId">
              <label>아이디</label
              ><input class="readonly" type="text" v-model="adminInfo.userId" readonly />
            </div>
            <div class="checkPass">
              <label>비밀번호</label
              ><input
                @input="checkPw('edit')"
                type="password"
                @focus="checkPwConfirm('edit')"
                v-model="adminInfo.password"
              />
            </div>
            <div class="checkPass">
              <label>비밀번호 확인</label
              ><input
                @input="checkPwConfirm('edit')"
                @focus="checkPwConfirm('edit')"
                type="password"
                v-model="adminInfo.passConfirm"
              />
            </div>
            <div class="userName">
              <label>이름</label
              ><input class="readonly" readonly type="text" v-model="adminInfo.nickname" />
            </div>
            <div class="email">
              <label>이메일</label
              ><input
                @input="checkEmail()"
                @focus="checkEmail()"
                type="email"
                v-model="adminInfo.email"
              />
            </div>
            <div class="phone">
              <label>연락처</label
              ><input
                @input="checkPhone()"
                @focus="checkPhone()"
                type="tel"
                v-model="adminInfo.phone"
                placeholder="010-1234-5678"
                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}"
                maxlength="13"
              />
            </div>
            <span
              class="warn-message"
              v-if="adminInfo.errorFlag === true"
              v-text="adminInfo.errorMessage"
            ></span>
          </div>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="editManage()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame anajugi" v-if="popType === 'anajugiEditDoc'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <div class="title">의사회원 정보 수정</div>
          <div class="inputWrap">
            <!-- <div class="checkId"><label>아이디</label><input type="text" v-model="adminInfo.adminId"/></div>
            <div class="checkPass"><label>비밀번호</label><input type="password" v-model="pw"/></div>
            <div class="userName"><label>이름</label><input type="text" v-model="adminInfo.name"/></div>
            <div class="email"><label>이메일</label><input type="email" v-model="adminInfo.email"/></div>
            <div class="phone"><label>연락처</label><input type="tel" v-model="adminInfo.phone" placeholder="000-000-0000" @keyup="getTel()" pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}" maxlength="13"></div> -->

            <div class="checkId">
              <label>아이디</label
              ><input type="text" class="readonly" readonly v-model="adminInfo.userId" />
            </div>
            <div class="checkPass">
              <label>비밀번호</label
              ><input
                type="password"
                @input="checkPw('edit')"
                @focus="checkPw('edit')"
                v-model="adminInfo.password"
              />
            </div>
            <div class="checkPass">
              <label>비밀번호 확인</label
              ><input
                @input="checkPwConfirm('edit')"
                @focus="checkPwConfirm('edit')"
                type="password"
                v-model="adminInfo.passConfirm"
              />
            </div>
            <div class="userName">
              <label>이름</label
              ><input type="text" class="readonly" readonly v-model="adminInfo.nickname" />
            </div>
            <div class="email">
              <label>이메일</label
              ><input
                @input="checkEmail()"
                @focus="checkEmail()"
                type="email"
                v-model="adminInfo.email"
              />
            </div>
            <div class="hospital">
              <label>소속 병원</label
              ><input
                @input="checkDochospital()"
                @focus="checkDochospital()"
                type="text"
                v-model="adminInfo.hospital"
              />
            </div>

            <div class="phone">
              <label>연락처</label
              ><input
                @input="checkPhone(adminInfo.phone)"
                @focus="checkPhone(adminInfo.phone)"
                type="tel"
                v-model="adminInfo.phone"
              />
            </div>
            <div class="warn-message" v-if="adminInfo.errorFlag">{{ adminInfo.errorMessage }}</div>
          </div>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="editDoc('edit')"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame huge anajugi declareDetail" v-if="popType === 'declareDetail'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <div class="title">신고 당한 내용</div>
          <div class="inputWrap">
            <div class=" declareTitle declareContext">
              <div class="declareT" :class="{ addDeclare: popupSet.declareId.type === 1 }">
                제목
              </div>
              <div class=" declareC">
                <div
                  :class="{ addDeclare: popupSet.declareId.type === 1 }"
                  v-text="declareContent.title"
                />
              </div>
            </div>
            <div v-if="declareContent.photos.length > 0" class=" declarePicture">
              <div class="declareT">사진</div>

              <div class=" declareC imgWrap">
                <p v-for="(items, index) in declareContent.photos">
                  <img :src="items.photoUrl" style="height:150px;" />
                </p>
                <!-- <div v-html="declareContent.photos"/> -->
              </div>
            </div>
            <div class=" declareContent declareContext">
              <div class="declareT" :class="{ addDeclare: popupSet.declareId.type === 1 }">
                내용
              </div>

              <div class=" declareC">
                <div
                  v-text="declareContent.content"
                  :class="{ addDeclare: popupSet.declareId.type === 1 }"
                />
              </div>
            </div>
            <div v-if="declareContent.comments.length > 0" class="declareComment declareContext">
              <div :class="{ addDeclare: declareClass[0] === 2 }" class="declareT">댓글</div>
              <div class="declareC">
                <div v-for="(items, index) in declareContent.comments">
                  <div
                    :class="{ addDeclare: declareClass[0] === 2 && declareClass[1] === index }"
                    v-text="items.content"
                  ></div>
                </div>
              </div>
            </div>
            <div v-if="declareContent.replys.length > 0" class=" declareReply declareContext">
              <div :class="{ addDeclare: declareClass[0] === 3 }" class="declareT">답글</div>
              <div class=" declareC">
                <div v-for="(items, index) in declareContent.replys">
                  <div
                    :class="{ addDeclare: declareClass[0] === 3 && declareClass[1] === index }"
                    v-text="items.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'anajugiDel'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
          <p class="attention" v-if="popupSet.attention" v-html="popupSet.attention"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="deleteManage()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'anajugiAdminEdit'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="editManage()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'withDraw'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="withDraw()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'resultCheck'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="resultCheckAction()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame tat" v-if="popType === 'write'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="textarea-wide">
          <textarea v-model="dataValue" @keyup.enter="write()" />
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" @click="close()" />
          <button class="active" v-text="confirmBtnText" type="button" @click="write()" />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import vSelect from 'vue-select';
import Modal from './Modal.vue';

export default {
  props: {
    popupSet: {
      popupSet: {
        type: Boolean,
        default: false,
      },
      deleteBid: { type: Number },
      declareId: { type: Object, default: null },
      overlayRestrict: {
        type: Boolean,
        default: false,
      },
      adminEditInfo: {
        type: Object,
        default: null,
      },
      wishFix: {
        type: Boolean,
        default: false,
      },
      content: {
        type: String,
      },
      attention: {
        type: String,
        default: '',
      },
      imgHide: {
        type: Boolean,
        default: false,
      },
      nextLink: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
        default: 'warn',
      },
      popImg: {
        type: String,
        default: 'warning.svg',
      },
      popImgArr: {
        type: Array,
      },
      destination: {
        type: Boolean,
      },
      huge: {
        type: Boolean,
        default: false,
      },
      medium: {
        type: Boolean,
        default: false,
      },
      popNumber: {
        type: [Number, String],
        default: false,
      },
    },
  },
  components: {
    Modal,
    vSelect,
  },
  data() {
    return {
      popNumber: this.popupSet.popNumber,
      editInfo: this.popupSet.adminEditInfo,
      popAskOption: this.popupSet.popAskOption,
      popType: this.popupSet.popType || 'warn',
      popImgArr: this.popupSet.popImgArr,
      popImg: this.popupSet.popImg || 'warning.svg',
      content: this.popupSet.content,
      nextLink: this.popupSet.nextLink,
      cancelBtnText: this.popupSet.cancelBtnText,
      confirmBtnText: this.popupSet.confirmBtnText,
      destination: this.popupSet.destination,
      huge: this.popupSet.huge,
      medium: this.popupSet.medium,
      param: this.popupSet.param,
      imgHide: this.popupSet.imgHide,
      adminInfo: {
        adminId: '',
        password: '',
        passConfirm: '',
        name: '',
        email: '',
        phone: '',
        errorFlag: false,
        errorMessage: null,
        userId: '',
      },
      // doctor: {
      //   user_id: '',
      //   password: '',
      //   passConfirm: '',
      //   nickname: '',
      //   email: '',
      //   hospital: '',
      //   phone: '',
      //   q: '',
      //   errorFlag: false,
      //   errorMessage: null,
      // },
      ask: {
        start: 0,
        size: 5,
        status: 0,
        sort: 0,
        order: 0,
        q: '',
      },
      askReply: '',
      askErrorFlag: true,
      askErrorMessage: '',
      pw: '',
      editPass: '1234567',
      manage: {
        id: null,
        userId: null,
        start: 0,
        size: 10,
        search: null,
        q: null,
        sort: 0,
        order: 0,
      },
      // manageDoc: {
      //   user_id: null,
      //   start: 0,
      //   size: 10,
      //   search: null,
      //   q: null,
      //   sort: 0,
      //   order: 0,
      // },
      declareContent: null,
      declareCheck: null,
      declareIndex: null,
      declareClass: [],
    };
  },
  watch: {},
  mounted() {
    this.beforeEdit();
    this.beforeDeclareDeatil();
    this.beforeAnswer();
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf('\n') !== 1) {
        return this.content.split('\n').join('<br />');
      }
      return this.content;
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('member', [
      'CREATE_MANAGE',
      'DELETE_MANAGE',
      'FETCH_MANAGE_LIST',
      'EDIT_MANAGE',
      'CREATE_DOCTOR',
      'DELETE_DOCTOR',
      'FETCH_DOCTOR_LIST',
      'EDIT_DOCTOR',
      'CANCEL_DECLARE',
      'DELETE_DECLARE',
      'DECLARE_DETAIL',
      'DECLARE_LIST',
      'DELETE_ACCOUNT',
      'NOTICE_DELETE',
      'GET_PHOTOS',
      'QNA_LIST',
      'QNA_UPDATE',
      'QNA_UPDATE2',
    ]),

    async withDraw() {
      try {
        await this.DELETE_ACCOUNT(this.popupSet.uid.uid);
        const comments = '처리되었습니다.';
        this.$emit('result-check', comments);
      } catch (e) {
        console.log('error', e);
        alert('통신상의 이유로 삭제되지 않았습니다');
        return false;
      }
    },

    async deleteManage() {
      const bid = this.popupSet.deleteBid;
      if (this.popNumber === 3) {
        try {
          await this.DELETE_MANAGE(this.popupSet.deleteBid).then((data) => {});

          const comments = '삭제되었습니다';
          this.$emit('result-check', comments);
        } catch (e) {
          const comments = '삭제되었습니다';
          console.log('error', e);
          alert('통신상의 이유로 삭제되지 않았습니다');
          return false;
        }
      } else if (this.popNumber === 4) {
        try {
          await this.DELETE_DOCTOR(this.popupSet.deleteBid).then((data) => {});
        } catch (e) {
          console.log('error', e);
          alert('통신상의 이유로 삭제되지 않았습니다');
          return false;
        }
      } else if (this.popNumber === 'cancel_declare') {
        try {
          await this.CANCEL_DECLARE(this.popupSet.deleteBid).then((data) => {});
          const comments = '취소되었습니다';
          this.$emit('result-check', comments);
        } catch (e) {
          console.log('error', e);
          alert('통신상의 이유로 삭제되지 않았습니다');
          return false;
        }
      } else if (this.popNumber === 'delete_declare') {
        try {
          await this.DELETE_DECLARE(this.popupSet.deleteBid).then((data) => {});
          const comments = '삭제되었습니다';
          this.$emit('result-check', comments);
        } catch (e) {
          console.log('error', e);
          alert('통신상의 이유로 삭제되지 않았습니다');
          return false;
        }
      } else if (this.popNumber === 6) {
        try {
          await this.NOTICE_DELETE(this.popupSet.deleteBid).then((data) => {
            const comments = '삭제되었습니다';
            this.$emit('result-check', comments);
          });
        } catch (e) {
          console.log('error', e);
          alert('통신상의 이유로 삭제되지 않았습니다');
          return false;
        }
      }
    },

    async beforeEdit() {
      if (this.popNumber === 3) {
        const option = this.popupSet.adminEditInfo;
        const index = this.popupSet.dataId;
        try {
          await this.FETCH_MANAGE_LIST(option).then((data) => {
            this.adminInfo = data.response.List[this.popupSet.dataId];
            this.adminInfo.errorFlag = false;
            this.adminInfo.errorMessage = null;
          });
        } catch (error) {
          console.log('error:', error);
        }
        this.editInfo = {
          aid: this.adminInfo.id,
          name: this.adminInfo.nickname,
          email: this.adminInfo.email,
          phone: this.adminInfo.phone,
        };
      } else if (this.popNumber === 4) {
        const option = this.popupSet.adminEditInfo;
        const index = this.popupSet.dataId;

        try {
          await this.FETCH_DOCTOR_LIST(option).then((data) => {
            this.adminInfo = data.response.List[this.popupSet.dataId];
            this.adminInfo.errorFlag = false;
            this.adminInfo.errorMessage = null;
          });
        } catch (error) {
          console.log('error:', error);
        }
      }
    },
    async beforeAnswer() {
      if (this.popNumber === 7) {
        const index = this.popupSet.deleteBid;
        const option = this.popupSet.popAskOption;
        try {
          await this.QNA_LIST(option).then((data) => {
            this.ask = data.response.List[index];
            this.askReply = this.ask.reply;
          });
        } catch (error) {
          console.log('error:', error);
        }
      }
    },
    // 신고 내용 추출 메서드
    async beforeDeclareDeatil() {
      if (this.popNumber === 5) {
        const { declareId } = this.popupSet;

        const option = { id: declareId.id, type: declareId.type };
        await this.DECLARE_DETAIL(option).then((data) => {
          this.declareContent = data.response;
        });
        // 신고내용의 사진 추출
        let name = this.declareContent.photos;
        if (name.length > 0) {
          for (let i = 0; i < name.length; i++) {
            name[i].photoUrl = name[i].photoUrl.replace('/api/v1/photos/', '');
            try {
              await this.GET_PHOTOS(name[i].photoUrl).then((data) => {
                this.declareContent.photos[i].photoUrl = URL.createObjectURL(data);
              });
            } catch (e) {
              console.log(e, 'error');
            }
          }
        } else if (name.lenth === 1) {
          name = name.replace('/api/v1/photos/', '');
        }
        let highlight;
        if (declareId.type === 1) {
          this.declareIndex = 0;
        } else if (declareId.type === 2) {
          highlight = this.declareContent.comments;
          for (let i = 0; i < highlight.length; i++) {
            if (highlight[i].id === declareId.id) {
              this.declareIndex = i;
            }
          }
        } else if (declareId.type === 3) {
          highlight = this.declareContent.replys;
          for (let i = 0; i < highlight.length; i++) {
            if (highlight[i].id === declareId.id) {
              this.declareIndex = i;
            }
          }
        }
        this.declareClass = [declareId.type, this.declareIndex];
      }
    },
    checkAsk() {
      if (!this.askReply) {
        this.askErrorFlag = true;
        this.askErrorMessage = '답변을 입력해주세요.';
      } else {
        this.askErrorFlag = false;
        this.askErrorMessage = '';
      }
      return this.askErrorFlag;
    },
    changeName(e) {
      this.askReply = e.target.value;
    },
    async editManage() {
      this.checkEmail();
      this.checkPhone();
      if (this.checkEmail() !== true) {
        return;
      }
      if (this.checkPhone() !== true) {
        return;
      }
      this.editInfo.name = this.adminInfo.nickname;
      this.editInfo.email = this.adminInfo.email;
      this.editInfo.phone = this.adminInfo.phone;

      const errorSavePass = this.adminInfo.password;
      if (this.adminInfo.password !== '' && this.adminInfo.password !== undefined) {
        this.checkPw('edit');
        if (this.checkPw('edit') !== true) {
          return;
        }
        const crypto = require('crypto');
        const password = crypto
          .createHash('sha512')
          .update(this.adminInfo.password)
          .digest('base64');

        this.editInfo.password = password;
      }
      try {
        await this.EDIT_MANAGE(this.editInfo).then((data) => {});
      } catch (e) {
        this.adminInfo.password = errorSavePass;
        alert(e.data.message);
      }
      this.close();
      window.location.reload();
    },

    async registerAnswer() {
      if (this.checkAsk() === true) {
        return;
      }
      const option = { qid: this.ask.id, answer: this.askReply };
      try {
        if (!this.ask.reply) {
          await this.QNA_UPDATE(option).then((data) => {
            console.log(data, '등록');
          });
        } else {
          await this.QNA_UPDATE2(option).then((data) => {
            console.log(data, '수정');
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.close();
      window.location.reload();
    },

    async registerManage() {
      this.checkId();
      this.checkName();
      this.checkPw();
      this.checkEmail();
      this.checkPhone();
      if (this.checkId() === false) {
        return;
      }
      if (this.checkName() === false) {
        return;
      }
      if (this.checkPw() === false) {
        return;
      }
      if (this.checkEmail() === false) {
        return;
      }
      if (this.checkPhone() === false) {
        return;
      }

      const errorSavePass = this.adminInfo.password;
      const crypto = require('crypto');
      const password = crypto
        .createHash('sha512')
        .update(this.adminInfo.password)
        .digest('base64');

      this.adminInfo.password = password;

      try {
        await this.CREATE_MANAGE(this.adminInfo).then((data) => {
          console.log(data);
        });
      } catch (e) {
        this.adminInfo.password = errorSavePass;
        alert(e.data.message);
        return false;
      }

      this.close();
      window.location.reload();
    },
    checkId(id0) {
      const id = this.adminInfo.adminId.replace(/\s/gi, '');
      const isId = /^[A-z0-9]{5,15}$/g;
      if (id === '') {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '아이디를 입력해주세요.';
        return false;
      }

      if (!isId.test(id)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '영어와 숫자로 5자리 이상 입력해주세요.';
        return false;
      }
      this.adminInfo.errorFlag = false;
      return true;
    },
    checkName(name0) {
      const name = this.adminInfo.name.replace(/\s/gi, '');
      const isId = /^[A-z0-9]{5,15}$/g;
      const isName = /^[A-zㄱ-ㅎ]{1}$/g;

      if (isName.test(name)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '한글과 영어로 2자리 이상 입력해주세요.';
        return false;
      }
      const isNumber = /[0-9]/;
      if (isNumber.test(name)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '한글과 영어만 입력할 수 있습니다.';
        return false;
      }
      if (name === '' || name === undefined) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '이름을 입력해주세요.';
        return false;
      }
      this.adminInfo.errorFlag = false;
      return true;
    },
    checkPhone(phone0) {
      if (this.adminInfo.phone.length === 3) {
        this.adminInfo.phone += '-';
      }
      if (this.adminInfo.phone.length === 8) {
        this.adminInfo.phone += '-';
      }
      this.adminInfo.phone = this.adminInfo.phone.replace(/[^0-9,-]/g, '');
      const Phone = this.adminInfo.phone.replace(/\s/gi, '');
      if (Phone === '') {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '핸드폰번호를 입력해주세요.';
        return false;
      }
      this.adminInfo.errorFlag = true;
      const isPhone = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;

      if (!isPhone.test(Phone)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '숫자로 11자리를 입력해주세요.';
        return false;
      }

      this.adminInfo.errorMessage = '';
      this.adminInfo.errorFlag = false;
      return true;
    },
    checkEmail(email0) {
      const mail = this.adminInfo.email.replace(/\s/gi, '');
      if (mail === '') {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '이메일을 입력해주세요.';
        return false;
      }
      const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!isEmail.test(mail)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '이메일 형식에 맞지 않습니다.';
        return false;
      }
      this.adminInfo.errorFlag = false;
      return true;
    },
    checkPw(pw) {
      this.adminInfo.errorFlag = true;
      if (pw !== 'edit') {
        if (this.adminInfo.password !== '' && this.adminInfo.password !== undefined) {
          const pw = this.adminInfo.password.replace(/\s/gi, '');
          const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}$/;
          if (!isPw.test(pw)) {
            this.adminInfo.errorFlag = true;
            this.adminInfo.errorMessage = '영어+숫자+특수문자의 조합으로 8~20자리를 입력해주세요.';
            return false;
          }
        } else {
          this.adminInfo.errorFlag = true;
          this.adminInfo.errorMessage = '비밀번호를 입력해주세요.';
          return false;
        }
        this.adminInfo.errorMessage = '';
        this.adminInfo.errorFlag = false;
      }
      if (pw === 'edit') {
        if (this.adminInfo.password !== '' && this.adminInfo.password !== undefined) {
          const pw = this.adminInfo.password.replace(/\s/gi, '');
          const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}$/;
          if (!isPw.test(pw)) {
            this.adminInfo.errorFlag = true;
            this.adminInfo.errorMessage = '영어+숫자+특수문자의 조합으로 8~20자리를 입력해주세요.';
            return false;
          }
        }
        this.adminInfo.errorMessage = '';
        this.adminInfo.errorFlag = false;
      }
      return true;
    },
    checkPwConfirm(pw) {
      if (pw !== 'edit') {
        if (this.adminInfo.passConfirm !== '' && this.adminInfo.passConfirm !== undefined) {
          const pw = this.adminInfo.passConfirm.replace(/\s/gi, '');

          if (this.adminInfo.password !== this.adminInfo.passConfirm) {
            this.adminInfo.errorFlag = true;
            this.adminInfo.errorMessage = '비밀번호가 일치하지 않습니다.';
            return false;
          }
        } else {
          this.adminInfo.errorFlag = true;
          this.adminInfo.errorMessage = '비밀번호를 입력해주세요.';
          return false;
        }
      }
      if (pw === 'edit') {
        if (
          this.adminInfo.password !== ''
          && this.adminInfo.password !== undefined
          && this.adminInfo.passConfirm !== ''
          && this.adminInfo.passConfirm !== undefined
        ) {
          const pw = this.adminInfo.passConfirm.replace(/\s/gi, '');

          if (this.adminInfo.password !== this.adminInfo.passConfirm) {
            this.adminInfo.errorFlag = true;
            this.adminInfo.errorMessage = '비밀번호가 일치하지 않습니다.';
            return false;
          }
        }
      }
      this.adminInfo.errorMessage = '';
      this.adminInfo.errorFlag = false;
      return true;
    },

    checkDocId(id0) {
      const id = this.adminInfo.userId.replace(/\s/gi, '');
      const isId = /^[A-z0-9]{5,15}$/g;
      if (id === '') {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '아이디를 입력해주세요.';
        return false;
      }

      if (!isId.test(id)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '영어와 숫자로 5자리 이상 입력해주세요.';
        return false;
      }
      this.adminInfo.errorFlag = false;
      return true;
    },
    async registerDoc() {
      this.checkDocId();
      this.checkDocName();
      this.checkPw();
      this.checkEmail();
      this.checkPhone();
      if (this.checkDocId() === false) {
        return;
      }
      if (this.checkDocName() === false) {
        return;
      }
      if (this.checkPw() === false) {
        return;
      }
      if (this.checkEmail() === false) {
        return;
      }
      if (this.checkPhone() === false) {
        return;
      }

      const errorSavePass = this.adminInfo.password;
      const crypto = require('crypto');
      const password = crypto
        .createHash('sha512')
        .update(this.adminInfo.password)
        .digest('base64');

      this.adminInfo.user_id = this.adminInfo.userId;
      this.adminInfo.password = password;

      try {
        await this.CREATE_DOCTOR(this.adminInfo).then((data) => {
          console.log(data);
        });
      } catch (e) {
        this.adminInfo.password = errorSavePass;
        alert(e.data.message);
        return false;
      }

      this.close();
      window.location.reload();
    },
    checkDocName(name0) {
      const name = this.adminInfo.nickname.replace(/\s/gi, '');
      const isId = /^[A-z0-9]{5,15}$/g;
      const isName = /^[A-zㄱ-ㅎ]{1}$/g;

      if (isName.test(name)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '한글과 영어로 2자리 이상 입력해주세요.';
        return false;
      }
      const isNumber = /[0-9]/;
      if (isNumber.test(name)) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '한글과 영어만 입력할 수 있습니다.';
        return false;
      }
      if (name === '' || name === undefined) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '이름을 입력해주세요.';
        return false;
      }
      this.adminInfo.errorFlag = false;
      return true;
    },
    checkDochospital(hospital) {
      if (this.adminInfo.hospital === '' || this.adminInfo.hospital === undefined) {
        this.adminInfo.errorFlag = true;
        this.adminInfo.errorMessage = '소속병원을 입력해주세요.';
        return false;
      }
      this.adminInfo.errorMessage = '';
      this.adminInfo.errorFlag = false;
      return true;
    },
    async editDoc() {
      const errorSavePass = this.adminInfo.password;
      this.checkEmail();
      this.checkPhone();
      this.checkDochospital();
      if (this.checkEmail() !== true) {
        return;
      }
      if (this.checkPhone() !== true) {
        return;
      }
      if (this.checkDochospital() !== true) {
        return;
      }
      if (this.adminInfo.password !== '' && this.adminInfo.password !== undefined) {
        this.checkPw('edit');
        if (this.checkPw('edit') !== true) {
          return;
        }
        const crypto = require('crypto');
        const password = crypto
          .createHash('sha512')
          .update(this.adminInfo.password)
          .digest('base64');
        this.editInfo.password = password;
      }

      this.adminInfo.errorMessage = '';
      this.editInfo = this.adminInfo;
      this.editInfo.did = this.adminInfo.id;
      try {
        await this.EDIT_DOCTOR(this.editInfo).then((data) => {});
      } catch (e) {
        this.adminInfo.password = errorSavePass;
        alert(e.data.message);
        return false;
      }
      this.close();
      window.location.reload();
    },
    resultCheckAction() {
      if (this.popupSet.popNumber === 1) {
        this.close();
        this.$router.go(-1);
      } else {
        this.close();
        window.location.reload();
      }
    },
    action() {
      this.SET_POPUP(false);
      if (this.isApiCall) {
        this.$emit('api-called');
      } else if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
    },
    close() {
      if (this.destination && this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      } else if (this.returnList !== undefined && this.returnList) {
        this.$router.go(-1);
      }
      this.SET_POPUP(false);
    },
  },
  created() {
    if (this.consultOpt !== undefined) {
      this.setConsultantArray();
    }
  },
};
</script>

<style lang="scss" scoped>
.medium {
  .modal-wrapper {
    .modal-container {
      .content .img-wrap {
        max-width: 590px;
        margin: 0 auto 60px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.frame {
  padding: 30px;
  min-height: 330px;
  &.anajugi {
    min-height: 400px;
  }
}
img {
  max-height: 600px;
  max-width: 1200px;
  height: auto;
  width: auto;
}
h3.title {
  @include initfontKor(
    $font-size: 26px,
    $line-height: 36px,
    $font-family: "AppleSDGothicNeoB00",
    $color: #292a2b,
    $letter-spacing: -0.52px,
    $text-align: center
  );
  margin: 0 0 12px 0;
}
.share-pop {
  width: inherit;
  .content {
    padding: 20px;
    margin: 0 0 60px 0;
    .sample {
      width: 100%;
      margin: 0 auto;
      min-height: 524px;
      background: #676767;
    }
  }
  .close {
    position: absolute;
    top: -5px;
    right: -55px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 15px 15px;
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &.activate {
        background: #fe6867;
        img {
          max-width: 32px;
          height: auto;
          margin: 0 20px 0 0;
        }
      }
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: #fe6867;
        color: #fff;
      }
    }
  }
}
.default-pop {
  width: inherit;
  &.anajugi {
    width: 700px;
    margin: 0 auto;
    &.askWrap {
      min-height: 330px;
    }
    .title {
      margin-bottom: 10px;
      font-family: AppleSDGothicNeoM00;
      font-size: 22px;
    }
    &.declareDetail {
      .content {
        .inputWrap {
          width: 80%;
          margin: 0 auto 50px;
          height: auto;
          text-align: center;

          .declareT {
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #f5f3f1;
            &.addDeclare {
              color: #fff;
              background-color: #fe6867;
            }
          }
          .declareC.addDeclare {
            width: 90%;
            margin: 0 auto;
            color: #fe6867;
            border: 2px dotted #fe6867;
          }
          .declareContext {
            min-height: 100px;
          }
          .declareC {
            max-height: 150px;
            text-align: center;
            overflow: hidden;
            overflow-y: auto;
            &.imgWrap {
              display: flex;
              p {
                flex: 1;
                img {
                  margin: 0 auto;
                  max-width: 100%;
                }
              }
            }
            .addDeclare {
              color: #ff0000;
            }
          }
        }
      }
    }
    .content {
      .inputWrap {
        width: 80%;
        margin: 0 auto;
        line-height: 30px;
        text-align: center;
        &.askInput {
          width: 100%;
          input {
            min-width: 60%;
          }
        }
        label {
          background: #f5f3f1;
          display: inline-block;
          width: 40%;
          outline: 1px solid #676767;
          vertical-align: middle;
        }
        input {
          outline: 1px solid #676767;
          padding: 4.5px 0;
          vertical-align: middle;
          text-indent: 16px;
        }
        .warn-message {
          line-height: 70px;
          color: red;
        }
      }
    }
  }
  &.type {
    margin: 10px auto 20% auto;
    display: flex;
    flex-direction: column;
    .content-title {
      margin: 5px auto 5px 5%;
      p {
        font-family: "AppleSDGothicNeoB00";
        color: #292a2b;
        text-align: left;
        line-height: 36px;
        font-size: 26px;
        letter-spacing: -0.52px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
      }
    }
    .content-body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20%;
      padding: 20px 0 20px 0;
      margin: auto;
      width: 60%;
      height: 10%;
      .left-btn-wrap,
      .right-btn-wrap {
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #292a2b;
        height: 40px;

        &.inActive {
          background: #fe6867;
          border: 0px;
          p {
            color: #fff;
          }
        }

        p {
          text-align: center;
          line-height: 2;
          font-family: "AppleSDGothicNeoM00";
          font-weight: normal;
          font-size: 20px;
          color: #292a2b;
        }
      }
    }
    .btn-wrap {
      border-radius: 0 0 15px 15px;
      overflow: hidden;
      button {
        width: 100%;
        border-radius: 6px;
        border: 0;
        padding: 0;
        cursor: pointer;
        font-family: "AppleSDGothicNeoM00";
        color: #292a2b;
        text-align: center;
        line-height: 40px;
        font-size: 21px;
        letter-spacing: -0.48px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
      }

      &.inActive {
        background: #fe6867;
        button {
          background: #fe6867;
          color: #fff;
        }
      }
    }
  }
  &.tat {
    .textarea-wide {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }

  &.meaning-result-pop {
    &.less {
      min-height: 265px;
    }
    padding: 40px 0;
    .img-wrap {
      display: flex;
      justify-content: center;
      margin: 0 0 60px 0;
    }
  }
  &.consultant {
    min-height: inherit;
    h3.title {
      margin: 0 0 25px 0;
      text-align: left;
    }
    .select-consultant {
      display: flex;
      margin: 0 0 65px 0;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        .select-wrap {
          width: 300px;
        }
        p {
          margin: 0 0 0 10px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
      }
    }
    .btn-wrap {
      display: flex;
      position: relative;
      border-radius: 0 0 15px 15px;
      overflow: hidden;
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        button {
          width: 120px;
          border-radius: 6px;
          border: 0;
          padding: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.inActivated {
            background: #f3f3f3 !important;
            color: #292a2b !important;
          }
          &.cancel {
            background: #ff0000;
            color: #fff;
          }
          &.all {
            background: #fe6867;
            color: #fff;
          }
          &.confirm {
            background: #fe6867;
            color: #fff;
          }
        }
      }
    }
  }
  &.training {
    h3.title {
      margin: 0 0 25px 0;
    }
    .choice-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 65px 0;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        .select-wrap {
          width: 120px;
        }
        p {
          margin: 0 0 0 10px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
      }
    }
    .center-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        button {
          width: 120px;
          border-radius: 6px;
          border: 0;
          padding: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.cancel {
            background: #ff0000;
            color: #fff;
          }
          &.all {
            background: #fe6867;
            color: #fff;
          }
          &.confirm {
            background: #fe6867;
            color: #fff;
          }
        }
      }
    }
  }
  &.trainingTime {
    h3.title {
      margin: 0 0 25px 0;
    }
    .choice-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 65px 0;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        .select-wrap {
          width: 120px;
        }
        p {
          margin: 0 0 0 10px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
      }
    }
    .center-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        button {
          width: 120px;
          border-radius: 6px;
          border: 0;
          padding: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.cancel {
            background: #ff0000;
            color: #fff;
          }
          &.all {
            background: #fe6867;
            color: #fff;
          }
          &.confirm {
            background: #fe6867;
            color: #fff;
          }
        }
      }
    }
  }
  &.wish {
    padding: 25px 40px;
    h3.title {
    }
    .textarea-wrap {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }
  &.resv {
    padding: 30px 65px;
    .pop-img {
      margin: 0 auto 45px auto;
    }
    .content {
      margin: 0 0 20px 0;
    }
    .resv-info {
      margin: 0 0 80px 0;
      .article {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(49, 87, 84, 0.2);
        &:last-of-type {
          border-bottom: 0;
        }
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.52px,
            $text-align: left
          );
          &.bold {
            font-family: "AppleSDGothicNeoB00";
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .pop-img {
    max-width: 105px;
    margin: 0 auto 32px auto;
    display: flex;
    justify-content: center;
    img {
    }
  }
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
      &.attention {
        color: #ff0000;
        font-size: 16px;
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 15px 15px;
    overflow: hidden;
    &.alone {
      button {
        width: 100%;
        background: #fe6867;
        color: #fff;
      }
    }
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: #fe6867;
        color: #fff;
      }
    }
  }
}
.fullCancelBtn {
  width: 100% !important;
}
</style>

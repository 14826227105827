<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized" ref="side"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">회원 정보 조회 및 관리</h5>
        <list-filter
          @request-list="injectionResult"
          @default-search="getDefaultSearch"
          :listInfo="listInfo"
          ref="filter"
        ></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          :response0="response"
          :reverse="reverse"
          @list-reversal="reversalResult"
          @list-amount-change="changedListAmount"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          :response0="listInfo"
          ref="pagination"
        ></pagination>
        <div class="excelButton"><p @click="excelPrint()">엑셀로 출력</p></div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import ListFilter from '@/components/ListFilter.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        next: null,
        first: null,
        last: null,
        list: null,
        // 추가
        active: [true],
        currentPage: 0,
        block: 5,
      },
      response: {
        List: [], count: '',
      },
      tree: {
        title: '일반 회원 관리',
        detail: '회원 정보 조회 및 관리',
      },
      listInfo: {
        type: 1,
        data: [],
        isHideAmount: false,
      },
      columnInfo: [
        {
          word: '가입일',
          filter: 'createAt',
        },
        {
          word: '아이디',
          filter: 'id',
        },
        {
          word: '닉네임',
          filter: 'nickname',
        },
        {
          word: '이메일',
          filter: 'email',
        },
        {
          word: '성별',
          filter: 'gender',
        },
        {
          word: '생년월일',
          filter: 'birthDate',
        },
      ],
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      // requestParam: '',
      // requestParamOrder: '',
      dateLabel1: '가입일',
      dateLabel2: '최근상담일',
      option: {
        ageFirst: null,
        ageLast: null,
        gender: 0,
        order: 1,
        q: null,
        size: 10,
        sort: 0,
        start: 0,
      },
      // 리스트 오름차순 내림차순, 0이 있는 값은 1이되면서 내림차순이 되고,''은 순서 변동 없음
      reverse: [0, 0, '', '', 0, 0],
    };
  },
  created() {
    window.scrollTo(0, 0);
  },

  methods: {
    ...mapActions('member', ['FETCH_ACCOUNT_LIST', 'ACCOUNT_LIST_EXCEL']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async excelPrint() {
      try {
        await this.ACCOUNT_LIST_EXCEL(this.option).then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'test.xlsx');
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) { console.log('에러', e); }
    },
    // 처음에 나오는  리스트
    async getDefaultSearch(option) {
      this.option = option;
      try {
        const data = await this.FETCH_ACCOUNT_LIST(option);
        this.response = data.response;
        this.listInfo.data = this.response.List;
        this.paginationInfo.currentPage = 0;
        this.paginationInfo.startPage = 0;
        // 페이징 을 위한
        const { count } = this.response;
        this.initPagination(this.paginationInfo.currentPage, count);
      } catch (e) { console.log('error', e); }
      return this.response;
    },
    // 페이지에 나오는 리스트 의 데이터 갯수 메서드
    changedListAmount(listAmountVal) {
      this.option.size = listAmountVal;
      this.paginationInfo.dataOnPage = this.option.size;
      this.$refs.filter.changeListAmount(listAmountVal);
      this.initPagination(this.paginationInfo.currentPage);
    },
    // 오름차순 내림차순 메서드
    reversalResult(info) {
      this.option.sort = info;
      if (this.reverse[info] === 0) {
        this.reverse[info] = 1;
      } else {
        this.reverse[info] = 0;
      }
      const order = this.reverse[info];
      if (info >= 3) { info -= 2; }
      const curPage = this.$refs.pagination.paginationStatus.currentPage;
      this.fetchData(info, order);
    },
    // 리스트 추출 메서드
    async fetchData(sort, order) {
      // this.dateLabel2 = '최근상담일';
      this.option.sort = sort;
      this.option.order = order;

      if (sort === false) {
        this.option.sort = 0;
        this.option.order = 1;
      }

      try {
        await this.FETCH_ACCOUNT_LIST(this.option).then((data) => {
          // if (isInit) {
          this.listInfo.data = data.response.List;
          this.listInfo.totalDataCnt = data.response.count;
          this.response.List = data.response.List;
          this.isListVisible = true;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    getAddClass(check) {
      /** 페이지네이션 번호 클릭 */
      const activeArray = [];
      for (let j = 0; j < Math.ceil(this.paginationInfo.totalDataCnt / this.paginationInfo.dataOnPage); j++) {
        activeArray.push(false);
      }
      activeArray[check] = true;
      this.paginationInfo.active = activeArray;
    },
    paginationClick(start) {
      this.getAddClass(start);
      const start0 = start * this.paginationInfo.dataOnPage;
      this.option.start = start0;
      this.paginationInfo.currentPage = start;

      this.initPagination(start);
      this.FETCH_ACCOUNT_LIST(this.option).then((data) => {
        // if (isInit) {
        this.listInfo.data = data.response.List;
        this.listInfo.totalDataCnt = data.response.count;
        this.response.List = data.response.List;
      // this.paginationInfo.currentPage = start;
      });
    },
    // 검색어를 가지고 리스트 추출리스트
    async injectionResult(option, search) {
      /**
       * 리스트 다시 그리는 요청을 받았을 때 실행
       * (필터 설정 후 검색, 페이지네이션 클릭, 리스트 수 셀렉트박스 변동)
       * */
      if (search === 0) { this.option.start = 0; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0; }
      const option0 = option;
      this.option = option;
      const data = await this.FETCH_ACCOUNT_LIST(option0);
      this.response = data.response;
      this.paginationInfo.totalDataCnt = this.response.count;
      this.initPagination(this.paginationInfo.currentPage, this.paginationInfo.totalDataCnt);
    },

    /** 페이징 처리 */
    initPagination(page, count) {
      if (count !== undefined) { this.paginationInfo.totalDataCnt = count; }
      this.pageDataSetting(this.paginationInfo.totalDataCnt, this.paginationInfo.dataOnPage, this.paginationInfo.block, page);
    },
    pageDataSetting(total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);

      const currentPage = page;

      const startIndex = currentPage % 4 === 0 ? currentPage : currentPage - (currentPage % 4);
      const endIndex = startIndex + 3 >= totalPage ? totalPage - 1 : startIndex + 3;

      this.paginationInfo.startIndex = startIndex;
      this.paginationInfo.endIndex = endIndex;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        if (index >= 0) {
          list.push(index);
        }
      }

      this.paginationInfo.first = totalPage > 1 && currentPage >= 0 ? true : null;
      this.paginationInfo.end = totalPage > 1 && currentPage + 1 < totalPage ? true : null;
      this.paginationInfo.firstMore = totalPage > 3 && currentPage >= 0 ? true : null;
      this.paginationInfo.endMore = totalPage > 4 && startIndex + 3 < totalPage ? true : null;
      this.paginationInfo.list = list;
      return {
        list, currentPage,
      };
    },

  },
  computed: {
    ...mapState('basic', ['checkPopup']),

  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
}
.excelButton{width:100%; height:50px; position:relative;}
.excelButton p{width:100px; height:100%;  text-align:center; line-height:50px;  color:#fff; background-color:#fe6867; position:absolute; right:0; border-radius: 5px;}
</style>

import { member } from '@/api';

const state = {
  uploadImg: [],
  util: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
  SET_UPLOAD_IMG_INPOP(state, uploadImg) {
    state.uploadImg = uploadImg;
  },
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_ACCOUNT_LIST({}, payload) {
    return member.fetchAccountList(payload);
  },
  ACCOUNT_LIST_EXCEL({}, payload) {
    return member.AccountListExcel(payload);
  },
  FETCH_ACCOUNT_DETAIL({}, payload) {
    return member.fetchAccountDetail(payload);
  },
  DELETE_ACCOUNT({}, payload) {
    return member.deleteAccount(payload);
  },
  WITHDRAW_LIST({}, payload) {
    return member.withdrawList(payload);
  },
  WITHDRAW_EXCEL({}, payload) {
    return member.withdrawExcel(payload);
  },
  DECLARE_LIST({}, payload) {
    return member.declareList(payload);
  },
  DECLARE_DETAIL({}, payload) {
    return member.declareDetail(payload);
  },
  CANCEL_DECLARE({}, payload) {
    return member.declareCancel(payload);
  },
  DELETE_DECLARE({}, payload) {
    return member.declareDelete(payload);
  },
  GET_PHOTOS({}, payload) {
    return member.getPhotos(payload);
  },
  QNA_LIST({}, payload) {
    return member.qnaList(payload);
  },
  QNA_UPDATE({}, payload) {
    return member.qnaUpdate(payload);
  },
  QNA_UPDATE2({}, payload) {
    return member.qnaUpdate2(payload);
  },
  VERSION_CHECK({}, payload) {
    return member.versionCheck(payload);
  },
  VERSION_WRITE({}, payload) {
    return member.versionWrite(payload);
  },
  FETCH_ACCOUNT_SET_LIST({ commit, getters }, payload) {
    return member.fetchAccountSetList(payload);
  },
  UPDATE_ACCOUNT_SET_MEMBER({ commit, getters }, payload) {
    return member.updateAccountSetMember(payload);
  },
  FETCH_ACTIVITY_POSITION_LIST({ commit, getters }, payload) {
    return member.fetchActivityPositionList(payload);
  },
  UPDATE_ACTIVITY_POSITION({ commit, getters }, payload) {
    return member.updateActivityPosition(payload);
  },
  FETCH_ACTIVITY_FEEDING_LIST({ commit, getters }, payload) {
    return member.fetchActivityFeedingList(payload);
  },
  UPDATE_ACTIVITY_FEEDING({ commit, getters }, payload) {
    return member.updateActivityFeeding(payload);
  },
  FETCH_MANAGE_LIST({}, payload) {
    return member.fetchManageList(payload);
  },
  CREATE_MANAGE({}, payload) {
    return member.createManage(payload);
  },
  DELETE_MANAGE({}, payload) {
    return member.deleteManage(payload);
  },
  EDIT_MANAGE({}, payload) {
    return member.editManage(payload);
  },
  FETCH_DOCTOR_LIST({}, payload) {
    return member.fetchDoctorList(payload);
  },
  CREATE_DOCTOR({}, payload) {
    return member.createDoctor(payload);
  },
  DELETE_DOCTOR({}, payload) {
    return member.deleteDoctor(payload);
  },
  EDIT_DOCTOR({}, payload) {
    return member.editDoctor(payload);
  },
  NOTICE_LIST({}, payload) {
    return member.noticeList(payload);
  },
  CREATE_NOTICE({}, payload) {
    return member.createNotice(payload);
  },
  UPLOAD_NOTICE_FILE({}, attachment) {
    return member.uploadNoticeFile(attachment);
  },
  EDIT_NOTICE({}, payload) {
    return member.editNotice(payload);
  },
  NOTICE_DETAIL({}, payload) {
    return member.noticeDetail(payload);
  },
  NOTICE_DELETE({}, payload) {
    return member.noticeDelete(payload);
  },
  DELETE_MANAGE_DETAIL({}, payload) {
    return member.deleteManageDetail(payload);
  },
  FETCH_CONSULT_LIST({}, payload) {
    return member.fetchConsultList(payload);
  },
  FETCH_CONSULT_DETAIL({}, payload) {
    return member.fetchConsultDetail(payload);
  },
  FETCH_CONSULT_SIGN({}, payload) {
    return member.fetchConsultSign(payload);
  },
  POST_CONSULT_DETAIL({}, payload) {
    return member.postConsultDetail(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-wrap" }, [
    this.$route.query.flag === true
      ? _c("div", { staticClass: "content-container" }, [
          _c("h3", [_vm._v("게시판 관리")]),
          _vm.tree.detail !== null
            ? _c("div", { staticClass: "img-wrap" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/util/back_trans.png") }
                })
              ])
            : _vm._e(),
          _vm.tree.detail !== null
            ? _c(
                "h3",
                {
                  on: {
                    click: function($event) {
                      return _vm.goUrl()
                    }
                  }
                },
                [_vm._v("신고 내용 관리")]
              )
            : _vm._e(),
          _vm.tree.detail2 !== null && _vm.tree.detail2 !== undefined
            ? _c("div", { staticClass: "img-wrap" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/util/back_trans.png") }
                })
              ])
            : _vm._e(),
          _c("h3", [_vm._v("회원 정보")]),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [_vm._v(" 로그아웃 ")]
          )
        ])
      : _c("div", { staticClass: "content-container" }, [
          _c("h3", { domProps: { textContent: _vm._s(_vm.tree.title) } }),
          _vm.tree.detail !== null
            ? _c("div", { staticClass: "img-wrap" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/util/back_trans.png") }
                })
              ])
            : _vm._e(),
          _vm.tree.detail !== null
            ? _c("h3", {
                domProps: { textContent: _vm._s(_vm.tree.detail) },
                on: {
                  click: function($event) {
                    return _vm.goUrl()
                  }
                }
              })
            : _vm._e(),
          _vm.tree.detail2 !== null && _vm.tree.detail2 !== undefined
            ? _c("div", { staticClass: "img-wrap" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/util/back_trans.png") }
                })
              ])
            : _vm._e(),
          _vm.tree.detail2 !== null && _vm.tree.detail2 !== undefined
            ? _c("h3", { domProps: { textContent: _vm._s(_vm.tree.detail2) } })
            : _vm._e(),
          _vm.tree.detail3 !== null && _vm.tree.detail3 !== undefined
            ? _c("div", { staticClass: "img-wrap" }, [
                _c("img", {
                  attrs: { src: require("@assets/images/util/back_trans.png") }
                })
              ])
            : _vm._e(),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [_vm._v(" 로그아웃 ")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title special">앱 관리</h5>
        <div class="version">
          <h5 class="title">IOS 앱버전관리</h5>
          <div class="article-wrap">
            <div class="article">
              <h6 class="header">버전</h6>
              <div class="content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="version1.currentVersion.value"
                    @input="isWritedVersion(1)"
                  />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">옵션</h6>
              <div class="content">
                <div class="select-wrap">
                  <v-select
                    :searchable="false"
                    @input="choiceVersionOpt(1)"
                    :options="version1.versionStatusList"
                    v-model="version1.versionStatus"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">웹스토어</h6>
              <div class="content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="version1.url"/>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-wrap">
            <button type="button" @click="versionApi1()">저장</button>
          </div>
        </div>
        <div class="version">
          <h5 class="title">안드로이드 앱버전관리</h5>
          <div class="article-wrap">
            <div class="article">
              <h6 class="header">버전</h6>
              <div class="content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="version2.currentVersion.value"
                    @input="isWritedVersion(2)"
                  />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">옵션</h6>
              <div class="content">
                <div class="select-wrap">
                  <v-select
                    :searchable="false"
                    @input="choiceVersionOpt(2)"
                    :options="version2.versionStatusList"
                    v-model="version2.versionStatus"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">옵션</h6>
              <div class="content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="version2.url"/>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="versionApi2()">저장</button>
          </div>
        </div>
        <!-- <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div> -->
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <search-popup v-if="checkSearchPopup" @get-member-id="applyMember" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SearchPopup from '@/components/modal/SearchPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import vSelect from 'vue-select';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '설정',
        detail: '앱 버전 관리',
      },
      device: '',
      version: {
        id: undefined,
        currentVersion: {
          flag: false,
          value: '',
        },
        versionStatus: '버전확인',
        versionStatusList: ['버전확인', '권고설치', '강제설치'],
      },
      version1: {
        id: undefined,
        currentVersion: {
          flag: false,
          value: '',
        },
        option: '',
        device: 1,
        url: '',
        versionStatus: '버전확인',
        versionStatusList: ['버전확인', '권고설치', '강제설치'],
      },
      version2: {
        id: undefined,
        currentVersion: {
          flag: false,
          value: '',
        },
        option: '',
        device: 2,
        url: '',
        versionStatus: '버전확인',
        versionStatusList: ['버전확인', '권고설치', '강제설치'],
      },
      push: {
        id: {
          flag: false,
          value: '',
        },
        url: {
          flag: false,
          value: '',
        },
        title: {
          flag: false,
          value: '',
        },
        content: {
          flag: false,
          value: '',
        },
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getVersion();
  },
  methods: {
    // ...mapActions('util', [
    //   'FETCH_APP_VERSION',
    //   'INSERT_APP_VERSION',
    //   'UPDATE_APP_VERSION',
    //   'SEND_PUSH',
    // ]),
    ...mapActions('member', ['VERSION_WRITE', 'VERSION_CHECK']),
    ...mapMutations('basic', ['SET_POPUP', 'SET_SEARCH_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isWritedVersion() {
      this.version.currentVersion.flag = false;
      const version = this.version.currentVersion.value.replace(/\s/gi, '');
      if (version === '') {
        this.version.currentVersion.flag = false;
        return false;
      }
      this.version.currentVersion.flag = true;
      return true;
    },
    isWritedUrl() {
      this.push.url.flag = false;
      const url = this.push.url.value.replace(/\s/gi, '');
      if (url === '') {
        this.push.url.flag = false;
        return false;
      }
      this.push.url.flag = true;
      return true;
    },
    isWritedTitle() {
      this.push.title.flag = false;
      const title = this.push.title.value.replace(/\s/gi, '');
      if (title === '') {
        this.push.title.flag = false;
        return false;
      }
      this.push.title.flag = true;
      return true;
    },
    isWritedContent() {
      this.push.content.flag = false;
      const content = this.push.content.value.replace(/\s/gi, '');
      if (content === '') {
        this.push.content.flag = false;
        return false;
      }
      this.push.content.flag = true;
      return true;
    },
    applyMember(index, item) {
      this.push.id.value = item.id;
      this.push.id.flag = true;
    },
    checkFlag() {
      if (
        !this.push.id.flag
        || !this.push.url.flag
        || !this.push.title.flag
        || !this.push.content.flag
      ) {
        if (!this.push.id.flag) {
          this.popupSet.content = '회원 아이디를 선택해주세요';
          this.push.id.flag = false;
        } else if (!this.push.url.flag) {
          this.popupSet.content = 'URL을 입력해주세요';
          this.push.url.flag = false;
        } else if (!this.push.title.flag) {
          this.popupSet.content = '제목을 입력해주세요';
          this.push.title.flag = false;
        } else if (!this.push.content.flag) {
          this.popupSet.content = '내용을 입력해주세요';
          this.push.content.flag = false;
        }
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
        return false;
      }
      return true;
    },
    sendPush() {
      console.log('push :', this.push);
      /** 푸시정보를 서버에 보냄 */
      if (!this.checkFlag()) return;

      const data = {
        id: this.push.id.value,
        url: this.push.url.value,
        title: this.push.title.value,
        body: this.push.content.value,
      };

      this.SEND_PUSH(data).then((data) => {
        const { result } = data;
        this.SET_POPUP(true);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = result ? '알림이 발송되었습니다.' : '알림 발송에 실패하였습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
      });
    },
    searchUserPop() {
      this.SET_SEARCH_POPUP(true);
    },
    async versionApi1() {
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.confirmBtnText = null;

      if (!this.version1.currentVersion.flag) {
        this.SET_POPUP(true);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '버전을 설정해주세요';
      } else if (
        !this.version1.isSelected
        || this.version1.versionStatusList.findIndex((x) => x === this.version1.versionStatus) === -1
      ) {
        this.SET_POPUP(true);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '옵션을 설정해주세요';
      } else {
        const appInfo = {
          device: 1,
          version: this.version1.currentVersion.value,
          option: this.version1.versionStatusList.findIndex((x) => x === this.version1.versionStatus),
          url: this.version1.url,
        };
        console.log('appinfo : ', appInfo);

        // if (this.version1.id === undefined) {
        await this.VERSION_WRITE(appInfo).then((data) => {
          console.log('data : ', data);
          if (data.message === 'success') {
            // this.getVersion();

            this.SET_POPUP(true);
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = '버전 상태가 변경되었습니다.';
          }
        });
      }
    },
    async versionApi2() {
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.confirmBtnText = null;

      if (!this.version2.currentVersion.flag) {
        this.SET_POPUP(true);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '버전을 설정해주세요';
      } else if (
        !this.version2.isSelected
        || this.version2.versionStatusList.findIndex((x) => x === this.version2.versionStatus) === -1
      ) {
        this.SET_POPUP(true);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '옵션을 설정해주세요';
      } else {
        const appInfo = {
          device: 2,
          version: this.version2.currentVersion.value,
          option: this.version2.versionStatusList.findIndex((x) => x === this.version2.versionStatus),
          url: this.version2.url,
        };

        await this.VERSION_WRITE(appInfo).then((data) => {
          if (data.message === 'success') {
            this.SET_POPUP(true);
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = '버전 상태가 변경되었습니다.';
          }
        });
      }
    },
    async getVersion() {
      await this.VERSION_CHECK(1).then((data) => {
        console.log(data);
        this.version1.id = data.response.id === null || data.response.id === undefined
          ? undefined
          : data.response.id;
        this.version1.currentVersion.value = data.response.version === null ? '1.0.0' : data.response.version;
        this.version1.versionStatus = data.response.option === null
          ? this.version1.versionStatusList[0]
          : this.version1.versionStatusList[data.response.option];

        /** 추가 */
        this.version1.currentVersion.value = data.response.version;
        this.version1.option = data.response.option;
        /** 추가 끝 */
        this.version1.currentVersion.flag = true;
        this.version1.isSelected = true;
      });
      await this.VERSION_CHECK(2).then((data) => {
        console.log(data);
        this.version.id = data.response.id === null || data.response.id === undefined
          ? undefined
          : data.response.id;
        this.version.currentVersion.value = data.response.version === null ? '1.0.0' : data.response.version;
        this.version.versionStatus = data.response.option === null
          ? this.version.versionStatusList[0]
          : this.version.versionStatusList[data.response.option];

        /** 추가 */
        this.version2.currentVersion.value = data.response.version;
        this.version2.option = data.response.option;
        /** 추가 끝 */
        this.version2.currentVersion.flag = true;
        this.version2.isSelected = true;
      });

      Promise.all([this.VERSION_CHECK(1), this.VERSION_CHECK(2)]);
    },
    choiceVersionOpt() {
      this.version.isSelected = true;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkSearchPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    SearchPopup,
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
.wrap-context .container h5.special {
  margin: 0 0 45px 0;
}
.version {
  width: 730px;
  margin: 0 0 65px 0;
  h5.title {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 34px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #292a2b,
      $letter-spacing: -0.6px,
      $text-align: left
    );
  }
  .article-wrap {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0 0 30px 0;
    .article {
      display: grid;
      grid-auto-flow: dense;
      outline: 1px solid #acb3c9;
      grid-gap: 1px;
      grid-template-columns: 170px 1fr;
      h6.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        padding: 0 15px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .content {
        outline: 1px solid #acb3c9;
        display: flex;
        align-items: center;
        input{text-indent:10px;}
        p {
          padding: 0 15px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    button {
      width: 105px;
      border-radius: 4px;
      border: 0;
      padding: 0;
      cursor: pointer;
      background: #292a2b;
      @include initfontKor(
        $font-size: 16px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
}
.push {
  width: 730px;
  margin: 0 0 65px 0;
  h5.title {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 34px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #292a2b,
      $letter-spacing: -0.6px,
      $text-align: left
    );
  }
  .article-wrap {
    margin: 0 0 30px 0;
    .article {
      display: grid;
      grid-auto-flow: dense;
      outline: 1px solid #acb3c9;
      grid-gap: 1px;
      grid-template-columns: 170px 1fr;
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      h6.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        padding: 0 15px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 60px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .content {
        outline: 1px solid #acb3c9;
        display: flex;
        align-items: center;
        &.flex {
          button {
            border: 0;
            padding: 0;
            background: #fe6867;
            width: 105px;
            cursor: pointer;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
        }
        .input-wrap {
          margin: 0 15px;
          input {
            width: 190px;
            height: inherit;
            border: 1px solid #acb3c9;
            padding: 0;
            padding: 0 12px;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 30px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
        }
        p {
          padding: 0 15px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    button {
      width: 105px;
      border-radius: 4px;
      border: 0;
      padding: 0;
      cursor: pointer;
      background: #292a2b;
      @include initfontKor(
        $font-size: 16px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
}
</style>
<style lang="scss">
.version .select-wrap {
  margin: 10px 15px;
  .v-select {
    .vs__selected {
      line-height: 40px;
    }
  }
}
</style>

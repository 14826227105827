<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">관리자 관리</h5>
        <div class="filter">
          <div class="filter-context">
            <div class="article">
            <!-- 관리자관리, 의사회원 관리는 똑같은 ui에 검색기능만 있지만 다른 구조로 작성됨. 양해부탁드립니다 -->
              <h5 class="f-title">검색</h5>
              <div class="f-content">
                <div class="input-wrap wide">
                  <input type="text" placeholder="이름" @keyup.enter="search()" v-model="manage.fakeQ" />
                </div>
              </div>
              <div class="btn-wrap-absolute">
                <button type="button" @click="search()">검색</button>
                <button type="button" @click="reset(); search()">초기화</button>
              </div>
            </div>
          </div>
        </div>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          :response0="response"
          :reverse="reverse"
          :manage="manage"
          :listIndex="listIndex"
          @list-amount-change="changedListAmount"
          @list-reversal="reversalResult"
          @register="register"
          @delete="deleteAdmin"
          @edit="editAdmin"
          v-if="isListVisible"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          :response0="listInfo"
          ref="pagination"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @result-check="resultCheck"/>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import Pagination from '@/components/Pagination.vue';

export default {
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 관리',
        detail: '관리자 관리',
      },
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        next: null,
        first: null,
        last: null,
        list: null,
        // 추가
        active: [true],
        currentPage: 0,
        block: 5,
      },
      manage: {
        start: 0,
        size: 10,
        search: null,
        sort: 0,
        order: 0,
      },
      reverse: [0, '', '', 0, 0, ''],
      columnInfo: [
        {
          word: '번호',
          filter: 'id',
        },
        {
          word: '아이디',
          filter: 'id',
        },
        {
          word: '이름',
          filter: 'name',
        },
        {
          word: '최근 로그인',
          filter: 'tel',
        },
        {
          word: '등록일',
          filter: 'author',
        },
        {
          word: '기능',
          filter: 'update_at',
        },
      ],
      response: {
        List: [], count: '',
      },
      // 앞에 번호를 위한 데이터값
      listIndex: { value: 0, flag: true },

      listInfo: {
        type: 3,
        data: [],
        isHideAmount: true,
      },
      isListVisible: true,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.fetchData();
  },
  methods: {
    ...mapActions('member', ['FETCH_MANAGE_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    // 한 번더 확인해주는 팝업
    async resultCheck(comments) {
      if (comments === '삭제되었습니다') {
        await this.SET_POPUP(false);
      }
      this.SET_POPUP(true);
      this.popupSet.popType = 'resultCheck';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = comments;
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = undefined;
      this.popupSet.confirmBtnText = '확인';
    },
    register() {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 3;
      this.popupSet.popType = 'anajugi';
      this.popupSet.cancelBtnText = null;
      this.popupSet.confirmBtnText = '등록';
      this.popupSet.cancelBtnText = '닫기';
      this.popupSet.destination = true;
      this.popupSet.huge = true;
      this.popupSet.adminEditInfo = null;
    },
    editAdmin(index, manage) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 3;
      this.popupSet.popType = 'anajugiEdit';
      this.popupSet.dataId = index;
      this.popupSet.adminEditInfo = manage;
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.confirmBtnText = '수정';
      this.popupSet.cancelBtnText = '닫기';
      this.popupSet.destination = true;
      this.popupSet.huge = true;
    },
    deleteAdmin(bid) {
      this.SET_POPUP(true);
      this.popupSet.popNumber = 3;
      this.popupSet.popType = 'anajugiDel';
      this.popupSet.deleteBid = bid;
      this.popupSet.content = '삭제하시겠습니까?';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.destination = true;
      this.popupSet.confirmBtnText = '삭제';
    },
    reversalResult(index) {
      if (this.reverse[index] === 0) {
        this.reverse[index] = 1;
      } else {
        this.reverse[index] = 0;
      }
      const order = this.reverse[index];
      this.fetchData(index, order);
      // 번호 내림차순 오름차순일때 달라지는 글의 번호
      if (this.manage.order === 1) { this.listIndex.value = this.response.count - this.manage.start; this.listIndex.flag = false; } else { this.listIndex.value = this.manage.start; this.listIndex.flag = true; }
    },
    reset() { this.manage.fakeQ = ''; },
    search() {
      this.manage.start = 0; this.manage.q = this.manage.fakeQ; this.paginationInfo.active = [true]; this.paginationInfo.currentPage = 0;
      this.FETCH_MANAGE_LIST(this.manage).then((data) => {
        this.response = data.response;
        this.listIndex.value = this.manage.start;
        this.paginationInfo.totalDataCnt = this.response.count;
        this.initPagination(this.paginationInfo.currentPage, this.paginationInfo.totalDataCnt);
      });
    },

    // 페이지에 나오는 리스트 의 데이터 갯수 메서드
    changedListAmount(listAmountVal) {
      this.manage.size = listAmountVal;
      this.paginationInfo.dataOnPage = this.manage.size;
      this.listIndex.value = this.manage.start;
      this.search();
      // this.$refs.filter.changeListAmount(listAmountVal);
      this.initPagination(this.paginationInfo.currentPage);
    },
    async fetchData(sort, order) {
      if (sort !== undefined && order !== undefined) {
        this.manage.sort = sort;
        this.manage.order = order;
      }
      const option = this.manage;
      try {
        await this.FETCH_MANAGE_LIST(option).then((data) => {
          // if (isInit) {
          //   this.listInfo.data = data.response.list;
          //   this.listInfo.totalDataCnt = data.response.list.length;
          //   this.isListVisible = true;
          // } else {
          //   this.$refs.list.initList(data.response.list, data.response.list.length);
          // }
          this.response = data.response;
          this.listInfo.data = data.response.List;
        });
      } catch (error) {
        console.log('error:', error);
      }

      // 페이징 을 위한
      const { count } = this.response;
      this.initPagination(this.paginationInfo.currentPage, count);
    },

    getAddClass(check) {
      /** 페이지네이션 번호 클릭 */
      const activeArray = [];
      for (let j = 0; j < Math.ceil(this.paginationInfo.totalDataCnt / this.paginationInfo.dataOnPage); j++) {
        activeArray.push(false);
      }
      activeArray[check] = true;
      this.paginationInfo.active = activeArray;
    },
    paginationClick(start) {
      this.getAddClass(start);
      const start0 = start * this.paginationInfo.dataOnPage;
      this.manage.start = start0;
      this.paginationInfo.currentPage = start;

      this.initPagination(start);
      this.FETCH_MANAGE_LIST(this.manage).then((data) => {
        this.listInfo.data = data.response.List;
        this.listInfo.totalDataCnt = data.response.count;
        this.response.List = data.response.List;

        // 번호 내림차순 오름차순일때 달라지는 글의 번호
        if (this.manage.order === 1) { this.listIndex.value = this.response.count - this.manage.start; this.listIndex.flag = false; } else { this.listIndex.value = this.manage.start; this.listIndex.flag = true; }
      });
    },

    /** 페이징 처리 */
    initPagination(page, count) {
      if (count !== undefined) { this.paginationInfo.totalDataCnt = count; }
      this.pageDataSetting(this.paginationInfo.totalDataCnt, this.paginationInfo.dataOnPage, this.paginationInfo.block, page);
    },
    pageDataSetting(total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);

      const currentPage = page;

      const startIndex = currentPage % 4 === 0 ? currentPage : currentPage - (currentPage % 4);
      const endIndex = startIndex + 3 >= totalPage ? totalPage - 1 : startIndex + 3;

      this.paginationInfo.startIndex = startIndex;
      this.paginationInfo.endIndex = endIndex;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        if (index >= 0) {
          list.push(index);
        }
      }

      this.paginationInfo.first = totalPage > 1 && currentPage >= 0 ? true : null;
      this.paginationInfo.end = totalPage > 1 && currentPage + 1 < totalPage ? true : null;
      this.paginationInfo.firstMore = totalPage > 3 && currentPage >= 0 ? true : null;
      this.paginationInfo.endMore = totalPage > 4 && startIndex + 3 < totalPage ? true : null;
      this.paginationInfo.list = list;
      return {
        list, currentPage,
      };
    },

  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
.filter-context {
  position: relative;
  padding: 40px;
  background: #f3f3f3;
  margin: 0 0 50px 0;
  .btn-wrap-absolute {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 20px;
    button {
    border-radius: 5px;
    border:0;
    padding: 0;
    width: 105px;
    height: 40px;
    background: #fe6867;
    margin: 0 0 0 10px;
    cursor: pointer;
    font-family: "AppleSDGothicNeoM00";
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 19px;
    letter-spacing: -0.6px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;

    }
  }
}
.filter {
    .article {
      margin: 20px 0 0 0;
      display: flex;
      &:first-of-type {
        margin: 0;
      }
      h5.f-title {
        width: 140px;
        white-space: nowrap;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
             .input-wrap {
          width: 110px;
          height: 40px;
          &.wide {
            width: 452px;
            ::placeholder{
              @include initfontKor(
                $font-size: 20px,
                $line-height: 40px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #a9adb1,
                $letter-spacing: -0.6px,
                $text-align: left
              );
            }
          }
          &:first-of-type {
          }
          input {
            width: inherit;
            border: 0;
            padding: 0 20px;
            @include initfontKor(
              $font-size: 22px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
            &.center {
              text-align: center;
            }
          }
        }
    }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal-mask",
        class: { huge: _vm.huge, medium: _vm.medium },
        on: {
          click: function($event) {
            return _vm.overlayClick($event)
          }
        }
      },
      [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "modal-container",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [_c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2)]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }